import { useJotaiFormContext } from '@/components/state/jotaiFormProvider'
import { useDryRunActions } from '@/pageComponents/orders/EditOrderPage/context/DryRunActionsContext'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { useCallback, useMemo, useState } from 'react'
import { useErrorHandler } from '../../../components/ErrorHandler/ErrorHandler'
import ActionButton from '../../../components/button/actionButton'
import { WithModalParams, useModal, useModalsContext } from '../../../components/state/context/modalsContext'
import useJotaiForm, { JotaiForm } from '../../../components/state/useJotaiForm'
import useJotaiOnSubmit from '../../../components/state/useJotaiOnSubmit'
import { WithUrql } from '../../../components/state/useJotaiUrqlQuery'
import buildLogger from '../../../util/logger'
import { LineItemArrDialogContent } from '@/pageComponents/orders/LineItemArr/LineItemArrDialogContent'
import { CommonOrderFormPageState } from '@/pageComponents/orders/EditOrderPage/CommonOrderFormPageState'
import LineItemDialog from '@/pageComponents/orders/lineItemDialog'
import { ChargeType } from '@/generated/graphql'

const logger = buildLogger('LineItemArrDialog')

export type LineItemArrDialogState = Pick<CommonOrderFormPageState, 'orderDetail'> & WithUrql
export type LineItemArrDialogProps<F> = {
  jotaiForm: JotaiForm<F>
  lineItemIndex: number
}

type DialogProps<F> = WithModalParams & LineItemArrDialogProps<F>

const formID = 'LineItemArrDialog'
const LineItemArrDialog = <F extends LineItemArrDialogState>({
  open,
  onClose,
  onSubmit,
  jotaiForm,
  lineItemIndex,
}: DialogProps<F>): JSX.Element => {
  const errorHandler = useErrorHandler()
  const [isSubmitting, setIsSubmitting] = useState(false)
  function handleSubmit() {
    async function doAsync() {
      setIsSubmitting(true)
      await onSubmit?.()
      setIsSubmitting(false)
      onClose?.()
    }
    doAsync().catch((error) => {
      setIsSubmitting(false)
      errorHandler(error)
    })
  }
  const lineItem = jotaiForm.useSelect(
    useCallback((form) => form?.orderDetail?.lineItems?.[lineItemIndex], [lineItemIndex])
  )

  console.log(lineItem?.charge?.type)
  const disabledExplanation = !lineItem?.charge?.shouldTrackArr
    ? 'Line item does not track ARR'
    : lineItem?.charge?.type == ChargeType.Usage
    ? 'Usage line items cannot override ARR'
    : ''

  return (
    <Dialog open={!!open} onClose={onClose} maxWidth={'sm'} scroll={'paper'}>
      <DialogTitle>View Line Item ARR</DialogTitle>
      <DialogContent dividers={true}>
        <LineItemArrDialogContent jotaiForm={jotaiForm} lineItemIndex={lineItemIndex} />
      </DialogContent>
      <DialogActions>
        <div style={{ flexGrow: 1 }} />
        <ActionButton
          buttonData={{
            label: 'Cancel',
            onClick: onClose,
            color: 'inherit',
            buttonProps: { variant: 'outlined' },
          }}
        />
        <ActionButton
          buttonData={{
            label: 'Save',
            color: 'primary',
            loading: isSubmitting,
            onClick: handleSubmit,
            disabledExplanation: disabledExplanation,
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

export const useLineItemArrDialog = <F extends LineItemArrDialogState>() => {
  const [, , toggleModal] = useModalsContext()
  const [lineItemIndex, setLineItemIndex] = useState<number>(0)

  const orderJotaiForm = useJotaiFormContext<F>()
  const orderDetail = orderJotaiForm.useSelect(useCallback((form) => form.orderDetail, []))

  const { queueDryRun } = useDryRunActions()

  const arrDraftForm = useJotaiForm<LineItemArrDialogState>(
    useMemo(
      () => ({
        defaultValue: {
          orderDetail,
        },
      }),
      [orderDetail]
    )
  )

  const onSaveArr = useJotaiOnSubmit(
    useMemo(
      () => ({
        atom: arrDraftForm.atom,
        onSubmit: (value: LineItemArrDialogState) => {
          orderJotaiForm.set((form) => {
            form.orderDetail.lineItems = value.orderDetail.lineItems
          })
          queueDryRun()
        },
      }),
      [arrDraftForm.atom, orderJotaiForm, queueDryRun]
    )
  )

  useModal<DialogProps<LineItemArrDialogState>>({
    component: LineItemArrDialog,
    schema: {
      key: formID,
      modalProps: {
        jotaiForm: arrDraftForm,
        onSubmit: onSaveArr,
        lineItemIndex,
      },
    },
  })

  const toggleCurrentModal = useCallback(() => {
    toggleModal(formID)
  }, [toggleModal])

  return [toggleCurrentModal, setLineItemIndex] as [typeof toggleCurrentModal, typeof setLineItemIndex]
}
export default LineItemDialog
