import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { MutableObject } from '../../../../components/SchemaForm/DeepMutable'
import BillyLink from '../../../../components/link/billyLink'
import { ActionType, ChargeType, Feature, LineItemFragment } from '../../../../generated/graphql'
import { unitPriceFormatter } from '../../../../util/currencyUtil'
import { useOrderChargeRowStyles, ChargeRowEditIconButton } from '../orderChargeRow'
import useDynamicFeatureFlag from '@/components/state/useDynamicFeatureFlag'

export function ListPriceCell({
  isListPriceOverrideEnabled,
  lineItem,
  currency,
  onClickPriceOverride,
  lineItemIndex,
  onEditRateAttribute,
  listPrice,
}: {
  isListPriceOverrideEnabled: boolean | null | undefined
  lineItem: MutableObject<{ readonly __typename?: 'OrderLineItemDetail' | undefined } & LineItemFragment>
  currency: string | null | undefined
  onClickPriceOverride: ((orderLineIndex: number) => void) | undefined
  lineItemIndex: number
  onEditRateAttribute?: ((orderLineIndex: number) => void) | undefined
  listPrice: string
}) {
  const { classes, cx } = useOrderChargeRowStyles()
  const isOrderAnnualizedAmountEnabled = useDynamicFeatureFlag(Feature.OrderAnnualizedAmount)

  const showRateCardAttributeDialog =
    lineItem.charge.rateCardId &&
    (lineItem.charge.type === ChargeType.Recurring || lineItem.charge.type === ChargeType.OneTime) &&
    lineItem.action !== ActionType.Update &&
    lineItem.action !== ActionType.None &&
    lineItem.action !== ActionType.Remove

  const rateAttributeDialogButton = showRateCardAttributeDialog ? (
    <ChargeRowEditIconButton
      tooltipTitle="Attributes must be selected for rate card charge"
      label="Edit Rate Card Attributes"
      onClick={() => {
        onEditRateAttribute?.(lineItemIndex)
      }}
    />
  ) : (
    <></>
  )

  if (isOrderAnnualizedAmountEnabled) {
    return (
      <Box display="flex" flexDirection="column" alignItems="flex-end" justifyContent="flex-start">
        <Typography variant="body2" className={classes.currencyCell}>
          {`${unitPriceFormatter({ currency, value: lineItem.listAmount })}`}
        </Typography>
        {isListPriceOverrideEnabled ? (
          <Box style={{ display: 'flex', alignItems: 'center', float: 'right' }}>
            {lineItem?.listUnitPriceBeforeOverride ? (
              <Typography variant="caption" className={cx(classes.strikethrough, classes.currencyCell)}>
                {unitPriceFormatter({ currency, value: lineItem.listUnitPriceBeforeOverride })}
              </Typography>
            ) : (
              lineItem.listPriceOverrideRatio && (
                <Typography variant="caption" className={cx(classes.overrideRatio)}>
                  {`${lineItem.listPriceOverrideRatio}x`}
                </Typography>
              )
            )}
            <BillyLink
              linkProps={{
                onClick: () => {
                  onClickPriceOverride?.(lineItemIndex)
                },
                style: { marginLeft: 20 },
              }}
            >
              {`(${listPrice})`}
            </BillyLink>
            {rateAttributeDialogButton}
          </Box>
        ) : (
          <Box style={{ display: 'flex', alignItems: 'center', float: 'right' }}>
            <Typography variant="caption">{`(${listPrice})`}</Typography>
            {rateAttributeDialogButton}
          </Box>
        )}
      </Box>
    )
  }

  return isListPriceOverrideEnabled ? (
    <Box style={{ display: 'flex', alignItems: 'center', float: 'right' }}>
      {lineItem?.listUnitPriceBeforeOverride ? (
        <Typography variant="body2" className={cx(classes.strikethrough, classes.currencyCell)}>
          {unitPriceFormatter({ currency, value: lineItem.listUnitPriceBeforeOverride })}
        </Typography>
      ) : (
        lineItem.listPriceOverrideRatio && (
          <Typography variant="body2" className={cx(classes.overrideRatio)}>
            {`${lineItem.listPriceOverrideRatio}x`}
          </Typography>
        )
      )}
      <BillyLink
        linkProps={{
          onClick: () => {
            onClickPriceOverride?.(lineItemIndex)
          },
          style: { marginLeft: 20 },
        }}
      >
        {listPrice}
      </BillyLink>
      {rateAttributeDialogButton}
    </Box>
  ) : (
    <Box style={{ display: 'flex', alignItems: 'center', float: 'right' }}>
      {listPrice}
      {rateAttributeDialogButton}
    </Box>
  )
}
