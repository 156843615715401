import { useCallback, useMemo } from 'react'
import { DocumentNode } from 'graphql'
import { Draft } from 'immer'
import { useRouter } from 'next/router'
import { JotaiForm } from '../../components/state/useJotaiForm'
import { useJotaiUrqlQuery, WithUrql } from '../../components/state/useJotaiUrqlQuery'
import {
  ApprovalSegmentsDocument,
  CustomFieldParentType,
  DuplicateOrderDocument,
  DuplicateOrderQuery,
  DuplicateOrderQueryVariables,
  Feature,
  GenerateAmendmentDocument,
  GenerateAmendmentQuery,
  GenerateAmendmentQueryVariables,
  GenerateCancelOrderDocument,
  GenerateCancelOrderQuery,
  GenerateCancelOrderQueryVariables,
  GenerateRenewalOrderDocument,
  GenerateRenewalOrderQuery,
  GenerateRenewalOrderQueryVariables,
  GetCurrentUserDocument,
  GetCustomFieldsDocument,
  GetCustomFieldsQuery,
  GetCustomFieldsQueryVariables,
  GetOrderDetailDocument,
  GetOrderDetailQuery,
  GetOrderDetailQueryVariables,
  GetOrderExpiryDurationDocument,
  GetOrderExpiryDurationQuery,
  GetOrderExpiryDurationQueryVariables,
  GetPaymentTermSettingsDocument,
  GetPaymentTermSettingsQuery,
  GetPaymentTermSettingsQueryVariables,
  OrderType,
} from '../../generated/graphql'
import { notEmpty } from '../../util/array'
import { getDocumentName } from '../../util/gqlUtil'
import buildLogger from '../../util/logger'
import { CommonOrderFormPageState } from './EditOrderPage/CommonOrderFormPageState'
import { useUpdateApprovalSegments } from './useUpdateApprovalSegments'
import { useUpdateDefaultOrderOwner } from './useUpdateDefaultOrderOwner'
import { deepMutable } from '../../components/SchemaForm/DeepMutable'
import { addDurationToUnixDate, currentDateTimeInTenantTz } from '../../util/datetime/luxon/dateUtil'
import { Duration } from 'luxon'
import { useDryRunActions } from '@/pageComponents/orders/EditOrderPage/context/DryRunActionsContext'
import useDynamicFeatureFlag from '@/components/state/useDynamicFeatureFlag'
import { populateCustomFieldsWithDefault, populateOrderCustomFieldDefault } from '@/components/CustomFields/utils'

const logger = buildLogger('usePopulateOrder')

type UsePopulateOrderJotaiForm = Pick<CommonOrderFormPageState, 'orderDetail'> & WithUrql

/**
 * Responsible for populating the orderDetails and related fields for new and existing orders.
 * Including renewals, duplicates, amendments, cancellations, and regular draft orders
 */
const usePopulateOrder = <F extends UsePopulateOrderJotaiForm>({ jotaiForm }: { jotaiForm: JotaiForm<F> }) => {
  const router = useRouter()
  const orderId = router.query.orderId as string
  const subscriptionId = router.query.subscriptionId as string | undefined
  const sourceId = router.query.sourceId as string | undefined
  const type = router.query.type as string | undefined

  const isNew = !orderId

  const isRenewal = jotaiForm.useSelect(
    useCallback((form) => form.orderDetail.orderType === OrderType.Renewal || type === 'renewal', [type])
  )
  const isCancellation = jotaiForm.useSelect(
    useCallback((form) => form.orderDetail.orderType === OrderType.Cancel || type === 'cancel', [type])
  )
  const isAmendment = jotaiForm.useSelect(
    useCallback((form) => form.orderDetail.orderType === OrderType.Amendment || type === 'amendment', [type])
  )

  const isNewRenewal = isRenewal && !!subscriptionId
  const isNewDuplicate = isNew && !!sourceId
  const isNewCancellation = isNew && isCancellation && !!subscriptionId
  const isNewAmendment = isNew && !!isAmendment && !!subscriptionId
  const isNewOrder = isNew && !isNewRenewal && !isNewDuplicate && !isNewCancellation && !isNewAmendment

  const isOrderCustomFieldsEnabled = useDynamicFeatureFlag(Feature.OrderCustomFields)

  useJotaiUrqlQuery<F, GetOrderDetailQuery, GetOrderDetailQueryVariables>({
    document: GetOrderDetailDocument,
    jotaiForm,
    onData: useCallback((data, draft: Draft<F>) => {
      draft.orderDetail = deepMutable(data.orderDetail)
      draft.orderDetail.customFields = populateOrderCustomFieldDefault(data.orderDetail)
      draft.orderDetail.lineItems = populateLineItemCustomFieldDefaults(data.orderDetail)
    }, []),
    pause: useCallback(() => isNew, [isNew]),
    queryOpts: useMemo(() => ({ requestPolicy: 'cache-only' }), []),
    variables: useCallback(() => ({ id: orderId }), [orderId]),
  })

  useJotaiUrqlQuery<F, GetCustomFieldsQuery, GetCustomFieldsQueryVariables>({
    document: GetCustomFieldsDocument,
    jotaiForm,
    onData: useCallback((data, draft: Draft<F>) => {
      draft.orderDetail.customFields = populateOrderCustomFieldDefault(data)
    }, []),
    pause: useCallback(() => !isNew || !isOrderCustomFieldsEnabled, [isNew, isOrderCustomFieldsEnabled]),
    variables: useCallback(() => ({ parentObjectType: CustomFieldParentType.Order }), []),
  })

  const { queueDryRun } = useDryRunActions()

  useJotaiUrqlQuery<F, GenerateRenewalOrderQuery, GenerateRenewalOrderQueryVariables>({
    document: GenerateRenewalOrderDocument,
    jotaiForm,
    onData: useCallback(
      (data, draft) => {
        draft.orderDetail = deepMutable(data.generateRenewalOrder)
        draft.orderDetail.customFields = populateOrderCustomFieldDefault(data.generateRenewalOrder)
        draft.orderDetail.lineItems = populateLineItemCustomFieldDefaults(data.generateRenewalOrder)
        queueDryRun()
      },
      [queueDryRun]
    ),
    pause: useCallback(() => !isNewRenewal, [isNewRenewal]),
    variables: useCallback(() => ({ subscriptionId: subscriptionId || '' }), [subscriptionId]),
  })

  useJotaiUrqlQuery<F, DuplicateOrderQuery, DuplicateOrderQueryVariables>({
    document: DuplicateOrderDocument,
    jotaiForm,
    onData: useCallback((data, draft) => {
      draft.orderDetail = deepMutable(data.duplicateOrder)
    }, []),
    pause: useCallback(() => !isNewDuplicate, [isNewDuplicate]),
    variables: useCallback(() => ({ orderId: sourceId || '' }), [sourceId]),
  })

  useJotaiUrqlQuery<F, GenerateAmendmentQuery, GenerateAmendmentQueryVariables>({
    document: GenerateAmendmentDocument,
    jotaiForm,
    onData: useCallback((data, draft) => {
      draft.orderDetail = deepMutable({ ...data.generateAmendment, termLength: undefined })
      draft.orderDetail.customFields = populateOrderCustomFieldDefault(data.generateAmendment)
      draft.orderDetail.lineItems = populateLineItemCustomFieldDefaults(data.generateAmendment)
    }, []),
    pause: useCallback(() => !isNewAmendment, [isNewAmendment]),
    variables: useCallback(() => ({ subscriptionId: subscriptionId || '' }), [subscriptionId]),
  })

  useJotaiUrqlQuery<F, GenerateCancelOrderQuery, GenerateCancelOrderQueryVariables>({
    document: GenerateCancelOrderDocument,
    jotaiForm,
    onData: useCallback((data: GenerateCancelOrderQuery, draft) => {
      draft.orderDetail = deepMutable(data.generateCancelOrder)
      draft.orderDetail.customFields = populateOrderCustomFieldDefault(data.generateCancelOrder)
      draft.orderDetail.lineItems = populateLineItemCustomFieldDefaults(data.generateCancelOrder)
    }, []),
    pause: useCallback(() => !isNewCancellation, [isNewCancellation]),
    variables: useCallback(() => ({ subscriptionId: subscriptionId || '' }), [subscriptionId]),
  })

  useJotaiUrqlQuery<F, GetPaymentTermSettingsQuery, GetPaymentTermSettingsQueryVariables>({
    document: GetPaymentTermSettingsDocument,
    jotaiForm,
    onData: useCallback(
      (data, draft) => (draft.orderDetail.paymentTerm = data.paymentTermSettings?.defaultPaymentTerm),
      []
    ),
    pause: useCallback(() => !isNewOrder, [isNewOrder]),
  })

  useJotaiUrqlQuery<F, GetOrderExpiryDurationQuery, GetOrderExpiryDurationQueryVariables>({
    document: GetOrderExpiryDurationDocument,
    jotaiForm,
    onData: useCallback((data, draft) => {
      if (data.orderExpiryDurationInDays !== null && !draft.orderDetail.expiresOn) {
        draft.orderDetail.expiresOn = addDurationToUnixDate(
          currentDateTimeInTenantTz().toUnixInteger(),
          Duration.fromObject({ days: data.orderExpiryDurationInDays })
        )
      }
    }, []),
    pause: useCallback(() => !isNew, [isNew]),
  })

  const populateOrderDocuments: DocumentNode[] = useMemo(() => {
    if (!isNew) {
      return [GetOrderDetailDocument]
    }
    if (isNewRenewal) {
      return [GenerateRenewalOrderDocument]
    }
    if (isNewDuplicate) {
      return [DuplicateOrderDocument]
    }
    if (isNewCancellation) {
      return [GenerateCancelOrderDocument]
    }
    if (isNewAmendment) {
      return [GenerateAmendmentDocument]
    }

    // new order
    return [GetPaymentTermSettingsDocument, GetOrderExpiryDurationDocument]
  }, [isNew, isNewRenewal, isNewDuplicate, isNewCancellation, isNewAmendment])

  const populateLineItemCustomFieldDefaults = (orderDetail) => {
    return deepMutable(
      orderDetail.lineItems?.map((lineItem) => {
        return {
          ...lineItem,
          customFields: populateCustomFieldsWithDefault(lineItem.customFields),
        }
      }) ?? []
    )
  }

  const hasOrderLoaded = jotaiForm.useSelect(
    useCallback(
      (form) =>
        populateOrderDocuments
          .map((doc) => {
            const docName = getDocumentName(doc)
            return form.urqlSuccesses?.[docName]
          })
          .filter((flag) => !!flag).length === populateOrderDocuments.length,
      [populateOrderDocuments]
    )
  )

  useUpdateDefaultOrderOwner({
    jotaiForm,
    isNew,
    pause: !hasOrderLoaded,
  })

  useUpdateApprovalSegments({ jotaiForm })

  const allLoadingDocuments = jotaiForm.useSelect(
    useCallback(
      (form) => {
        const hasOwner = !!form.orderDetail.owner
        return [
          ...populateOrderDocuments,
          hasOwner ? ApprovalSegmentsDocument : undefined,
          isNew ? GetCurrentUserDocument : undefined,
        ].filter(notEmpty)
      },
      [populateOrderDocuments, isNew]
    )
  )

  const hasAllLoaded = jotaiForm.useSelect(
    useCallback(
      (form) => {
        return (
          allLoadingDocuments
            .map((doc) => {
              const docName = getDocumentName(doc)
              return form.urqlSuccesses?.[docName]
            })
            .filter((flag) => !!flag).length === allLoadingDocuments.length
        )
      },
      [allLoadingDocuments]
    )
  )

  const populateOrderDetails = useMemo(
    () => ({
      hasLoaded: hasAllLoaded,
      isNew,
      orderId,
      isRenewal,
      isCancellation,
      isAmendment,
      isNewDuplicate,
    }),
    [hasAllLoaded, isNew, orderId, isRenewal, isCancellation, isAmendment, isNewDuplicate]
  )
  return populateOrderDetails
}

export default usePopulateOrder
