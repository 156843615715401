/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react'
import * as RT from '@tinymce/tinymce-react'
import { Editor } from '@tinymce/tinymce-react'

if (typeof window !== 'undefined') {
  require('tinymce/tinymce')
  require('tinymce/models/dom/model')
  require('tinymce/themes/silver')
  require('tinymce/icons/default')
  require('tinymce/plugins/advlist')
  require('tinymce/plugins/anchor')
  require('tinymce/plugins/autolink')
  require('tinymce/plugins/autoresize')
  require('tinymce/plugins/autosave')
  require('tinymce/plugins/importcss')
  require('tinymce/plugins/link')
  require('tinymce/plugins/lists')
  require('tinymce/plugins/searchreplace')
  require('tinymce/plugins/table')
  require('tinymce/plugins/emoticons/js/emojis')
  require('tinymce/plugins/code')
}

// Editor styles
import 'tinymce/skins/ui/oxide/skin.min.css'

// Content styles, including inline UI like fake cursors
/* @ts-ignore */
import contentCss from '!!raw-loader!tinymce/skins/content/default/content.min.css'
/* @ts-ignore */
import contentUiCss from '!!raw-loader!tinymce/skins/ui/oxide/content.min.css'
import { useUserTenantSession } from '@/components/UserTenantSessionProvider/UserTenantSessionContext'
import { isAdminRole } from '@/util/roleUtils'

type ReactTinyMCEEditor = {
  style?: React.CSSProperties
  value?: RT.IAllProps['value'] | null
  onChange?: RT.IAllProps['onEditorChange']
}

const className = 'mce-table'
const styleString = `
  <style>
    .${className} td,
    .${className} th {
      padding: 5pt;
      border: 1px solid #000000;
    }
  </style>
`

function BundledReactTinyMCEEditor(props: RT.IAllProps) {
  const { init, ...rest } = props

  return (
    <Editor
      init={{
        ...init,
        skin: false,
        content_css: false,
        content_style: [contentCss, contentUiCss, init?.content_style || ''].join('\n'),
      }}
      {...rest}
    />
  )
}

export const withTableStyleTemplate = (html: string) => {
  const appendClassNameToTable = html.includes(className)
    ? html
    : html.replaceAll('<table', `<table class="${className}"`)

  const contentString = `
  ${styleString}
  ${appendClassNameToTable}
  `
  return html.includes('table') ? contentString : html
}

const trimStyleFromHTML = (html?: string) => {
  if (html?.includes('style')) {
    const trimmedHtml = html.replace(styleString, '')
    return trimmedHtml
  }

  return html
}

export default function ReactTinyMCEEditor({ value = '', onChange, style }: ReactTinyMCEEditor): JSX.Element {
  const userTenantSession = useUserTenantSession()
  const role = userTenantSession.currentUser.role

  return (
    <BundledReactTinyMCEEditor
      value={trimStyleFromHTML(value || '')}
      onEditorChange={onChange}
      init={{
        height: style?.height || 500,
        menubar: false,
        branding: false,
        statusbar: false,
        plugins: ['advlist', 'anchor', 'autolink', 'link', 'lists', 'searchreplace', 'table', 'code'],
        toolbar:
          'undo redo | blocks | ' +
          'bold italic underline strikethrough forecolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'link table' +
          `${isAdminRole(role) ? ' code' : ''}`,
        content_style: 'body { font-family: Manrope,sans-serif; font-size: 14px }',
      }}
    />
  )
}
