import { useErrorHandler } from '../../../components/ErrorHandler/ErrorHandler'
import { useSnackbarHandler } from '../../../components/SnackbarHandler/SnackbarHandler'
import {
  AccountContactFragment,
  AccountDetailFragment,
  OrderStatus,
  useUpdateOrderStatusMutation,
} from '../../../generated/graphql'
import { isAccountAddressComplete } from '../../../util/address'
import buildLogger from '../../../util/logger'
import { useAddressIsRequiredDialog } from './AddressIsRequiredDialog'
import { useShipToAddressInvalid } from './ShipToAddressInvalid'
import { useUseAccountAddressDialog } from './UseAccountAddressDialog'
import { isTypeOfCombinedError } from '../../../util/gqlErrorHandling'
import { useCallback, useState } from 'react'

const logger = buildLogger('useMarkAsExecuted')

export type UseMarkAsExecutedProps = {
  orderId: string
  accountDetail?: AccountDetailFragment
  shippingContact?: AccountContactFragment
  billingContact?: AccountContactFragment
  onExecute: () => void
  setOrderExecutedState: (redirect: boolean) => void
  refresh?: () => void
  reload?: () => void
}
export function useMarkAsExecuted({
  orderId,
  accountDetail,
  shippingContact,
  setOrderExecutedState,
  billingContact,
  refresh,
  reload,
}: UseMarkAsExecutedProps) {
  const errorHandler = useErrorHandler()
  const snackbarHandler = useSnackbarHandler()
  const accountId = accountDetail?.id ?? ''
  const accountAddress = accountDetail?.address

  const hasContacts = !!shippingContact && !!billingContact
  const hasContactsAddresses = !!shippingContact?.address && !!billingContact?.address
  const hasAccountAddress = !!accountDetail?.id && isAccountAddressComplete(accountAddress)

  const toggleAddressIsRequiredDialog = useAddressIsRequiredDialog({ accountId })
  const toggleShipToAddressInvalid = useShipToAddressInvalid({ accountId })
  const [, updateOrderStatus] = useUpdateOrderStatusMutation()

  const [statusUpdatedOn, setStatusUpdatedOn] = useState<number | undefined>(undefined)

  const executeWithAvalaraAddressValidation = useCallback(
    (updateDate?: number) => {
      async function doAsync() {
        await updateOrderStatus({
          id: orderId,
          status: OrderStatus.Executed,
          statusUpdatedOn: updateDate,
        })
        refresh?.()
        setOrderExecutedState(true)
        snackbarHandler.successAlert('Order has been executed!')
      }
      doAsync().catch((err) => {
        if (isTypeOfCombinedError(err)) {
          const isAddressInvalid = err.graphQLErrors.some(
            (gqlError) => gqlError.extensions?.errorId === 'AddressValidation'
          )

          if (isAddressInvalid) {
            toggleShipToAddressInvalid()
            return
          } else if (err.message.includes('expired')) {
            reload?.()
          }
        }

        errorHandler(err)
      })
    },
    [
      errorHandler,
      orderId,
      refresh,
      reload,
      setOrderExecutedState,
      snackbarHandler,
      toggleShipToAddressInvalid,
      updateOrderStatus,
    ]
  )

  const toggleUseAccountAddressDialog = useUseAccountAddressDialog({
    accountDetail,
    shippingContact,
    billingContact,
    onExecute: () => executeWithAvalaraAddressValidation(statusUpdatedOn),
  })

  return useCallback(
    (updatedDate?: number) => {
      setStatusUpdatedOn(updatedDate)
      if (!hasContacts) {
        snackbarHandler.pushAlert('Please add a shipping and billing contact to execute.', 'warning')
        return
      }
      if (!hasContactsAddresses) {
        hasAccountAddress ? toggleUseAccountAddressDialog() : toggleAddressIsRequiredDialog()
      } else {
        executeWithAvalaraAddressValidation(updatedDate)
      }
    },
    [
      snackbarHandler,
      hasContacts,
      hasAccountAddress,
      hasContactsAddresses,
      toggleUseAccountAddressDialog,
      toggleAddressIsRequiredDialog,
      executeWithAvalaraAddressValidation,
    ]
  )
}
