import { OrderAmendOrCancelDate } from '@/pageComponents/orders/AmendmentOrderPage/OrderAmendOrCancelDate'
import { CardContent, CardHeader, Grid, Typography } from '@mui/material'
import BillyCard from '../../../components/card/billyCard'
import JotaiUrqlErrors from '../../../components/error/jotaiUrqlErrors'
import JotaiMuiCheckbox from '../../../components/input/JotaiMuiCheckbox'
import { JotaiUrqlBillySnackBar } from '../../../components/snackBar/useJotaiUrqlBillySnackBar'
import { useJotaiFormContext } from '../../../components/state/jotaiFormProvider'
import JotaiReadValue from '../../../components/state/jotaiReadValue'
import { UpsertAmendmentDocument } from '../../../generated/graphql'
import { AmendCancelCustomerDetailsSection } from '../EditOrderPage/cards/BillyOrderDetailsCard/DetailsGridLayout'
import { OrderDetailsSection } from '../EditOrderPage/cards/BillyOrderDetailsCard/OrderDetailsSection'
import { AmendSubscriptionPageState } from './AmendmentOrderPage'
import { useAmendmentOrderPageStyles } from './useAmendmentOrderPageStyles'

export function AmendmentOrderDetailsCard() {
  const jotaiForm = useJotaiFormContext<AmendSubscriptionPageState>()
  const { classes } = useAmendmentOrderPageStyles()

  return (
    <BillyCard>
      <JotaiReadValue
        atomSelector={(form: AmendSubscriptionPageState) => form.orderDetail.account?.name}
        form={jotaiForm}
        render={(accountName) => <CardHeader title={accountName} subheader="Order Details" />}
      />
      <CardContent>
        <JotaiUrqlErrors jotaiForm={jotaiForm} />
        <JotaiUrqlBillySnackBar document={UpsertAmendmentDocument} jotaiForm={jotaiForm} />
        <OrderDetailsSection />
        <AmendCancelCustomerDetailsSection />

        <Typography component="h6" className={classes.rowLabel}>
          Subscription Details
        </Typography>
        <Grid container direction="row" spacing={2}>
          <OrderAmendOrCancelDate />
          <Grid item lg={4} xl={2} className={classes.fieldGridItem} direction="row" spacing={2}>
            <JotaiMuiCheckbox
              atomSelector={(form) => form.orderDetail.autoRenew}
              form={jotaiForm}
              atomUpdater={(value, draft) => (draft.orderDetail.autoRenew = value)}
              fieldLabel="Auto-Renew"
            />
          </Grid>
        </Grid>
      </CardContent>
    </BillyCard>
  )
}
