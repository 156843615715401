import buildLogger from '@/util/logger'
import { DataGridProProps } from '@mui/x-data-grid-pro'
import { SortOrder } from 'elastic-ts'
import { useCallback, useMemo, useState } from 'react'
import { BillyColumn, ColumnSort } from './baseTable'
const logger = buildLogger('useEsTableSorting')

const getSortKeyFromColumns = ({
  columns,
  defaultSortField,
  onError,
}: {
  columns: ReadonlyArray<BillyColumn>
  defaultSortField?: string
  onError?: (error: unknown) => void
}) => {
  try {
    const ret = defaultSortField ?? (columns.filter((c) => c.sort)[0]?.accessor as string)
    if (!ret) {
      throw new Error('Please add at least one sortable column to table columns.')
    }
    return ret
  } catch (error) {
    logger.error({ error })
    onError?.(error)
  }
  return ''
}

export function useEsTableSorting(columns: ReadonlyArray<BillyColumn>, defaultSortField?: string) {
  const [columnSort, setColumnSort] = useState<ColumnSort>({
    key:
      getSortKeyFromColumns({
        columns,
        defaultSortField,
      }) ?? '',
    order: 'desc',
  })

  const isSortingValid = useMemo(
    () => columns.find((column) => column.accessor === columnSort.key),
    [columns, columnSort.key]
  )
  /**
   * BaseTable
   */
  const onChangeColumnSort = useCallback(
    (key: string, order: SortOrder) => {
      if (columnSort.key !== key || columnSort.order !== order) {
        setColumnSort({ key, order })
      }
    },
    [columnSort]
  )

  const sortFieldKey = `${columnSort.key}${
    columnSort.key === 'status' || columnSort.key === 'order_type' ? '.keyword' : ''
  }`

  const sortField = useMemo(() => [{ [sortFieldKey]: columnSort.order }], [columnSort.order, sortFieldKey])

  /**
   * ES DGP
   */
  const toggleCurrentSortDirection = useCallback(() => {
    const newOrder = columnSort.order === 'asc' ? 'desc' : 'asc'
    onChangeColumnSort(columnSort.key, newOrder)
  }, [columnSort, onChangeColumnSort])

  const onSortModelChange: DataGridProProps['onSortModelChange'] = useCallback(
    (model) => {
      const targetSort = model[0]

      if (targetSort) {
        onChangeColumnSort?.(targetSort.field as string, targetSort.sort as 'asc' | 'desc')
      } else {
        toggleCurrentSortDirection()
      }
    },
    [onChangeColumnSort, toggleCurrentSortDirection]
  )

  const sortModel: DataGridProProps['sortModel'] = useMemo(
    () => (columnSort ? [{ field: columnSort.key, sort: columnSort.order }] : []),
    [columnSort]
  )

  return useMemo(
    () => (isSortingValid ? { sortField, onSortModelChange, sortModel, isValid: true } : { isValid: false }),
    [sortField, onSortModelChange, sortModel, isSortingValid]
  )
}
