import { BillyStepperForm, Step, StepperFormChildren } from '@/components/BillyStepperForm/BillyStepperForm'
import { PageContainer } from '@/components/PageContainer/PageContainer'
import { AmendmentBillingCard } from '@/pageComponents/orders/AmendmentOrderPage/AmendmentBillingCard'
import { AmendmentOrderDetailsCard } from '@/pageComponents/orders/AmendmentOrderPage/AmendmentOrderDetailsCard'
import { AmendmentOrderLayout } from '@/pageComponents/orders/AmendmentOrderPage/AmendmentOrderLayout'
import { AmendmentOrderCardStack } from '@/pageComponents/orders/AmendmentOrderPage/AmendmentOrderPage'
import { OrderEditItemsCard } from '@/pageComponents/orders/EditOrderPage/cards/OrderEditItemsCard'
import { IncludedTermsCard } from '../IncludedTerms/IncludedTermsCard'
import DocumentMasterTemplateCard from '../documentMasterTemplateCard'
import OrderDiscounts from '../orderDiscounts'

const AmendmentOrderSteps: Step[] = [
  {
    id: 'Order Details',
    label: 'Order Details',
    children: <AmendmentOrderDetailsCard />,
  },
  {
    id: 'Order Items',
    label: 'Order Items',
    children: <OrderEditItemsCard orderType={'AMENDMENT'} />,
  },
  {
    id: 'Discount',
    label: 'Order Discount',
    children: <OrderDiscounts />,
    optional: true,
  },
  {
    id: 'Billing',
    label: 'Billing',
    children: <AmendmentBillingCard />,
    optional: true,
  },
  {
    id: 'Document Template',
    label: 'Document Template',
    children: <DocumentMasterTemplateCard />,
    optional: true,
  },
  {
    id: 'Included Terms',
    label: 'Included Terms',
    children: <IncludedTermsCard />,
    optional: true,
  },

  {
    id: 'Preview',
    label: 'Preview',
    children: <AmendmentOrderCardStack />,
  },
]

export function AmendmentOrderStepperContent() {
  return (
    <BillyStepperForm.Form steps={AmendmentOrderSteps}>
      {(stepperChildrenProps: StepperFormChildren) => {
        return (
          <PageContainer
            slot={{
              Layout: AmendmentOrderLayout,
            }}
            slotProps={{
              Layout: stepperChildrenProps,
            }}
          >
            <BillyStepperForm.Content {...stepperChildrenProps} />
          </PageContainer>
        )
      }}
    </BillyStepperForm.Form>
  )
}
