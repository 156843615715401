import { Edit, FileDownload, RemoveRedEye } from '@mui/icons-material'
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import { SortOrder } from 'elastic-ts'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Cell,
  Column,
  ColumnInterfaceBasedOnValue,
  Row,
  TableInstance,
  TableOptions,
  UsePaginationInstanceProps,
  UsePaginationOptions,
  usePagination,
  useTable,
} from 'react-table'
import { makeStyles } from 'tss-react/mui'
import buildLogger from '../../util/logger'
import useHasChanged from '../../util/useHasChanged'
import BillyLink from '../link/billyLink'
import navCommon, { drawerShownBreakpoints } from '../nav/navCommon'
import { BaseTablePaginationActions } from './BaseTablePaginationActions'
import BaseTableHeader from './baseTableHeader'
import { WithCellNavigation, buildLinkCell } from './cells/LinkCell'
import BooleanCell from './cells/booleanCell'
import CurrencyCell, { dynamicCurrencyCell, staticCurrencyCell, unitPriceCell } from './cells/currencyCell'
import DateCell, { NumberDateCell } from './cells/dateCell'
import EndDateCell from './cells/endDateCell'
import EnumCell from './cells/enumCell'
import QuantityCell from './cells/quantityCell'
import TimeCell, { NumberTimeCell } from './cells/timeCell'
import { GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid-pro'
import BulkInvoiceStatusChip, {
  dynamicBulkInvoiceStatusChip,
} from '@/pageComponents/invoices/bulk/bulkInvoiceStatusChip'
import EntityCell from '@/components/table/cells/EntityCell'

export const logger = buildLogger('BaseTable')

export const maxResults = 10000

const SearchFieldDataTypes = [
  'string',
  'number',
  'date',
  'datetime',
  'endDate',
  'currency',
  'unitPrice',
  'enum',
  'boolean',
  'link',
  'unixDate',
  'unixTime',
  'quantity',
  'bulk-invoice-status',
  'entity-status',
] as const

export type SearchFieldDataType = typeof SearchFieldDataTypes[number]

// type guard for SearchFieldDataType
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isSearchFieldDataType(dataType: any): dataType is SearchFieldDataType {
  return SearchFieldDataTypes.includes(dataType)
}

export const getSizeByRatio = (
  ratio: number
): {
  minWidth: number
  maxWidth: number
  flex?: number
} => {
  return {
    minWidth: 8 * 6 * (1 + ratio),
    maxWidth: 8 * 18 * (1 + ratio),
    flex: ratio,
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getDGPRenderCellByColumn = (column: BillyColumn<any>) =>
  isSearchFieldDataType(column.dataType)
    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) => {
        switch (column.dataType) {
          case 'date':
            return <DateCell value={params.value} />
          case 'datetime':
            return <TimeCell value={params.value} />
          case 'endDate':
            return <EndDateCell value={params.value} />
          case 'boolean':
            return <BooleanCell value={params.value} />
          case 'currency':
            return (
              <CurrencyCell
                value={params.value}
                currency={column.currencyField ? params.row[column.currencyField] : column.currencyType}
              />
            )
          case 'unitPrice':
            return <CurrencyCell value={params.value} currency={column.currencyType} />
          case 'enum':
            return <EnumCell value={params.value} />
          case 'link':
            return buildLinkCell({
              getLinkTarget: column.getLinkTarget,
              navigationMode: column.navigationMode,
            })({
              value: params.value,
              row: params.row,
            })
          case 'unixDate':
            return <NumberDateCell value={params.value} />
          case 'unixTime':
            return <NumberTimeCell value={params.value} />
          case 'quantity':
            return <QuantityCell value={params.value} />
          case 'bulk-invoice-status':
            return <BulkInvoiceStatusChip status={params.value} phase={params.row['phase']} />
          case 'entity-status':
            return <EntityCell value={params.value} />
          default:
            return undefined
        }
      }
    : undefined

export const getSizeByDataType = (dataType?: SearchFieldDataType) => {
  switch (dataType) {
    case 'boolean':
      return getSizeByRatio(1)
    case 'date':
    case 'endDate':
    case 'unixDate':
    case 'enum':
    case 'bulk-invoice-status':
    case 'entity-status':
      return getSizeByRatio(2)
    case 'currency':
    case 'link':
    case 'unitPrice':
    case 'datetime':
    case 'unixTime':
    case 'number':
    case 'quantity':
    case 'string':
    default:
      return getSizeByRatio(3)
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type BillyColumn<DataType extends object = any> = Column<DataType> &
  WithCellNavigation & {
    currencyType?: string
    currencyField?: string
    dataType?: SearchFieldDataType
    hidden?: boolean
    defaultInvisible?: boolean
    size?: 'shrink' | 'expand'
    wrapping?: 'truncate' | 'wrap'
    sort?: boolean
    // settings to any since will be moving to DGP
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Cell?: any
  }

export const useStyles = makeStyles<{ isRowClick: boolean }>()((theme, params, _classes) => {
  const { isRowClick } = params
  const hoverStyles = isRowClick
    ? {
        '&:hover': {
          backgroundColor: 'rgba(236, 233, 248, 0.35)',
          '& .table-row-action-icon': {
            opacity: 1,
          },
        },
        '&.MuiTableRow-footer:hover': {
          backgroundColor: 'inherit',
        },
      }
    : {
        '&:nth-of-type(even)': {
          backgroundColor: '#F9FAFB',
        },
      }
  return {
    baseTableRoot: {
      overflow: 'auto',
    },
    headerCell: {
      whiteSpace: 'nowrap',
    },
    loadingMask: {
      opacity: 0.5,
      pointerEvents: 'none',
    },
    loadingSpinner: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      [theme.breakpoints.up(drawerShownBreakpoints[0])]: {
        paddingLeft: navCommon.drawerWidth,
      },
      transform: 'translate(-50%, -50%)',
    },
    table: {
      '& .MuiTableHead-root': {
        '& .MuiTableCell-root': {
          fontWeight: 600,
          backgroundColor: '#EDF0F3',
        },
      },
      '& .MuiTableBody-root': {
        '& .MuiTableRow-root': {
          '& .table-row-action-icon': {
            display: 'flex',
            justifyContent: 'flex-end',
            color: theme.palette.action.active,
            opacity: 0,
          },
          ...hoverStyles,
          cursor: isRowClick ? 'pointer' : 'inherit',
          transition: 'all 0.15s ease',
          '& .MuiTableCell-root': {
            height: 54,
            '& .MuiTableRow-root': {
              backgroundColor: '#F9FAFB',
            },
          },
        },
      },
    },
    tableCellTruncate: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      maxWidth: 0,
    },
    tableCellExpand: {
      width: '100%',
    },
    tableCellNormalSize: {
      minWidth: 200,
    },
    tableCellShrink: {
      width: 0,
      whiteSpace: 'nowrap',
    },
    tableCellWrap: {
      maxWidth: 0,
      whiteSpace: 'normal',
      '& :first-of-type': {
        display: 'flex',
        flexWrap: 'wrap',
        rowGap: theme.spacing(1.5),
      },
    },
    textAlignRight: {
      textAlign: 'right',
    },
    numericCell: {
      fontVariantNumeric: 'tabular-nums',
    },
  }
})

export type Pagination = {
  onPageIndexChange: (pageIndex: number) => void
  totalRows: number
  pageIndex?: number
}

export type ColumnSort = {
  key: string
  order: SortOrder
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type BaseTableProps<DataType extends object = any> = {
  columns: ReadonlyArray<BillyColumn<DataType>>
  // WARN: DO NOT make this a ReadonlyArray.  It requires us to use the spread
  // operator on the data below which causes BaseTable to go into an infinite
  // loop due to rendering via uncontrolled side effects.
  data?: Array<DataType>
  isLoading?: boolean
  /** For navigation use getLinkTarget prop instead */
  onRowClick?: (row: Row<DataType>) => void
  getLinkTarget?: (row: Row<DataType>) => string
  renderEmpty?: React.FunctionComponent
  rowId?: string
  rowActionType?: 'edit' | 'download' | 'preview'
  pageSize?: number
  pagination?: Pagination
  hideFooter?: boolean
  CustomRowAction?: React.FC<{ row: Row<DataType> }>
  columnSort?: ColumnSort
  onChangeColumnSort?: (key: string, order: SortOrder) => void
}

const BaseTable = <DataType extends object>({
  columns,
  data,
  isLoading = false,
  onRowClick,
  rowActionType = 'preview',
  getLinkTarget,
  renderEmpty: RenderEmpty,
  rowId,
  pageSize = 10,
  pagination,
  hideFooter = false,
  CustomRowAction,
  columnSort,
  onChangeColumnSort,
}: BaseTableProps<DataType>) => {
  const isRowClick = !!onRowClick
  const { classes, cx } = useStyles({ isRowClick })
  const [expectingDataToChange, setExpectingDataToChange] = useState(true)

  const columnWithHoverActionOrCustomAction = useCallback(
    (columns: BillyColumn<DataType>[]) => {
      let newColumns = columns
      if (rowActionType && onRowClick) {
        newColumns = newColumns.concat([
          {
            Header: ' ',
            size: 'shrink',
            Cell: () => (
              <div className="table-row-action-icon">
                {rowActionType === 'download' ? (
                  <FileDownload color="inherit" />
                ) : rowActionType === 'preview' ? (
                  <RemoveRedEye color="inherit" />
                ) : rowActionType === 'edit' ? (
                  <Edit color="inherit" />
                ) : (
                  <></>
                )}
              </div>
            ),
          },
        ])
      }
      if (CustomRowAction) {
        newColumns = newColumns.concat([
          {
            Header: '  ',
            size: 'shrink',
            Cell: (row) => <CustomRowAction row={row} />,
          },
        ])
      }
      return newColumns
    },
    [rowActionType, onRowClick, CustomRowAction]
  )

  const decoratedColumns = useMemo(
    () =>
      columnWithHoverActionOrCustomAction(
        columns.map((column) => {
          if (column.dataType && !column.Cell) {
            if (column.dataType === 'date') {
              column.Cell = DateCell
            } else if (column.dataType === 'datetime') {
              column.Cell = TimeCell
            } else if (column.dataType === 'endDate') {
              column.Cell = EndDateCell
            } else if (column.dataType === 'currency') {
              if (column.currencyField) {
                column.Cell = dynamicCurrencyCell(column.currencyField)
              } else if (column.currencyType) {
                column.Cell = staticCurrencyCell(column.currencyType)
              } else {
                column.Cell = CurrencyCell
              }
            } else if (column.dataType === 'unitPrice') {
              column.Cell = unitPriceCell(column.currencyType)
            } else if (column.dataType === 'enum') {
              column.Cell = EnumCell
            } else if (column.dataType === 'bulk-invoice-status') {
              column.Cell = dynamicBulkInvoiceStatusChip('phase', 'status')
            } else if (column.dataType === 'entity-status') {
              column.Cell = EntityCell
            } else if (column.dataType === 'boolean') {
              column.Cell = BooleanCell
            } else if (column.dataType === 'link') {
              column.Cell = buildLinkCell({
                getLinkTarget: column.getLinkTarget,
                navigationMode: column.navigationMode,
              })
            } else if (column.dataType === 'unixDate') {
              column.Cell = NumberDateCell
            } else if (column.dataType === 'unixTime') {
              column.Cell = NumberTimeCell
            } else if (column.dataType === 'quantity') {
              column.Cell = QuantityCell
            }
          }
          return column
        })
      ),
    [columns, columnWithHoverActionOrCustomAction]
  )

  const memoizedData = useMemo(() => data || [], [data])
  const dataHasChanged = useHasChanged(JSON.stringify(memoizedData))

  const useTableParams: TableOptions<DataType> &
    UsePaginationOptions<DataType> & {
      initialState: { pageIndex: number; pageSize: number }
    } = {
    columns: decoratedColumns,
    // WARN: do not spread this property. It will cause an infinite loop bug
    // in baseTable related to making 'data' readonly.
    data: memoizedData,
    initialState: { pageIndex: pagination?.pageIndex || 0, pageSize },
  }

  const pageCount = pagination ? Math.ceil(pagination.totalRows / pageSize) : Math.ceil(data?.length || 0 / pageSize)

  if (pagination) {
    useTableParams.manualPagination = true
    useTableParams.pageCount = pageCount
  }

  const table = useTable(useTableParams, usePagination)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    state: { pageIndex },
  } = table as unknown as UsePaginationInstanceProps<any> & TableInstance<any> & { state: { pageIndex: number } }

  useEffect(() => {
    if (pagination && !pagination.pageIndex) {
      if (dataHasChanged && !expectingDataToChange) {
        gotoPage(0)
      }
      if (dataHasChanged && expectingDataToChange) {
        setExpectingDataToChange(false)
      }
    }
  }, [dataHasChanged, expectingDataToChange, gotoPage, pagination])

  useEffect(() => {
    if (pagination) {
      pagination.onPageIndexChange(pageIndex)
    }
  }, [pageIndex, pagination])

  const handleRowClick = (row: Row<any>) => () => {
    onRowClick && onRowClick(row)
  }

  const header = (
    <TableHead>
      {headerGroups.map((headerGroup) => (
        <BaseTableHeader
          headerGroup={headerGroup}
          isRowClick={isRowClick}
          columnSort={columnSort}
          onChangeColumnSort={onChangeColumnSort}
          key={headerGroup.id}
        />
      ))}
    </TableHead>
  )

  const body = (
    <TableBody {...getTableBodyProps}>
      {page.map((row: Row<any>, i: number) => {
        prepareRow(row)
        const rowProps = row.getRowProps(() => ({ key: rowId ? row.original[rowId] : row.original }))
        const hasExpand = row.cells.some((cell) => (cell.column as BillyColumn<DataType>).size === 'expand')
        return (
          <TableRow {...rowProps} onClick={handleRowClick(row)} key={`${i}-${rowProps.key}`}>
            {row.cells.map((cell, index) => {
              return (
                <BaseTableCell<DataType>
                  key={cell.column.id.toString()}
                  {...{
                    cell,
                    hasExpand,
                    getLinkTarget,
                    row,
                    index,
                    isRowClick,
                  }}
                />
              )
            })}
          </TableRow>
        )
      })}
    </TableBody>
  )

  const handleChangePage = useCallback(
    (_, index) => {
      gotoPage(index)
      setExpectingDataToChange(true)
    },
    [gotoPage]
  )

  const footer = (
    <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPage={pageSize}
          rowsPerPageOptions={[pageSize]}
          count={pagination?.totalRows || data?.length || 0}
          onPageChange={handleChangePage}
          page={Math.min(pageIndex, Math.max(pageCount - 1, 0))}
          ActionsComponent={BaseTablePaginationActions}
          labelDisplayedRows={({ from, to, count }) =>
            `${from.toLocaleString('en-US')}–${to.toLocaleString('en-US')} of ${count.toLocaleString('en-US')}${
              count === maxResults ? '+' : ''
            }`
          }
        />
      </TableRow>
    </TableFooter>
  )

  return (
    <div className={cx(classes.baseTableRoot, isLoading ? classes.loadingMask : undefined)}>
      {isLoading && (
        <div className={classes.loadingSpinner}>
          <CircularProgress />
        </div>
      )}

      {(memoizedData && memoizedData.length > 0) || !RenderEmpty ? (
        <Table {...getTableProps()} className={classes.table}>
          {header}
          {body}
          {!hideFooter && footer}
        </Table>
      ) : (
        <>
          {!isLoading && (
            <div>
              <RenderEmpty />
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default BaseTable

export function BaseTableCell<DataType extends object>({
  cell,
  hasExpand,
  getLinkTarget,
  row,
  index,
  isRowClick,
}: {
  cell: Cell<DataType>
  hasExpand: boolean
  getLinkTarget: ((row: Row<DataType>) => string) | undefined
  row: Row<DataType>
  index: number
  isRowClick: boolean
}) {
  const { classes, cx } = useStyles({ isRowClick })
  const cellProps = cell.getCellProps()
  const column: BillyColumn<DataType> = cell.column
  const shouldShrink =
    column.size === 'shrink' ||
    (!column.size &&
      (column.dataType === 'date' ||
        column.dataType === 'endDate' ||
        column.dataType === 'enum' ||
        column.dataType === 'number' ||
        column.dataType === 'currency' ||
        column.dataType === 'quantity'))

  const shouldExpand = column.size === 'expand'
  const shouldNormalSize = !shouldShrink && !shouldExpand && hasExpand
  const shouldTruncate = column.wrapping === 'truncate' || (!column.wrapping && !shouldShrink)
  const shouldWrap = !shouldTruncate && !shouldShrink
  const isNumericCell = column.dataType === 'number' || column.dataType === 'currency' || column.dataType === 'quantity'
  const shouldAlignRight = isNumericCell || column.dataType == 'datetime'
  const href = getLinkTarget ? getLinkTarget(row) : undefined
  return !column.hidden ? (
    <TableCell
      {...cellProps}
      key={cellProps.key}
      className={cx(
        shouldShrink && classes.tableCellShrink,
        shouldExpand && classes.tableCellExpand,
        shouldNormalSize && classes.tableCellNormalSize,
        shouldTruncate && classes.tableCellTruncate,
        shouldWrap && classes.tableCellWrap,
        isNumericCell && classes.numericCell,
        shouldAlignRight && classes.textAlignRight
      )}
    >
      {index == 0 && href ? (
        <BillyLink nextProps={{ href }}>
          <span title={shouldTruncate ? cell.value : undefined}>{cell.render('Cell', row)}</span>
        </BillyLink>
      ) : (
        <span title={shouldTruncate ? cell.value : undefined}>{cell.render('Cell', row)}</span>
      )}
    </TableCell>
  ) : (
    <></>
  )
}
