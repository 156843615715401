import { useErrorHandler } from '@/components/ErrorHandler/ErrorHandler'
import ActionButton from '@/components/button/actionButton'
import PageLoadingPlaceholder from '@/components/placeholder/pageLoadingPlaceholder'
import { useBillyRouter } from '@/components/route/useBillyRouter'
import { WithCustomizationLocator } from '@/components/state/context/customizationContext'
import { useModal, useModalsContext, WithModalParams } from '@/components/state/context/modalsContext'
import { useJotaiFormContext } from '@/components/state/jotaiFormProvider'
import { NewOrderFormData } from '@/pageComponents/orders/EditOrderPage/NewOrderPage'
import buildLogger from '@/util/logger'
import { Box, Dialog, DialogActions, DialogTitle } from '@mui/material'
import { GridFilterModel } from '@mui/x-data-grid-pro'
import { useCallback, useEffect, useState } from 'react'
import {
  ActionType,
  CustomFieldParentType,
  PlanFragment,
  PlanMinimalFragment,
  PlanStatus,
  useGetCustomFieldsBatchedQuery,
  useGetPlanMinimalsQuery,
} from '../../generated/graphql'
import { AddPlanDataGrid, AddPlanDataGridProps } from './AddPlanDataGrid'
import { useAddPlanCustomizationSlotProps } from './useAddPlanCustomizationSlotProps'
const logger = buildLogger('AddPlanDataGridDialog')

export interface WithCustomizationSlot {
  slot?: {
    defaultFilterModel?: GridFilterModel
  }
}
interface AddPlanProps extends Omit<AddPlanDataGridProps, 'onSelectionChange'> {
  readonly onCancel?: () => void
  readonly onSubmit?: (plans: ReadonlyArray<PlanMinimalFragment>) => void | Promise<void>
  readonly onOpen?: () => void
}

export type AddPlanDialogProps = Omit<WithModalParams, 'onSubmit'> &
  WithCustomizationSlot &
  AddPlanProps &
  WithCustomizationLocator

const formID = 'AddPlanDataGridDialog'

export function AddPlanDataGridDialog({
  open,
  loading,
  onOpen,
  onClose,
  onModalClose,
  onSubmit,
  slot,
  parentLocator,
  ...props
}: AddPlanDialogProps) {
  const [plans, setPlans] = useState<ReadonlyArray<PlanMinimalFragment>>([])

  const errorHandler = useErrorHandler()
  const [isSubmitting, setIsSubmitting] = useState(false)
  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    async function doAsync() {
      setIsSubmitting(true)
      await onSubmit?.(plans)
      setIsSubmitting(false)
      onClose?.()
    }
    doAsync().catch((error) => {
      setIsSubmitting(false)
      errorHandler(error)
    })
  }

  useEffect(() => {
    if (open) {
      onOpen?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <Dialog open={!!open} onClose={onModalClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ flexGrow: 1 }}>Add Plans</Box>
        </Box>
      </DialogTitle>
      <PageLoadingPlaceholder isLoading={!!loading} mode="dialog-table">
        <form id={formID} onSubmit={handleSubmit}>
          <AddPlanDataGrid
            parentLocator={parentLocator}
            {...props}
            onSelectionChange={(plans) => setPlans(plans)}
            slot={slot}
          />
        </form>
      </PageLoadingPlaceholder>
      <DialogActions>
        <div style={{ flexGrow: 1 }} />
        <ActionButton
          key={'Cancel'}
          buttonData={{
            label: 'Cancel',
            onClick: onClose,
            color: 'inherit',
            buttonProps: { variant: 'outlined' },
          }}
        />
        <ActionButton
          key={'Add Plans'}
          buttonData={{
            label: 'Add Plans',
            loading: isSubmitting,
            disabledExplanation: !plans.length && 'Please select at least one plan',
            buttonProps: {
              type: 'submit',
              form: formID,
            },
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

export function useOrderAddPlanDataGridDialog<F extends NewOrderFormData = NewOrderFormData>({
  addPlan,
  parentLocator,
}: {
  addPlan: (plan: PlanFragment | null) => void
} & WithCustomizationLocator) {
  const router = useBillyRouter()
  const [, , toggleModal] = useModalsContext()
  const jotaiForm = useJotaiFormContext<F>()

  const [accountId, setAccountId] = useState<string>('')

  const [planMinimalsResponse] = useGetPlanMinimalsQuery({
    variables: {
      accountId,
      status: PlanStatus.Active,
    },
    pause: !accountId,
  })

  const addPlanCustomizeSlot = useAddPlanCustomizationSlotProps(accountId)

  const { planMinimalsFiltered, plansList } = jotaiForm.useSelect(
    useCallback(
      (form) => {
        const { orderDetail, plansList } = form
        const lineItemPlanIDs = orderDetail.lineItems.map((current) => current.plan?.id)

        function existsInPlansList(planMinimal: PlanMinimalFragment) {
          return !!plansList?.some((plan) => plan.id === planMinimal.id)
        }
        function isNotPickedOrPickedButRemoved(planMinimal: PlanMinimalFragment) {
          const existingLineItems = orderDetail.lineItems.filter((current) => current.plan?.id === planMinimal.id)
          return (
            !lineItemPlanIDs.includes(planMinimal.id) ||
            existingLineItems?.every((lineItem) => lineItem.action === ActionType.Remove)
          )
        }

        const planMinimalsFiltered = (planMinimalsResponse.data?.planMinimals ?? [])
          .filter(existsInPlansList)
          .filter(isNotPickedOrPickedButRemoved)

        return accountId
          ? {
              planMinimalsFiltered,
              plansList,
            }
          : {
              planMinimalsFiltered: [],
              plansList: [],
            }
      },
      [planMinimalsResponse.data?.planMinimals, accountId]
    )
  )

  const [customFieldsResponse] = useGetCustomFieldsBatchedQuery({
    variables: {
      parentObjectType: CustomFieldParentType.Plan,
      parentObjectIds: planMinimalsFiltered.map((plan) => plan.id || '') || [],
    },
  })

  const customFields = customFieldsResponse.data?.customFieldsBatched ?? []

  useModal<AddPlanDialogProps>({
    component: AddPlanDataGridDialog,
    schema: {
      key: formID,
      modalProps: {
        ...addPlanCustomizeSlot,
        loading: planMinimalsResponse.fetching,
        data: planMinimalsFiltered,
        customFields,
        parentLocator,
        onSubmit: async (newPlans: ReadonlyArray<PlanMinimalFragment>) => {
          //show user instant feedback
          await router.esDelay(1)
          const addingPlanIDs = newPlans.map((current) => current.id)
          const plansToAdd = (plansList ?? []).filter((current) => addingPlanIDs.includes(current.id))
          plansToAdd.forEach((current) => addPlan(current))
        },
      },
    },
  })
  const toggleAddPlanDataGridDialog = useCallback(
    (accountId: string) => {
      toggleModal(formID)
      setAccountId(accountId)
    },
    [toggleModal]
  )

  return [toggleAddPlanDataGridDialog]
}

export function useAddPlanDataGridDialog(modalProps: AddPlanDialogProps) {
  const [, , toggleModal] = useModalsContext()

  useModal<AddPlanDialogProps>({
    component: AddPlanDataGridDialog,
    schema: {
      key: formID,
      modalProps,
    },
  })
  const toggleAddPlanDataGridDialog = useCallback(() => {
    toggleModal(formID)
  }, [toggleModal])

  return [toggleAddPlanDataGridDialog]
}
