import { formatUnixDate } from '@/util/datetime/luxon/dateUtil'
import { ActionType } from '../../../generated/graphql'
import { billableCurrencyFormat, unitPriceFormatter } from '../../../util/currencyUtil'
import { NewOrderFormData } from '../EditOrderPage/NewOrderPage'
import { OrderItemRowProps, makeRampDisabledExplanation } from './orderChargeRow'

export function reduceLineItem({
  orderDetail,
  lineItemIndex,
  orderType,
  currency,
}: Pick<OrderItemRowProps, 'currency' | 'lineItemIndex' | 'orderType'> & {
  orderDetail: NewOrderFormData['orderDetail']
}) {
  const lineItems = orderDetail.lineItems
  const lineItem = lineItems[lineItemIndex]

  const charge = lineItem.charge

  const effectiveDate = lineItem.effectiveDate
  const isRamp = lineItem.isRamp || false
  const startDate = orderDetail.startDate
  const rampIntervalLength = orderDetail.rampInterval?.length || 0

  const amendmentLineInAmendmentPeriod =
    orderType === 'AMENDMENT' && !!lineItem.endDate && lineItem.endDate <= startDate
  const isMinimumCommitCharge = lineItems.some((li) => li.charge.minimumCommitBaseChargeId === lineItem.charge.id)
  let instances = lineItems.filter((li) => li.plan?.id === lineItem.plan?.id && li.charge.id === lineItem.charge.id)
  if (orderType === 'RESTRUCTURE') {
    instances = instances.filter((li) => li.action !== ActionType.Remove)
  }
  const isOnlyInstanceOfCharge = instances.length === 1
  const isChargeRamped = instances.some((li) => li.isRamp)
  const rampDisabledExplanation = makeRampDisabledExplanation(
    isRamp,
    startDate,
    charge,
    rampIntervalLength,
    isOnlyInstanceOfCharge,
    orderType
  )

  const showDate = isChargeRamped || (!!lineItem.effectiveDate && !!lineItem.endDate)

  const isDiscountEnabled = [ActionType.Add, ActionType.Renewal, ActionType.Restructure].includes(lineItem.action)

  const isCustomListPriceEditEnabled =
    orderType !== 'CANCELLATION' &&
    charge.isCustom &&
    (lineItem.action === ActionType.Add ||
      lineItem.action === ActionType.Restructure ||
      lineItem.action === ActionType.Renewal)

  const isListPriceOverrideEnabled =
    orderType !== 'CANCELLATION' &&
    charge.isListPriceEditable &&
    (lineItem.action === ActionType.Add ||
      lineItem.action === ActionType.Restructure ||
      lineItem.action === ActionType.Renewal)

  const sellUnitPrice =
    lineItem.sellUnitPrice === null ? 'N/A' : unitPriceFormatter({ currency, value: lineItem.sellUnitPrice })

  const discount =
    lineItem.listUnitPrice === null
      ? 'N/A'
      : unitPriceFormatter({
          currency,
          value: lineItem.discountAmount ?? 0,
        })

  const unitDiscount =
    lineItem.listUnitPrice === null
      ? 'N/A'
      : unitPriceFormatter({
          currency,
          value: lineItem.listUnitPrice && lineItem.sellUnitPrice ? lineItem.listUnitPrice - lineItem.sellUnitPrice : 0,
        })

  const listPrice =
    lineItem.listUnitPrice == null
      ? 'N/A'
      : unitPriceFormatter({
          currency,
          value: lineItem.listUnitPrice ?? charge.amount ?? 0,
        })

  const showRemovedRowStyle = lineItem.action === ActionType.Remove && orderType !== 'CANCELLATION'
  const resetToQuantity = (() => {
    const charge = orderDetail.currentSubscription?.charges.find(
      (charge) => charge?.id === lineItem.subscriptionChargeId
    )

    return charge?.quantity
  })()

  const canEditBeToggled = orderType === 'AMENDMENT'
  const showResetQuantity = (() => {
    const updatedQuantity = orderDetail.lineItems[lineItemIndex]?.quantity ?? 0
    return canEditBeToggled && (!!resetToQuantity || resetToQuantity === 0) && resetToQuantity !== updatedQuantity
  })()

  const filteredLineItems = lineItems.filter((li) => li.plan?.id === lineItem.plan?.id)

  const allDuplicateCharges = filteredLineItems.filter((li) => li.charge.id === lineItem.charge.id)

  const isChargeDuplicated =
    allDuplicateCharges.length > 1 &&
    allDuplicateCharges[0]?.id !== lineItem.id &&
    [ActionType.Add, ActionType.Restructure].includes(lineItem.action) &&
    !lineItem.isRamp

  return {
    lineItem,
    isRamp,
    startDate,
    rampIntervalLength,
    amendmentLineInAmendmentPeriod,
    isMinimumCommitCharge,
    isOnlyInstanceOfCharge,
    isChargeRamped,
    rampDisabledExplanation,
    showDate,
    isDiscountEnabled,
    sellUnitPrice,
    isCustomListPriceEditEnabled,
    isListPriceOverrideEnabled,
    discount,
    unitDiscount,
    listPrice,
    showRemovedRowStyle,
    canEditBeToggled,
    resetToQuantity,
    showResetQuantity,
    charge,
    chargeName: charge.name,
    formattedDate:
      formatUnixDate(Number(effectiveDate)) +
      (lineItem.endDate ? ` - ${formatUnixDate(Number(lineItem.endDate) - 1)}` : ''),
    formattedCurrency: billableCurrencyFormat({ currency, value: lineItem.amount }),
    isChargeDuplicated,
  }
}
