import { BillyStepperForm, Step, StepperFormChildren } from '@/components/BillyStepperForm/BillyStepperForm'
import { PageContainer } from '@/components/PageContainer/PageContainer'
import { CancellationOrderCardStack } from '@/pageComponents/orders/CancellationOrderPage/CancellationOrderContent'
import { CancellationOrderDetailsCard } from '@/pageComponents/orders/CancellationOrderPage/CancellationOrderDetailsCard'
import { CancellationOrderLayout } from '@/pageComponents/orders/CancellationOrderPage/CancellationOrderLayout'
import { OrderEditItemsCard } from '@/pageComponents/orders/EditOrderPage/cards/OrderEditItemsCard'
import { IncludedTermsCard } from '../IncludedTerms/IncludedTermsCard'
import DocumentMasterTemplateCard from '../documentMasterTemplateCard'

const CancellationOrderSteps: Step[] = [
  {
    id: 'Order Details',
    label: 'Order Details',
    children: <CancellationOrderDetailsCard />,
  },
  {
    id: 'Order Items',
    label: 'Order Items',
    children: <OrderEditItemsCard orderType={'CANCELLATION'} />,
  },
  {
    id: 'Document Template',
    label: 'Document Template',
    children: <DocumentMasterTemplateCard />,
    optional: true,
  },
  {
    id: 'Included Terms',
    label: 'Included Terms',
    children: <IncludedTermsCard />,
    optional: true,
  },

  {
    id: 'Preview',
    label: 'Preview',
    children: <CancellationOrderCardStack />,
  },
]

export function CancellationOrderStepperContent() {
  return (
    <BillyStepperForm.Form steps={CancellationOrderSteps}>
      {(stepperChildrenProps: StepperFormChildren) => {
        return (
          <PageContainer
            slot={{
              Layout: CancellationOrderLayout,
            }}
            slotProps={{
              Layout: stepperChildrenProps,
            }}
          >
            <BillyStepperForm.Content {...stepperChildrenProps} />
          </PageContainer>
        )
      }}
    </BillyStepperForm.Form>
  )
}
