import {
  GridColDef,
  GridFilterModel,
  GridRowSelectionModel,
  GridSingleSelectColDef,
  ValueOptions,
} from '@mui/x-data-grid-pro'
import { GetCustomFieldsBatchedQuery, PlanMinimalFragment, Role } from '../../generated/graphql'

import { CustomizationFieldWithSpecialCondition } from '@/components/AddPlanDataGrid/useCustomizationParser'
import { useCustomizationProps, WithCustomizationLocator } from '@/components/state/context/customizationContext'
import { useVisibleColumnsProps } from '@/components/table/tableInCardWithSearchDGP'
import { useUserTenantSession } from '@/components/UserTenantSessionProvider/UserTenantSessionContext'
import { useCallback, useMemo, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import BillyDataGridPro from '../table/billyDataGridPro'
import TableEmptyContent from '../table/tableEmptyContent'
import { WithCustomizationSlot } from './AddPlanDataGridDialog'

export interface AddPlanDataGridProps extends WithCustomizationSlot {
  readonly loading?: boolean
  readonly data: ReadonlyArray<PlanMinimalFragment>
  readonly customFields?: GetCustomFieldsBatchedQuery['customFieldsBatched']
  readonly onSelectionChange?: (selected: ReadonlyArray<PlanMinimalFragment>) => void
}

// flat entry of the plan for use in the grid
interface PlanGridEntry {
  readonly id: string
  readonly name: string
  readonly description: string
  readonly product_name: string | undefined
  readonly product_category: string | undefined
  readonly plan: PlanMinimalFragment
}

export const usePlanDataGridStyle = makeStyles()((theme) => ({
  virtualScroller: {
    height: 'calc(100vh - 351px)',
  },
  overlayWrapper: {
    minHeight: '105px',
  },
}))
export function AddPlanDataGrid(props: AddPlanDataGridProps & WithCustomizationSlot & WithCustomizationLocator) {
  const { onSelectionChange } = props
  const { classes } = usePlanDataGridStyle()
  const { slot } = props
  const customizationProps = useCustomizationProps({
    displayName: 'addPlan',
    parentLocator: props.parentLocator,
  })

  const userTenantSession = useUserTenantSession()

  const planNameColumn: GridColDef = {
    headerName: 'Plan Name',
    field: 'name',
    align: 'left',
    width: 250,
    minWidth: 150,
  }

  const productNameColumn: GridColDef = {
    headerName: 'Product Name',
    field: 'product_name',
    align: 'left',
    width: 200,
    minWidth: 150,
  }

  const categoryColumn: GridColDef = {
    headerName: 'Category',
    field: CustomizationFieldWithSpecialCondition.ProductCategory,
    align: 'left',
    width: 200,
    minWidth: 150,
    valueOptions: [],
    type: 'singleSelect',
    colSpan: 1,
  }

  const descriptionColumn: GridColDef = {
    headerName: 'Description',
    field: 'description',
    align: 'left',
    width: 250,
    minWidth: 150,
  }

  const salesUserGridColumns: GridColDef[] = [planNameColumn, productNameColumn, categoryColumn, descriptionColumn]

  const idColumn: GridColDef = {
    headerName: 'ID',
    field: 'id',
    align: 'left',
    width: 150,
    minWidth: 100,
  }

  const fullUserPlanGridColumns: GridColDef[] = [
    idColumn,
    planNameColumn,
    productNameColumn,
    categoryColumn,
    descriptionColumn,
  ]

  const planGridColumns: GridColDef[] =
    userTenantSession.currentUser.role === Role.Sales ? salesUserGridColumns : fullUserPlanGridColumns

  const data: PlanGridEntry[] = props.data.map((plan) => {
    const customFields = props.customFields?.find(
      (customField) => customField?.parentObjectId === plan.id
    )?.customFieldEntries
    const customFieldMap: Record<string, string> = {}
    customFields?.forEach((customField) => {
      if (customField?.name && customField?.value) {
        customFieldMap[customField.name] = customField.value
        const column = planGridColumns.find((column) => column.field === customField.name)
        if (!column) {
          planGridColumns.push({
            headerName: customField.name,
            field: customField.name,
            align: 'left',
            width: 250,
            minWidth: 150,
            valueOptions: [customField.value],
            type: 'singleSelect',
            colSpan: 1,
          })
        } else {
          const valueOptions = ((column as GridSingleSelectColDef).valueOptions as ValueOptions[]) || []
          if (!valueOptions.find((valueOption) => valueOption === customField.value)) {
            ;(column as GridSingleSelectColDef).valueOptions = [...valueOptions, customField.value]
          }
        }
      }
    })

    const categories = (categoryColumn.valueOptions as ValueOptions[]) || []
    if (plan.product.productCategory?.name && !categories.includes(plan.product.productCategory?.name)) {
      categories.push(plan.product.productCategory?.name)
    }

    return {
      id: plan.id as string,
      name: plan.name,
      description: plan.description ?? '',
      product_name: plan.product.name ?? '',
      product_category: plan.product.productCategory?.name ?? undefined,
      plan: plan,
      ...customFieldMap,
    }
  })

  const handleSelection = useCallback(
    (rows: GridRowSelectionModel) => {
      const selected = data.filter((current) => rows.includes(current.id)).map((current) => current.plan)
      onSelectionChange?.(selected)
    },
    [data, onSelectionChange]
  )

  const [filters, setFilters] = useState<GridFilterModel | undefined>(slot?.defaultFilterModel)

  const columns = useMemo(
    () =>
      customizationProps
        ? [...planGridColumns].sort((columnA, columnB) => {
            if (customizationProps.columnOrderNormalized?.[columnA.field] === undefined) {
              return 1
            }
            if (customizationProps.columnOrderNormalized?.[columnB.field] === undefined) {
              return -1
            }
            return (
              customizationProps?.columnOrderNormalized?.[columnA.field] -
              customizationProps?.columnOrderNormalized?.[columnB.field]
            )
          })
        : planGridColumns,
    [planGridColumns, customizationProps]
  )

  const visibleColumnsProps = useVisibleColumnsProps({
    columns: columns.map((column) => ({
      accessor: column.field,
      defaultInvisible: customizationProps?.columnDefaultInvisible?.includes(column.field),
    })),
  })

  return (
    <>
      <BillyDataGridPro
        unstable_headerFilters
        classes={classes}
        variant="stacked"
        loading={props.loading}
        onRowSelectionModelChange={handleSelection}
        disableRowSelectionOnClick={true}
        disableMultipleRowSelection={false}
        disableColumnSelector={false}
        disableColumnResize={false}
        checkboxSelection
        rows={data}
        slots={{
          noRowsOverlay: TableEmptyContent,
          noResultsOverlay: TableEmptyContent,
        }}
        slotProps={{
          noRowsOverlay: { message: 'No Plans.' },
          noResultsOverlay: { message: 'No Plans Found.' },
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'name', sort: 'asc' }],
          },
        }}
        columns={columns}
        autoHeight={false}
        filterModel={filters}
        onFilterModelChange={(newFilterModel) => {
          setFilters(newFilterModel)
        }}
        {...visibleColumnsProps}
      />
    </>
  )
}
