import { FormControl, Tooltip } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { Draft } from 'immer'
import { selectAtom, useAtomValue } from 'jotai/utils'
import React, { ForwardedRef, MutableRefObject, useCallback, useEffect, useMemo, useRef } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useYupError } from '../../util/jotai'
import buildLogger from '../../util/logger'
import DesktopDatePickerWithTimezoneAbsoluteLuxon from '../DesktopDatePickerWithTimezone/DesktopDatePickerWithTimezoneAbsoluteLuxon'
import { IUnixtimeWithTimezone } from '../DesktopDatePickerWithTimezone/IUnixtimeWithTimezone'
import { ForwardedSchemaFormLuxonDatePickerProps } from '../SchemaForm/types/DateType'
import { useTenantTimeZone } from '../UserTenantSessionProvider/useTenantTimeZone'
import { JotaiForm } from '../state/useJotaiForm'

const logger = buildLogger('JotaiMuiDateField')

const useStyles = makeStyles()((theme: Theme) => ({
  formControl: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  helperText: {
    color: theme.palette.error.main,
  },
}))

export type BaseDateFieldProps = {
  datePickerProps?: ForwardedSchemaFormLuxonDatePickerProps
  disabledExplanation?: string | false
  disabled?: boolean
}

export type BillyDateFieldProps = {
  onChange: (unixTime: number) => void
  value: number | undefined
  error: string | undefined
} & BaseDateFieldProps

export type JotaiMuiDateFieldProps<T> = {
  atomSelector: (form: T) => number | undefined | null
  atomUpdater: (value: number | null, draft: Draft<T>) => void
  form: JotaiForm<T>
  errorPath: string
} & BaseDateFieldProps

function JotaiMuiDateField<T>(props: JotaiMuiDateFieldProps<T>, ref: ForwardedRef<HTMLDivElement>): JSX.Element {
  const { atomSelector, atomUpdater, datePickerProps, errorPath, form, disabled } = props
  const { classes } = useStyles()

  const selectorAtom = useMemo(() => selectAtom(form.atom, atomSelector), [atomSelector, form.atom])
  const unixValue = useAtomValue(selectorAtom)
  const error = useYupError(form, errorPath)

  const refObj = ref as MutableRefObject<HTMLDivElement>

  const inputRef = useRef<HTMLInputElement>(null)

  const tenantTimezone = useTenantTimeZone()

  useEffect(() => {
    if (error) {
      logger.debug({ error, refobj: refObj, inputRef: inputRef.current })
      inputRef?.current?.focus()
    }
  }, [error, refObj])

  const handleChange = useCallback(
    (datetime: IUnixtimeWithTimezone) => {
      form.set((draft) => {
        atomUpdater(datetime.unixtime, draft)
      })
    },
    [atomUpdater, form]
  )

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Tooltip title={props.disabledExplanation || ''} ref={ref}>
        <FormControl className={classes.formControl} variant={'outlined'}>
          <DesktopDatePickerWithTimezoneAbsoluteLuxon
            inputRef={inputRef}
            unixtime={unixValue ?? undefined}
            timezone={tenantTimezone}
            onChange={handleChange}
            disabled={!!props.disabledExplanation || disabled}
            datePickerProps={datePickerProps}
            error={!!error}
            helperText={error}
            label={props.datePickerProps?.label ?? ''}
          />
        </FormControl>
      </Tooltip>
    </LocalizationProvider>
  )
}

export function BillyMuiDateField(props: BillyDateFieldProps): JSX.Element {
  const { datePickerProps, onChange, value, error, disabled } = props
  const { classes } = useStyles()
  const inputRef = useRef<HTMLInputElement>(null)

  const tenantTimezone = useTenantTimeZone()

  const handleChange = useCallback(
    (datetime: IUnixtimeWithTimezone) => {
      onChange(datetime.unixtime)
    },
    [onChange]
  )

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Tooltip title={props.disabledExplanation || ''}>
        <FormControl className={classes.formControl} variant={'outlined'}>
          <DesktopDatePickerWithTimezoneAbsoluteLuxon
            inputRef={inputRef}
            unixtime={value ?? undefined}
            timezone={tenantTimezone}
            onChange={handleChange}
            disabled={!!props.disabledExplanation || disabled}
            datePickerProps={datePickerProps}
            error={!!error}
            helperText={error}
            label={props.datePickerProps?.label ?? ''}
          />
        </FormControl>
      </Tooltip>
    </LocalizationProvider>
  )
}

export default React.forwardRef(JotaiMuiDateField)
