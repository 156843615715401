import { isCurrentEnvironment } from '@/util/isEnv'
import { logger } from './useJotaiForm'

const JOTAI_RENDER_WARN_LIMIT = 10
const JOTAI_RENDER_ERROR_LIMIT = 50

export const notifyJotaiRenderCount = (count: number, fnName: string) => {
  const message =
    `${fnName} is rendering ${count} times. ` +
    'This is because the UseJotaiFormProps/onSubmit are changing on every render.\n' +
    'You should always useMemo when creating a new jotaiForm.\n' +
    'You should always useCallback when calling onSubmit.'

  if (count > JOTAI_RENDER_WARN_LIMIT && count < JOTAI_RENDER_ERROR_LIMIT) {
    logger.warn({ message })
  } else if (count > JOTAI_RENDER_ERROR_LIMIT) {
    if (isCurrentEnvironment('local')) {
      // throw new Error(message)
      logger.warn({ message })
    } else {
      logger.warn({ message })
    }
  }
}
