import ActionMenu from '@/components/menu/actionMenu'
import { OrderViewLineItemPopoverLink } from '@/pageComponents/orders/LineItemsEditTable/LineItemPopoverLink'
import { useConfiguredCustomColumns } from '@/pageComponents/orders/LineItemsEditTable/useLineItemCustomFieldColumns'
import { getLineItemViewCustomFieldVariables } from '@/pageComponents/orders/LineItemsViewTable/util'
import { CustomFieldsMenu } from '@/pageComponents/orders/customFieldsMenu'
import { useCanUploadUsage } from '@/util/roleUtils'
import { FilterList, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import {
  Badge,
  Box,
  Checkbox,
  Collapse,
  Dialog,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useRouter } from 'next/dist/client/router'
import { useMemo, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import DateRangeEnd from '../../../components/DateRangeEnd/DateRangeEnd'
import DateRangeStart from '../../../components/DateRangeStart/DateRangeStart'
import BillyLink from '../../../components/link/billyLink'
import CurrencyCell from '../../../components/table/cells/currencyCell'
import StatusChip from '../../../components/table/cells/statusChip'
import {
  AccountMetrics,
  ActionType,
  ChargeType,
  CompositeOrderType,
  Discount,
  Feature,
  LineItemFragment,
  Maybe,
  OrderLineItemDetail,
  OrderType,
  SubscriptionCharge,
  useGetCustomFieldsQuery,
} from '../../../generated/graphql'
import { WithViewPageOptions, instanceOfWithOrderLineItems, instanceOfWithSubscriptions } from '../../../util/charge'
import { billableCurrencyFormat, unitPriceFormatter } from '../../../util/currencyUtil'
import buildLogger from '../../../util/logger'
import { formatQuantity } from '../../../util/priceTier'
import { chargeHasNoQuantity } from '../LineItemsEditTable/orderChargeRow'
import ChargeDialogBodyWithQuery, { ChargeDialogBodyProps } from '../chargeDialogBody'
import PlanDialogBody from '../planDialogBody'
import { LineItemViewChargeCustomFieldCells } from './LineItemViewChargeCustomFieldCells'
import useDynamicFeatureFlag from '@/components/state/useDynamicFeatureFlag'

const logger = buildLogger('ChargesTable')

const EMPTY_PLACEHOLDER = '-'

export type FilterType = 'SHOW_ONLY_PURCHASED_ITEMS' | 'SHOW_MOST_PURCHASED_AT_TOP'

export enum LineItemState {
  New = 'NEW',
  Duplicate = 'DUPLICATE',
  Removed = 'REMOVED',
  Deprecated = 'DEPRECATED',
}

export type ChargesTableProps = WithViewPageOptions & {
  hideTotals?: boolean
  currency?: string
  orderType?: OrderType | CompositeOrderType
  metrics?: Partial<AccountMetrics>
  canEditCustomFields?: boolean
  onRefresh?: () => void
}

const useStyles = makeStyles()((theme, _params, _classes) => ({
  // todo: should probably move away from page component level styles
  statusChip: {
    marginLeft: theme.spacing(2),
  },
  table: {
    '& .MuiTableHead-root': {
      '& .MuiTableCell-root': {
        fontWeight: 600,
        backgroundColor: '#EDF0F3',
      },
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        transition: 'all 0.15s ease',
        '&:nth-of-type(even)': {
          backgroundColor: '#F9FAFB',
        },
        '&:last-child': {
          '& .MuiTableCell-root': {
            borderBottom: 'none',
          },
        },
        '& .MuiTableCell-root': {
          height: 54,
          fontWeight: 600,
          '& .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root': {
            color: '#595959',
            backgroundColor: '#EDECEB',
            borderBottom: `1px solid ${theme.customPalette.borderColor}`,
          },
          '& .MuiTableBody-root .MuiTableCell-root': {
            fontWeight: 550,
          },
        },
      },
    },
  },
  strikethrough: {
    textDecoration: 'line-through',
    color: 'GrayText',
    textAlign: 'right',
  },
  colShrink: {
    width: 0,
    whiteSpace: 'nowrap',
  },
}))

type PlanRowData = {
  key: string
  id: string
  sku: string
  name: string
  total: number
  charges?: ChargeRowData[]
}

type ChargeRowData = LineItemFragment['charge'] & {
  key: string
  action: ActionType
  noQuantity: boolean
  quantity: number
  startDate: number
  endDate: number
  listAmount: number
  listPrice: number
  listPriceBeforeOverride: number | undefined
  sellPrice: number
  discount?: Maybe<Maybe<Discount>[]>
  annualizedAmount: number
  discountAmount: number
  total: number
  rowId?: SubscriptionCharge['id'] | OrderLineItemDetail['id']
}

function PlanRow({
  charges,
  lineItems,
  subscriptionId,
  plan,
  hideTotals,
  planIndex,
  currency,
  orderType,
  currentSubscription,
  canEditCustomFields,
  onRefresh,
  filters,
}: {
  //TODO: move this to better props that re-uses ChargesTableProps
  plan: PlanRowData
  hideTotals?: boolean
  currency?: string
  orderType?: OrderType | CompositeOrderType
  planIndex: number
  canEditCustomFields?: boolean
  filters?: Array<FilterType>
  onRefresh?: () => void
} & WithViewPageOptions): JSX.Element {
  const [open, setOpen] = useState(true)
  const [isLineItemDialogOpen, setIsLineItemDialogOpen] = useState(false)
  const [isPlanDialogOpen, setIsPlanDialogOpen] = useState(false)

  const [chargeIndex, setChargeIndex] = useState(0)

  const handleDialogClose = (): void => {
    setIsLineItemDialogOpen(false)
  }

  const handlePlanDialogClose = (): void => {
    setIsPlanDialogOpen(false)
  }

  const { classes } = useStyles()

  const handleChargeClick = (index: number): void => {
    setChargeIndex(index)
    setIsPlanDialogOpen(false)
    setIsLineItemDialogOpen(true)
  }

  const handlePlanClick = (): void => {
    setIsLineItemDialogOpen(false)
    setIsPlanDialogOpen(true)
  }

  const isNewPlan = plan?.charges && plan.charges[0]?.action == ActionType.Add
  const isPlanRemoved = plan?.charges && !plan.charges?.find((plan) => plan.action !== ActionType.Remove)
  const isOrderAnnualizedAmountEnabled = useDynamicFeatureFlag(Feature.OrderAnnualizedAmount)

  const chargeDetailDialogProps = useMemo((): ChargeDialogBodyProps | undefined => {
    const viewPageOptions = { lineItems, subscriptionId, charges }
    if (plan.charges) {
      const selectedCharge = plan.charges[chargeIndex]
      if (instanceOfWithOrderLineItems(viewPageOptions)) {
        const lineItem = viewPageOptions.lineItems.find((lineItem) => lineItem?.id === selectedCharge.rowId)
        logger.trace({
          message: 'Init Order View Charge Detail Dialog',
          planIndex,
          lineItemId: selectedCharge.rowId,
          chargeIndex,
          charge: selectedCharge,
          lineItem,
          chargeDetail: lineItem?.chargeDetail,
        })
        return {
          chargeId: selectedCharge.id ?? '',
          planId: plan.id,
          currency,
          lineItem,
        }
      }

      if (instanceOfWithSubscriptions(viewPageOptions)) {
        return {
          chargeId: selectedCharge.id ?? '',
          planId: plan.id,
          currency,
        }
      }
    }
  }, [plan.charges, chargeIndex, lineItems, subscriptionId, charges, currency, planIndex, plan.id])

  const viewPageOptions = useMemo(() => ({ lineItems, charges, subscriptionId }), [lineItems, charges, subscriptionId])

  let chargeLineItems =
    plan.charges?.filter((chargeRow: ChargeRowData) =>
      filters && filters.includes('SHOW_ONLY_PURCHASED_ITEMS') ? chargeRow.quantity > 0 : chargeRow
    ) || []
  if (filters && filters.includes('SHOW_MOST_PURCHASED_AT_TOP')) {
    chargeLineItems = [...chargeLineItems]?.sort(
      (chargeLineItemA, chargeLineItemB) => chargeLineItemB.quantity - chargeLineItemA.quantity
    )
  }

  const mode = instanceOfWithOrderLineItems(viewPageOptions) ? 'order' : 'subscription'

  const [customFieldsResponse] = useGetCustomFieldsQuery({
    variables: getLineItemViewCustomFieldVariables({ mode, rowId: chargeLineItems[0]?.rowId }),
  })
  const showCustomFieldsMenu = (customFieldsResponse.data?.customFields?.length ?? 0) > 0

  const canUploadUsage = useCanUploadUsage()
  const isOrderTypeAmendmentOrRestructure =
    orderType === OrderType.Amendment || orderType === CompositeOrderType.CancelSingleSubscriptionAndRestructure

  const { customColumns } = useConfiguredCustomColumns({
    orderType,
    lineItem: lineItems?.[0],
  })

  return (
    <>
      {!!chargeDetailDialogProps && (
        <Dialog open={isLineItemDialogOpen} onClose={handleDialogClose} maxWidth={'md'} scroll={'paper'}>
          <ChargeDialogBodyWithQuery onClose={handleDialogClose} {...chargeDetailDialogProps} />
        </Dialog>
      )}
      <Dialog open={isPlanDialogOpen} onClose={handlePlanDialogClose} maxWidth={'md'} scroll={'paper'}>
        <PlanDialogBody onClose={handlePlanDialogClose} id={plan.id} />
      </Dialog>

      <TableRow aria-label="plan-row">
        <TableCell>
          <Typography component="span" variant="body2" style={{ marginRight: 8 }}>
            {planIndex + 1}.
          </Typography>
          <BillyLink linkProps={{ onClick: () => handlePlanClick() }} style={{ display: 'inline' }}>
            {plan.name}
          </BillyLink>
          {isOrderTypeAmendmentOrRestructure && (
            <span className={classes.statusChip}>
              {<StatusChip value={isNewPlan ? LineItemState.New : isPlanRemoved ? LineItemState.Removed : ''} />}
            </span>
          )}
        </TableCell>
        <TableCell />
        {!hideTotals ? (
          <TableCell align="right">{billableCurrencyFormat({ currency, value: plan.total })}</TableCell>
        ) : null}
        <TableCell width="5%">
          <IconButton aria-label="expand row" onClick={() => setOpen(!open)} size="large">
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table aria-label="line-item-collapse-table">
              <LineItemsViewChargeTableHeader
                hideTotals={hideTotals}
                hideActionColumn={!showCustomFieldsMenu}
                customColumns={customColumns}
              />
              <TableBody>
                {chargeLineItems?.map((chargeRow: ChargeRowData, index) => {
                  const lineItem = lineItems?.find((lineItem) => lineItem?.id === chargeRow?.rowId)
                  const resetToQuantity = currentSubscription?.charges.find(
                    (subscriptionCharge) => subscriptionCharge?.id === lineItem?.subscriptionChargeId
                  )?.quantity
                  const showResetQuantity =
                    !!orderType &&
                    [
                      OrderType.Amendment,
                      OrderType.Cancel,
                      CompositeOrderType.CancelSingleSubscriptionAndRestructure,
                    ].includes(orderType) &&
                    resetToQuantity !== null &&
                    resetToQuantity !== undefined &&
                    resetToQuantity !== chargeRow.quantity

                  return (
                    <TableRow key={chargeRow.key}>
                      <TableCell style={{ paddingLeft: 36 }}>
                        {subscriptionId && chargeRow.rowId ? (
                          <BillyLink
                            nextProps={{ href: `/subscriptions/${subscriptionId}/charges/${chargeRow.rowId}` }}
                          >
                            <div>{chargeRow.name}</div>
                          </BillyLink>
                        ) : (
                          <OrderViewLineItemPopoverLink
                            lineItemId={chargeRow.rowId ?? ''}
                            onClickLink={() => {
                              logger.debug({ chargeRow, index })
                              handleChargeClick(index)
                            }}
                          >
                            {chargeRow.name}
                          </OrderViewLineItemPopoverLink>
                        )}
                      </TableCell>
                      <TableCell>
                        <DateRangeStart datetime={chargeRow.startDate} />{' '}
                      </TableCell>
                      <TableCell>
                        <DateRangeEnd datetime={chargeRow.endDate} />
                      </TableCell>
                      {!subscriptionId && !!chargeRow.rowId && (
                        <LineItemViewChargeCustomFieldCells lineItem={lineItem} orderType={orderType} />
                      )}
                      <TableCell align="right">
                        {chargeRow.noQuantity && canUploadUsage ? (
                          chargeRow.type === ChargeType.Usage && instanceOfWithSubscriptions(viewPageOptions) ? (
                            <BillyLink
                              linkProps={{
                                href: `/settings/uploads`,
                              }}
                            >
                              <div>Load Usage</div>
                            </BillyLink>
                          ) : (
                            'N/A'
                          )
                        ) : (
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            {showResetQuantity && (
                              <Box
                                sx={{
                                  color: 'error.main',
                                  textDecoration: 'line-through',
                                  mr: '8px',
                                }}
                              >
                                ({formatQuantity(resetToQuantity)})
                              </Box>
                            )}
                            <div>{formatQuantity(chargeRow.quantity)}</div>
                          </Box>
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <Box>
                          {chargeRow.listPrice ? (
                            isOrderAnnualizedAmountEnabled ? (
                              <Typography variant="body2">
                                {unitPriceFormatter({ currency, value: chargeRow.listAmount })}
                              </Typography>
                            ) : (
                              unitPriceFormatter({ currency, value: chargeRow.listPrice })
                            )
                          ) : (
                            EMPTY_PLACEHOLDER
                          )}
                          {chargeRow.listPriceBeforeOverride && (
                            <Typography variant="body2" className={classes.strikethrough}>
                              {unitPriceFormatter({ currency, value: chargeRow.listPriceBeforeOverride })}
                            </Typography>
                          )}
                          {isOrderAnnualizedAmountEnabled && !!chargeRow.listPrice && (
                            <Typography variant="caption">{`(${unitPriceFormatter({
                              currency,
                              value: chargeRow.listPrice,
                            })})`}</Typography>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell align="right">
                        {unitPriceFormatter({
                          currency,
                          value: chargeRow.discountAmount
                            ? chargeRow.discountAmount
                            : (chargeRow.listPrice || 0) - (chargeRow.sellPrice || 0),
                        })}
                        {isOrderAnnualizedAmountEnabled && (
                          <Box>
                            <Typography variant="caption">{`(${unitPriceFormatter({
                              currency,
                              value: (chargeRow.listPrice || 0) - (chargeRow.sellPrice || 0),
                            })})`}</Typography>
                          </Box>
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {chargeRow.sellPrice
                          ? unitPriceFormatter({ currency, value: chargeRow.sellPrice })
                          : EMPTY_PLACEHOLDER}
                      </TableCell>
                      {!hideTotals ? (
                        <TableCell align="right">
                          <Box textAlign="right">
                            <Typography variant="body2">
                              {billableCurrencyFormat({ currency, value: chargeRow.total })}
                            </Typography>
                            {isOrderAnnualizedAmountEnabled && !!chargeRow.annualizedAmount && (
                              <Typography variant="caption">{`(${billableCurrencyFormat({
                                currency,
                                value: chargeRow.annualizedAmount,
                              })})`}</Typography>
                            )}
                          </Box>
                        </TableCell>
                      ) : null}
                      {showCustomFieldsMenu && (
                        <TableCell align="right" className={classes.colShrink} aria-label="line-item-action-menu">
                          <CustomFieldsMenu
                            {...getLineItemViewCustomFieldVariables({
                              mode,
                              rowId: chargeRow.rowId,
                            })}
                            canEditCustomFields={canEditCustomFields}
                            onRefresh={onRefresh}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

function LineItemsViewChargeTableHeader({
  hideTotals,
  hideActionColumn,
  customColumns,
}: {
  hideTotals?: boolean
  hideActionColumn?: boolean
  customColumns: string[]
}) {
  const hasCustomColumns = customColumns.length > 0
  const isOrderAnnualizedAmountEnabled = useDynamicFeatureFlag(Feature.OrderAnnualizedAmount)

  return (
    <TableHead>
      <TableRow>
        <TableCell width={hasCustomColumns ? '20%' : '30%'} style={{ paddingLeft: 36 }}>
          Charges
        </TableCell>
        <TableCell width="9%">Start At</TableCell>
        <TableCell width="9%">End At</TableCell>
        <>
          {hasCustomColumns ? (
            customColumns.map((columnLabel) => (
              <TableCell align="right" key={columnLabel} width={`${10 / customColumns.length}%`}>
                {columnLabel}
              </TableCell>
            ))
          ) : (
            <></>
          )}
        </>
        <TableCell width="8%" align="right">
          Quantity
        </TableCell>
        <TableCell width="10%" align="right">
          <Box textAlign="right">
            <Typography variant="body2">{isOrderAnnualizedAmountEnabled ? 'List Total' : 'List Unit Price'}</Typography>
            {isOrderAnnualizedAmountEnabled && <Typography variant="caption">(List Unit Price)</Typography>}
          </Box>
        </TableCell>
        <TableCell width="12%" align="right">
          <Box textAlign="right">
            <Typography variant="body2">{isOrderAnnualizedAmountEnabled ? 'Discount Total' : 'Discount'}</Typography>
            {isOrderAnnualizedAmountEnabled && <Typography variant="caption">(Discount Per Unit)</Typography>}
          </Box>
        </TableCell>
        <TableCell width="10%" align="right">
          {isOrderAnnualizedAmountEnabled ? 'Sell Price' : 'Sell Unit Price'}
        </TableCell>
        {!hideTotals ? (
          <TableCell width="12%" align="right">
            <Box textAlign="right">
              <Typography variant="body2">Subtotal</Typography>
              {isOrderAnnualizedAmountEnabled && <Typography variant="caption">(Annual Subtotal)</Typography>}
            </Box>
          </TableCell>
        ) : null}
        {!hideActionColumn && <TableCell />}
      </TableRow>
    </TableHead>
  )
}

// legacy code hasn't been refactored yet
function extractData(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item: Maybe<SubscriptionCharge> | OrderLineItemDetail | any,
  data: PlanRowData[]
): void {
  // 1. find the plan by id
  const plan = data.find((plan: PlanRowData) => plan.id === item?.plan?.id)
  const chargeId = item?.charge?.id
  const startDate = item?.startDate || item?.effectiveDate
  const endDate = item?.endDate
  // 2. transform a charge line item into a ChargeRowData data object to be displayed
  const charge: ChargeRowData = {
    ...item?.charge,
    action: item?.action,
    amount: item?.amount,
    annualizedAmount: item?.annualizedAmount,
    discount: item?.discounts,
    discountAmount: item?.discountAmount,
    endDate: endDate,
    key: chargeId,
    listPriceBeforeOverride: item?.listUnitPriceBeforeOverride ?? undefined,
    listPrice: item?.listUnitPrice ?? item?.charge?.amount,
    listAmount: item?.listAmount ?? undefined,
    noQuantity: item?.charge ? chargeHasNoQuantity(item.charge) : true,
    quantity: item?.quantity,
    sellPrice: item?.sellUnitPrice,
    startDate: startDate,
    total: item?.amount,
    unitOfMeasure: item?.chargeDetail?.unitOfMeasure ?? undefined,
    rowId: item?.id as SubscriptionCharge['id'] | OrderLineItemDetail['id'],
  }

  // 3. either insert the charge or create the plan with the charge in it.
  if (plan && plan.charges) {
    plan.charges?.push(charge)
  } else {
    item?.plan?.id &&
      data.push({
        key: item.plan.id,
        id: item.plan.id,
        sku: item.plan.productId,
        name: item.plan.name,
        total: 0,
        charges: [charge],
      })
  }
}

function extractCancelAndRestructureData(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item: Maybe<SubscriptionCharge> | OrderLineItemDetail | any,
  data: PlanRowData[]
): void {
  const chargeId = item?.charge?.id
  const startDate = item?.startDate || item?.effectiveDate
  const endDate = item?.endDate
  // 2. transform a charge line item into a ChargeRowData data object to be displayed
  const charge: ChargeRowData = {
    ...item?.charge,
    action: item?.action,
    amount: item?.amount,
    discount: item?.discounts,
    endDate: endDate,
    key: chargeId,
    listPriceBeforeOverride: item?.listUnitPriceBeforeOverride ?? undefined,
    listPrice: item?.listUnitPrice ?? item?.charge?.amount,
    listAmount: item?.listAmount ?? undefined,
    noQuantity: item?.charge ? chargeHasNoQuantity(item.charge) : true,
    quantity: item?.quantity,
    sellPrice: item?.sellUnitPrice,
    startDate: startDate,
    total: item?.amount,
    unitOfMeasure: item?.chargeDetail?.unitOfMeasure ?? undefined,
    rowId: item?.id as SubscriptionCharge['id'] | OrderLineItemDetail['id'],
  }

  const removedPlan = data.find(
    (plan: PlanRowData) =>
      plan.id === item?.plan?.id && plan.charges?.[0].action === ActionType.Remove && item.action === ActionType.Remove
  )
  const newPlan = data.find(
    (plan: PlanRowData) =>
      plan.id === item?.plan?.id && plan.charges?.[0].action === ActionType.Add && item.action === ActionType.Add
  )
  const restructuredPlan = data.find(
    (plan: PlanRowData) =>
      plan.id === item?.plan?.id &&
      plan.charges?.[0].action === ActionType.Restructure &&
      item.action === ActionType.Restructure
  )

  if (
    removedPlan?.charges?.[removedPlan.charges.length - 1].action === ActionType.Remove &&
    charge.action === ActionType.Remove
  ) {
    removedPlan.charges?.push(charge)
  } else if (
    newPlan?.charges?.[newPlan.charges.length - 1].action === ActionType.Add &&
    charge.action === ActionType.Add
  ) {
    newPlan.charges?.push(charge)

    // This is for clubbing the charges that belong to the same plan id.
  } else if (
    restructuredPlan &&
    restructuredPlan.charges &&
    restructuredPlan.charges[0].action === ActionType.Restructure
  ) {
    restructuredPlan.charges?.push(charge)
  } else {
    item?.plan?.id &&
      data.push({
        key: item.plan.id,
        id: item.plan.id,
        sku: item.plan.productId,
        name: item.plan.name,
        total: 0,
        charges: [charge],
      })
  }
  return
}

export default function LineItemsViewTable({
  charges,
  lineItems,
  currency,
  subscriptionId,
  hideTotals,
  orderType,
  metrics,
  currentSubscription,
  canEditCustomFields,
  onRefresh,
}: ChargesTableProps): JSX.Element {
  const { classes } = useStyles()

  const viewPageOptions = { lineItems, charges, subscriptionId }
  const planData = Array<PlanRowData>()
  const router = useRouter()
  const isSubscriptionPage = router.pathname.includes('subscriptions')
  const [filters, setFilters] = useState<Array<FilterType>>([])

  // remap data to be grouped by Plan
  if (instanceOfWithOrderLineItems(viewPageOptions)) {
    viewPageOptions.lineItems.forEach((item) => {
      if (orderType === CompositeOrderType.CancelSingleSubscriptionAndRestructure) {
        extractCancelAndRestructureData(item, planData)
      } else {
        extractData(item, planData)
      }
    })
  } else if (instanceOfWithSubscriptions(viewPageOptions)) {
    viewPageOptions.charges.forEach((item) => {
      extractData(item, planData)
    })
  }
  // calculate totals for each plan
  planData.forEach((plan) => {
    const planTotal = plan?.charges?.reduce((sum, item) => sum + item.total, 0)
    if (planTotal) plan.total = planTotal
  })
  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell>Plan Name</TableCell>
          <TableCell />
          {!hideTotals ? <TableCell align="right">Total Plans Cost</TableCell> : null}
          {isSubscriptionPage ? (
            <TableCell align="right">
              <FilterMenu filters={filters} onChangeFilter={setFilters} />{' '}
            </TableCell>
          ) : (
            <TableCell />
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {planData.map((plan, index) => {
          if (instanceOfWithSubscriptions(viewPageOptions)) {
            return (
              <PlanRow
                charges={viewPageOptions.charges}
                key={plan.key}
                plan={plan}
                hideTotals={hideTotals}
                planIndex={index}
                currency={currency}
                orderType={orderType}
                subscriptionId={viewPageOptions.subscriptionId}
                filters={filters}
                onRefresh={onRefresh}
              />
            )
          }
          if (instanceOfWithOrderLineItems(viewPageOptions)) {
            return (
              <PlanRow
                currentSubscription={currentSubscription}
                lineItems={viewPageOptions.lineItems}
                key={plan.key}
                plan={plan}
                hideTotals={hideTotals}
                planIndex={index}
                currency={currency}
                orderType={orderType}
                canEditCustomFields={canEditCustomFields}
                onRefresh={onRefresh}
              />
            )
          }
        })}
        {!hideTotals ? (
          <>
            {metrics?.nonRecurringTotal !== undefined && (
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }} colSpan={3} align="right">
                  Non-Recurring Total:
                </TableCell>
                <TableCell colSpan={2} align="right">
                  <CurrencyCell currency={currency} value={metrics?.nonRecurringTotal} />
                </TableCell>
              </TableRow>
            )}
            {metrics?.recurringTotal !== undefined && (
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }} colSpan={3} align="right">
                  Recurring Total:
                </TableCell>
                <TableCell colSpan={2} align="right">
                  <CurrencyCell currency={currency} value={metrics?.recurringTotal} />
                </TableCell>
              </TableRow>
            )}
            {metrics?.tcv !== undefined && (
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }} colSpan={3} align="right">
                  Total:
                </TableCell>
                <TableCell colSpan={2} align="right">
                  <CurrencyCell currency={currency} value={metrics?.tcv} />
                </TableCell>
              </TableRow>
            )}
          </>
        ) : null}
      </TableBody>
    </Table>
  )
}

const FilterMenu = ({
  filters,
  onChangeFilter,
}: {
  filters: Array<FilterType>
  onChangeFilter: (filter: Array<FilterType>) => void
}) => {
  const hideNonPurchasedItems = filters.indexOf('SHOW_ONLY_PURCHASED_ITEMS') > -1
  const showPurchasedItemsAtTop = filters.indexOf('SHOW_MOST_PURCHASED_AT_TOP') > -1

  const changeFilter = (filterType: FilterType) => {
    if (filters.indexOf(filterType) > -1) {
      const updatedArray = [...filters]
      updatedArray.splice(filters.indexOf(filterType), 1)
      return onChangeFilter(updatedArray)
    }
    return onChangeFilter([...filters, filterType])
  }

  return (
    <ActionMenu
      menuItems={[
        {
          children: (
            <>
              <Checkbox sx={{ paddingLeft: 0, paddingTop: 0, paddingBottom: 0 }} checked={hideNonPurchasedItems} />
              Show only Purchased Items
            </>
          ),
          onClick: () => changeFilter('SHOW_ONLY_PURCHASED_ITEMS'),
        },
        {
          children: (
            <>
              <Checkbox sx={{ paddingLeft: 0, paddingTop: 0, paddingBottom: 0 }} checked={showPurchasedItemsAtTop} />
              Show Most Purchased Items at Top
            </>
          ),
          onClick: () => changeFilter('SHOW_MOST_PURCHASED_AT_TOP'),
        },
      ]}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', cursor: 'pointer' }}>
        <Badge color="primary" badgeContent={filters.length} invisible={filters.length === 0}>
          <FilterList color="primary" />
        </Badge>
        <Typography color="primary">FILTERS</Typography>
      </Box>
    </ActionMenu>
  )
}
