import { FC } from 'react'
import { TabList, TabListProps } from '@mui/lab'
import { Tab, Tooltip } from '@mui/material'
import buildLogger from '../../util/logger'

const logger = buildLogger('TabBar')

export interface TabBarProps {
  tabs: readonly TabDetail[]
  onTabChange?: TabListProps['onChange']
}
export type TabDetail = {
  readonly disableExplanation?: string
  readonly disabled?: boolean
  readonly hide?: boolean
  readonly label: string
  /**
   * You can provide your own value. Otherwise, we fallback to the child position index.
   *  */
  readonly value?: string
}

export function isTabEmpty(tabs: readonly TabDetail[]): boolean {
  return !!tabs?.filter((tab) => !tab.hide).length
}
export const TabBar: FC<React.PropsWithChildren<TabBarProps>> = ({ tabs, onTabChange }) => {
  return (
    <TabList onChange={onTabChange}>
      {tabs
        .filter((tab) => !tab.hide)
        .map((tab, index) => {
          const isDisabled = !!tab.disableExplanation || !!tab.disabled
          return (
            <Tab
              key={`mui-tab-${index}`}
              style={isDisabled ? { pointerEvents: 'auto' } : undefined}
              label={
                <Tooltip title={isDisabled ? tab.disableExplanation ?? '' : ''}>
                  <span role="navigation" aria-label={tab.label}>
                    {tab.label}
                  </span>
                </Tooltip>
              }
              disabled={isDisabled}
              value={tab.value}
            />
          )
        })}
    </TabList>
  )
}
