import { Grid, InputAdornment } from '@mui/material'
import { Box } from '@mui/system'
import Big from 'big.js'
import { useCallback } from 'react'
import JotaiUrqlErrors from '../../../components/error/jotaiUrqlErrors'
import JotaiMuiNumberField from '../../../components/input/JotaiMuiNumberField'
import JotaiReadValue from '../../../components/state/jotaiReadValue'
import { ActionType, LineItemFragment } from '../../../generated/graphql'
import { getLineItemCondition } from '../../../util/charge'
import { currencySymbol, getOverrideRatio, unitPriceFormatter } from '../../../util/currencyUtil'
import buildLogger from '../../../util/logger'
import { LineItemPriceOverrideDialogProps, LineItemPriceOverrideDialogState } from './LineItemPriceOverrideDialog'

const logger = buildLogger('LineItemPriceOverrideDialogContent')

export const LineItemPriceOverrideDialogContent = <F extends LineItemPriceOverrideDialogState>({
  jotaiForm,
  orderType,
  lineItemIndex,
}: LineItemPriceOverrideDialogProps<F>): JSX.Element => {
  const [isPriceOverrideDisabled] = jotaiForm.useSelect(
    useCallback(
      (form) => {
        const lineItem = form?.orderDetail?.lineItems?.[lineItemIndex]
        const { allowDiscount, allowGoalSeeking } = getLineItemCondition(lineItem)
        return [!allowDiscount, !allowGoalSeeking]
      },
      [lineItemIndex]
    )
  )

  const baseUnitPrice = jotaiForm.useSelect(
    useCallback(
      (form) => {
        const lineItem = form?.orderDetail?.lineItems?.[lineItemIndex]
        return lineItem?.listUnitPriceBeforeOverride ?? lineItem?.listUnitPrice ?? 0.0
      },
      [lineItemIndex]
    )
  )

  const disabledExplanation = 'Add a charge quantity to set a price override'

  return (
    <>
      <JotaiUrqlErrors jotaiForm={jotaiForm} />
      <JotaiReadValue
        atomSelector={useCallback(
          (form: F) => {
            const lineItem = form.orderDetail.lineItems[lineItemIndex]
            return { action: lineItem?.action || ActionType.Add, charge: lineItem?.charge }
          },
          [lineItemIndex]
        )}
        form={jotaiForm}
        render={useCallback(
          ({ action }: { action: ActionType; charge: LineItemFragment['charge'] }) => (
            <>
              {(orderType === 'NEW' ||
                orderType === 'RESTRUCTURE' ||
                (orderType === 'AMENDMENT' && action === ActionType.Add)) && (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={6} role="gridcell">
                      <Box display="flex">
                        <Box>
                          <JotaiMuiNumberField
                            atomSelector={(form) => {
                              const listPriceOverrideRatio =
                                form.orderDetail.lineItems[lineItemIndex]?.listPriceOverrideRatio
                              if (!listPriceOverrideRatio && listPriceOverrideRatio !== 0) {
                                return 1.0
                              }
                              return listPriceOverrideRatio
                            }}
                            atomUpdater={(value, draft) => {
                              const lineItem = draft.orderDetail.lineItems[lineItemIndex]
                              if ((value || value === 0.0) && value < +1000.0 && value >= +0.0) {
                                lineItem.listPriceOverrideRatio = +value.toString()
                                lineItem.arrOverride = undefined
                              }
                              if (value === 1.0) {
                                lineItem.listPriceOverrideRatio = undefined
                                lineItem.arrOverride = undefined
                              }
                            }}
                            errorPath={`orderDetail.lineItems[${lineItemIndex}].listPriceOverrideRatio`}
                            form={jotaiForm}
                            percent={true}
                            unrestrictedPercentRange={true}
                            maxDecimalPlaces={8}
                            textFieldProps={{
                              'aria-label': 'Price Override Ratio',
                              placeholder: '0',
                              size: 'small',
                              label: 'Override',
                              disabled: isPriceOverrideDisabled,
                              'aria-disabled': isPriceOverrideDisabled,
                              inputProps: {
                                sx: { height: '2.5rem', textAlign: 'right' },
                              },
                              helperText: 'Define an increase or decrease in the list price',
                            }}
                            disabledExplanation={isPriceOverrideDisabled && disabledExplanation}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6} style={{ marginBottom: '1rem' }} role="gridcell">
                      <Grid item display="flex">
                        <JotaiReadValue
                          atomSelector={(form) => {
                            const currency = form.orderDetail?.currency ?? undefined
                            return {
                              currency,
                            }
                          }}
                          form={jotaiForm}
                          render={({ currency }: { currency?: string }) => (
                            <Box
                              sx={{
                                flexFlow: 'row',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <Box>
                                <JotaiMuiNumberField
                                  atomSelector={(form) => {
                                    const lineItem = form.orderDetail.lineItems?.[lineItemIndex]
                                    const overrideRatio = lineItem.listPriceOverrideRatio ?? 1.0
                                    const overridePrice = Big(baseUnitPrice).times(Big(overrideRatio))
                                    return +overridePrice.toFixed(5, 1)
                                  }}
                                  atomUpdater={(overrideListUnitPrice, draft) => {
                                    const lineItem = draft.orderDetail.lineItems?.[lineItemIndex]
                                    if (!overrideListUnitPrice && overrideListUnitPrice !== 0.0) {
                                      lineItem.listPriceOverrideRatio = undefined
                                      lineItem.arrOverride = undefined
                                      return
                                    }
                                    const ratio = getOverrideRatio(overrideListUnitPrice, baseUnitPrice)
                                    if ((ratio || ratio === 0.0) && +ratio < 1000.0 && +ratio >= 0.0) {
                                      lineItem.listPriceOverrideRatio = +ratio
                                      lineItem.arrOverride = undefined
                                    }
                                  }}
                                  errorPath={`orderDetail.lineItems[${lineItemIndex}].listPriceOverrideRatio`}
                                  form={jotaiForm}
                                  textFieldProps={{
                                    'aria-label': 'Price Override',
                                    placeholder: 'Price Override',
                                    size: 'small',
                                    label: 'Price Override',
                                    disabled: isPriceOverrideDisabled,
                                    'aria-disabled': isPriceOverrideDisabled,
                                    inputProps: {
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          {currencySymbol(currency ?? 'USD')}
                                        </InputAdornment>
                                      ),
                                      sx: { height: '2.5rem', textAlign: 'right' },
                                    },
                                    helperText: `List price is ${unitPriceFormatter({
                                      currency,
                                      value: baseUnitPrice,
                                    })}`,
                                  }}
                                  disabledExplanation={isPriceOverrideDisabled && disabledExplanation}
                                />
                              </Box>
                            </Box>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          ),
          [jotaiForm, lineItemIndex, orderType, isPriceOverrideDisabled, disabledExplanation, baseUnitPrice]
        )}
      />
    </>
  )
}
