import { useCustomizationProps, WithCustomizationLocator } from '@/components/state/context/customizationContext'
import { useJotaiFormContext } from '@/components/state/jotaiFormProvider'
import { Grid, Typography } from '@mui/material'
import JotaiMuiCheckbox from '../../../../../components/input/JotaiMuiCheckbox'
import { NewOrderFormData } from '../../NewOrderPage'
import { useOrderPageFormStyles } from '../../hooks/useOrderPageFormStyles'

export function EditOrderSubscriptionDetailsForm({ parentLocator }: WithCustomizationLocator) {
  const { classes } = useOrderPageFormStyles()
  const jotaiForm = useJotaiFormContext<NewOrderFormData>()

  const customizationProps = useCustomizationProps({ displayName: 'autoRenew', parentLocator })
  const hidden = customizationProps?.hidden
  const defaultChecked = customizationProps?.defaultChecked
  return (
    <section aria-label="Subscription Details" hidden={hidden}>
      <Typography component="h6" className={classes.rowLabel}>
        Subscription Details
      </Typography>
      <Grid container direction="row" spacing={2} style={{ marginBottom: 24 }}>
        <Grid item container direction="row">
          <Grid item lg={4} xl={2} className={classes.fieldGridItem}>
            <JotaiMuiCheckbox
              atomSelector={(form) => form.orderDetail.autoRenew}
              form={jotaiForm}
              checkboxProps={{
                defaultChecked,
              }}
              atomUpdater={(value, draft) => (draft.orderDetail.autoRenew = value)}
              fieldLabel="Auto-Renew"
            />
          </Grid>
        </Grid>
      </Grid>
    </section>
  )
}
