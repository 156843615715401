import { CardHeader } from '@mui/material'
import { useCallback } from 'react'
import { Row } from 'react-table'
import buildLogger from '../../util/logger'
import BillyCard from '../card/billyCard'
import BaseTable, { BaseTableProps, BillyColumn } from './baseTable'
import TableNewItemAction, { NewItemProps } from './tableNewItemAction'

const logger = buildLogger('TableInCardWithButton')

type Props<DataType extends object> = Readonly<
  Pick<BaseTableProps<DataType>, 'rowActionType' | 'CustomRowAction'> & {
    title?: string
    columns: ReadonlyArray<BillyColumn<DataType>>
    data?: Array<DataType>
    newItem?: NewItemProps
    renderEmpty?: () => JSX.Element
    rowHandler?: {
      onRowClick: (id: string) => void
      idField: string
    }
    getLinkTarget?: (row: Row<DataType>) => string
    hidden?: boolean
    pageSize?: number
    isLoading?: boolean
  }
>

function TableInCardWithButton<DataType extends object>({
  title,
  columns,
  data,
  newItem,
  renderEmpty,
  rowHandler,
  getLinkTarget,
  rowActionType,
  CustomRowAction,
  hidden,
  pageSize,
  isLoading,
}: Props<DataType>): JSX.Element {
  const handleRowClick = useCallback(
    (row) => rowHandler && rowHandler.onRowClick(row.original[rowHandler.idField]),
    [rowHandler]
  )

  const newAction = newItem ? <TableNewItemAction {...newItem} /> : undefined

  return (
    <BillyCard hidden={hidden} role="presentation" aria-label={title}>
      {(newItem || title) && <CardHeader title={title} action={newAction} />}
      <BaseTable
        columns={columns}
        data={data}
        renderEmpty={renderEmpty}
        onRowClick={rowHandler?.onRowClick ? handleRowClick : undefined}
        getLinkTarget={getLinkTarget}
        rowId={rowHandler?.idField || 'id'}
        pageSize={pageSize}
        rowActionType={rowActionType}
        CustomRowAction={CustomRowAction}
        isLoading={isLoading}
      />
    </BillyCard>
  )
}

export default TableInCardWithButton
