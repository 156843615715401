import { LineItemCellLoadingIndicator } from '@/pageComponents/orders/LineItemsEditTable/Cells/LineItemCellLoadingIndicator'
import { reduceLineItem } from '@/pageComponents/orders/LineItemsEditTable/reduceLineItem'
import {
  useDryRunActions,
  useShouldRecalculateTotals,
} from '@/pageComponents/orders/EditOrderPage/context/DryRunActionsContext'
import { InputAdornment } from '@mui/material'
import { useCallback } from 'react'
import JotaiMuiNumberField from '../../../../components/input/JotaiMuiNumberField'
import { useJotaiFormContext } from '../../../../components/state/jotaiFormProvider'
import { currencySymbol } from '../../../../util/currencyUtil'
import { NewOrderFormData } from '../../EditOrderPage/NewOrderPage'
import { CommonOrderFormPageState } from '../../EditOrderPage/CommonOrderFormPageState'
import { useLineItemEditActions } from '../LineItemEditContextProvider'
import { OrderItemRowProps, useOrderChargeRowStyles } from '../orderChargeRow'
import { ListPriceCell } from './ListPriceCell'

export function LineItemListPriceCell<F extends CommonOrderFormPageState = NewOrderFormData>({
  lineItemIndex,
  orderType,
  currency,
}: OrderItemRowProps) {
  const { classes } = useOrderChargeRowStyles()
  const jotaiForm = useJotaiFormContext<F>()
  const { onClickPriceOverride, onEditRateAttribute } = useLineItemEditActions()

  const shouldRecalculateTotals = useShouldRecalculateTotals()
  const { isCustomListPriceEditEnabled, ...listPriceProps } = jotaiForm.useSelect(
    useCallback(
      (form) => {
        const reduced = reduceLineItem({
          orderDetail: form.orderDetail,
          lineItemIndex,
          orderType,
          currency,
        })
        return {
          currency,
          isCustomListPriceEditEnabled: reduced.isCustomListPriceEditEnabled,
          isListPriceOverrideEnabled: reduced.isListPriceOverrideEnabled,
          lineItem: reduced.lineItem,
          listPrice: reduced.listPrice,
        }
      },
      [lineItemIndex, orderType, currency]
    )
  )

  const { clearDryRun, queueDryRun } = useDryRunActions()

  return isCustomListPriceEditEnabled ? (
    <JotaiMuiNumberField
      atomSelector={(form) => form.orderDetail.lineItems[lineItemIndex]?.listUnitPrice}
      atomUpdater={(value, draft) => {
        const lineItem = draft.orderDetail.lineItems[lineItemIndex]
        if (lineItem) {
          lineItem.listUnitPrice = value
          lineItem.arrOverride = undefined
        }
      }}
      errorPath={`orderDetail.lineItems[${lineItemIndex}].listUnitPrice`}
      form={jotaiForm}
      textFieldProps={{
        className: classes.fieldShrink,
        label: '',
        size: 'small',
        disabled: shouldRecalculateTotals,
        inputProps: {
          onFocus: clearDryRun,
          onBlur: queueDryRun,
          startAdornment: <InputAdornment position="start">{currencySymbol(currency || 'USD')}</InputAdornment>,
          style: { textAlign: 'right' },
        },
      }}
    />
  ) : (
    <div className={classes.total}>
      {shouldRecalculateTotals ? (
        <LineItemCellLoadingIndicator />
      ) : (
        <ListPriceCell
          {...{
            ...listPriceProps,
            lineItemIndex,
            onClickPriceOverride,
            onEditRateAttribute,
          }}
        />
      )}
    </div>
  )
}
