import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { FormEvent, useCallback, useState } from 'react'
import { useErrorHandler } from '../../../components/ErrorHandler/ErrorHandler'
import ActionButton from '../../../components/button/actionButton'
import { WithModalParams, useModal, useModalsContext } from '../../../components/state/context/modalsContext'
import { isAccountAddressComplete, mapUpsertContactVariableUsingAccountAddress } from '../../../util/address'
import buildLogger from '../../../util/logger'
import { useHandleUpsertAccountContact } from '../../accounts/useHandleUpsertAccountContact'
import { UseMarkAsExecutedProps } from './useMarkAsExecuted'
const logger = buildLogger('UseAccountAddressDialog')

type DialogProps = WithModalParams
const formID = 'UseAccountAddressDialog'
export function UseAccountAddressDialog({ open, onClose, onSubmit }: DialogProps): JSX.Element {
  const errorHandler = useErrorHandler()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      async function doAsync() {
        setIsSubmitting(true)
        await onSubmit?.()
        setIsSubmitting(false)
        onClose?.()
      }
      doAsync().catch((error) => {
        setIsSubmitting(false)
        errorHandler(error)
      })
    },
    [onSubmit, onClose, errorHandler]
  )
  return (
    <>
      <Dialog open={!!open} onClose={onClose} maxWidth={'sm'} scroll={'paper'} fullWidth>
        <DialogTitle>Use Account Address?</DialogTitle>
        <DialogContent dividers>
          <form id={formID} onSubmit={handleSubmit}>
            <Typography>Billing or shipping contacts do not have an address specified.</Typography>
          </form>
        </DialogContent>
        <DialogActions>
          <ActionButton
            key={'Cancel'}
            buttonData={{
              label: 'Cancel',
              onClick: onClose,
              color: 'inherit',
              buttonProps: { variant: 'outlined' },
            }}
          />
          <ActionButton
            key={'Use Account Address'}
            buttonData={{
              label: 'Use Account Address',
              loading: isSubmitting,
              buttonProps: {
                form: formID,
                type: 'submit',
              },
            }}
          />
        </DialogActions>
      </Dialog>
    </>
  )
}

type UseUseAccountAddressDialogProps = Pick<
  UseMarkAsExecutedProps,
  'accountDetail' | 'shippingContact' | 'billingContact' | 'onExecute'
>

export function useUseAccountAddressDialog({
  accountDetail,
  onExecute,
  billingContact,
  shippingContact,
}: UseUseAccountAddressDialogProps) {
  const [, , toggleModal] = useModalsContext()

  const handleUpsertContact = useHandleUpsertAccountContact({})

  const handleSubmit = useCallback(async () => {
    let shouldExecute = true
    if (accountDetail && accountDetail.address) {
      if (shippingContact && !isAccountAddressComplete(shippingContact.address)) {
        shouldExecute = (await handleUpsertContact(
          mapUpsertContactVariableUsingAccountAddress(shippingContact, accountDetail)
        ))
          ? shouldExecute
          : false
      }
      if (
        billingContact &&
        !isAccountAddressComplete(billingContact.address) &&
        billingContact.id !== shippingContact?.id
      ) {
        shouldExecute = (await handleUpsertContact(
          mapUpsertContactVariableUsingAccountAddress(billingContact, accountDetail)
        ))
          ? shouldExecute
          : false
      }
    }

    //TODO: do not throw error when it is avalara error
    if (shouldExecute) {
      onExecute()
    }
  }, [handleUpsertContact, onExecute, accountDetail, shippingContact, billingContact])

  useModal<DialogProps>({
    component: UseAccountAddressDialog,
    schema: {
      key: formID,
      modalProps: {
        onSubmit: handleSubmit,
      },
    },
  })
  return useCallback(() => {
    toggleModal(formID)
  }, [toggleModal])
}
