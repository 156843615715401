import { Stack, Typography } from '@mui/material'
import BillyLink from '../../../../components/link/billyLink'
import useDynamicFeatureFlag from '@/components/state/useDynamicFeatureFlag'
import { Feature } from '@/generated/graphql'

export function DiscountEditCell({
  isDiscountEnabled,
  onClickDiscount,
  lineItemIndex,
  discount,
  unitDiscount,
}: {
  isDiscountEnabled: boolean
  onClickDiscount: ((orderLineIndex: number) => void) | undefined
  lineItemIndex: number
  discount: string
  unitDiscount: string
}) {
  const isOrderAnnualizedAmountEnabled = useDynamicFeatureFlag(Feature.OrderAnnualizedAmount)

  if (isOrderAnnualizedAmountEnabled) {
    return isDiscountEnabled ? (
      <Stack>
        <Typography variant="body2" align="right">
          {discount}
        </Typography>
        <BillyLink
          linkProps={{
            onClick: () => {
              onClickDiscount?.(lineItemIndex)
            },
            'aria-label': 'edit-discount-button',
          }}
        >
          {`(${unitDiscount})`}
        </BillyLink>
      </Stack>
    ) : (
      <Stack>
        <Typography variant="body2">{discount}</Typography>
        <Typography variant="caption">{`(${unitDiscount})`}</Typography>
      </Stack>
    )
  }

  return isDiscountEnabled ? (
    <BillyLink
      linkProps={{
        onClick: () => {
          onClickDiscount?.(lineItemIndex)
        },
        style: { marginLeft: 20 },
        'aria-label': 'edit-discount-button',
      }}
    >
      {discount}
    </BillyLink>
  ) : (
    <>{discount}</>
  )
}
