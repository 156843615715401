import { EditOrderTimelineCard } from '@/pageComponents/orders/EditOrderPage/cards/EditOrderTimelineCard/EditOrderTimelineCard'
import { IncludedTermsCard } from '../IncludedTerms/IncludedTermsCard'
import { BillyOrderDetailsCard } from './cards/BillyOrderDetailsCard/BillyOrderDetailsCard'
import DocumentMasterTemplateCard from '../documentMasterTemplateCard'
import OrderDiscounts from '../orderDiscounts'
import { OrderEditBillingCard } from './cards/OrderEditBillingCard/OrderEditBillingCard'
import { OrderEditItemsCard } from './cards/OrderEditItemsCard'
import { BillyStepperForm, Step, StepperFormChildren } from '@/components/BillyStepperForm/BillyStepperForm'
import { PageContainer } from '@/components/PageContainer/PageContainer'
import { NewOrderStepperPageNavLayout } from './NewOrderStepperPageNavLayout'
import { NewOrderPageFormWithoutMemo } from './NewOrderPageForm'

const NewOrderSteps: Step[] = [
  {
    id: 'Order Details',
    label: 'Order Details',
    children: <BillyOrderDetailsCard />,
  },
  {
    id: 'Timeline Details',
    label: 'Timeline Details',
    children: <EditOrderTimelineCard />,
  },
  {
    id: 'Order Items',
    label: 'Order Items',
    children: <OrderEditItemsCard />,
  },
  {
    id: 'Discount',
    label: 'Order Discount',
    children: <OrderDiscounts />,
    optional: true,
  },
  {
    id: 'Billing',
    label: 'Billing',
    children: <OrderEditBillingCard />,
    optional: true,
  },
  {
    id: 'Document Template',
    label: 'Document Template',
    children: <DocumentMasterTemplateCard />,
    optional: true,
  },
  {
    id: 'Included Terms',
    label: 'Included Terms',
    children: <IncludedTermsCard />,
    optional: true,
  },

  {
    id: 'Preview',
    label: 'Preview',
    children: <NewOrderPageFormWithoutMemo />,
  },
]

export function NewOrderStepperContent() {
  return (
    <BillyStepperForm.Form steps={NewOrderSteps}>
      {(stepperChildrenProps: StepperFormChildren) => {
        return (
          <PageContainer
            slot={{
              Layout: NewOrderStepperPageNavLayout,
            }}
            slotProps={{
              Layout: stepperChildrenProps,
            }}
          >
            <BillyStepperForm.Content {...stepperChildrenProps} />
          </PageContainer>
        )
      }}
    </BillyStepperForm.Form>
  )
}
