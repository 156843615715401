import { useJotaiFormContext } from '@/components/state/jotaiFormProvider'
import { CardContent, CardHeader, Checkbox, Chip, Grid, MenuItem } from '@mui/material'
import { Box } from '@mui/system'
import { useCallback, useMemo } from 'react'
import { deepMutable } from '../../../components/SchemaForm/DeepMutable'
import ActionButton from '../../../components/button/actionButton'
import BillyCard from '../../../components/card/billyCard'
import JotaiMuiAutocomplete from '../../../components/input/JotaiMuiAutocomplete'
import JotaiMuiField from '../../../components/input/JotaiMuiField'
import { JotaiForm } from '../../../components/state/useJotaiForm'
import { DocumentTemplateStatus } from '../../../generated/graphql'
import { notEmpty, onlyUnique } from '../../../util/array'
import buildLogger from '../../../util/logger'
import { CommonOrderFormPageState } from '../EditOrderPage/CommonOrderFormPageState'
import { CustomContentDialogState, useDocumentCustomContentDialog } from './DocumentCustomContentDialog'

const logger = buildLogger('IncludedTermsCard')

export type OrderIncludedTermsCardStates = Pick<
  CommonOrderFormPageState,
  'orderDetail' | 'templateList' | 'attachments' | 'orderPlans'
>

export type OrderIncludedTermsCardProps<F> = {
  jotaiForm: JotaiForm<F>
}

export const IncludedTermsCard = <F extends OrderIncludedTermsCardStates>({
  hideAccountDocumentsField,
}: {
  hideAccountDocumentsField?: boolean
}): JSX.Element => {
  return (
    <BillyCard>
      <CardHeader title="Included Terms" />
      <CardContent>
        <Grid container rowGap={2}>
          <Grid item xs={12}>
            <PredefinedTermsAutocomplete<F> />
          </Grid>
          <Grid item xs={12} display="flex" alignItems="center">
            <CustomTermMuiField<F> />
          </Grid>
          {!hideAccountDocumentsField && <AccountDocumentsAutocomplete<F> />}
        </Grid>
      </CardContent>
    </BillyCard>
  )
}

function CustomTermMuiField<F extends OrderIncludedTermsCardStates>() {
  const jotaiForm = useJotaiFormContext<F>()

  const toggleDocumentCustomContentDialog = useDocumentCustomContentDialog(
    useMemo(
      () => ({
        orderPageForm: jotaiForm,
        onSubmit: (form: CustomContentDialogState) => {
          jotaiForm.set((draft) => {
            if (!draft.orderDetail.documentCustomContent) {
              draft.orderDetail.documentCustomContent = {
                orderId: draft.orderDetail.id || '',
                title: '',
                content: '',
              }
            }
            draft.orderDetail.documentCustomContent.title = form.title
            draft.orderDetail.documentCustomContent.content = form.content
          })
        },
        onDelete: () => {
          jotaiForm.set((draft) => {
            const documentCustomContent = draft.orderDetail.documentCustomContent
            if (documentCustomContent) {
              documentCustomContent.content = ''
              documentCustomContent.title = ''
            }
          })
        },
      }),
      [jotaiForm]
    )
  )

  const { hasContent, title } = jotaiForm.useSelect(
    useCallback(
      (form: OrderIncludedTermsCardStates) => ({
        hasContent: !!form.orderDetail.documentCustomContent?.content,
        title: form.orderDetail.documentCustomContent?.title,
      }),
      []
    )
  )

  return (
    <>
      <Box display="flex" flexGrow={1}>
        <JotaiMuiField
          atomSelector={() => title}
          textFieldProps={{
            label: 'Custom Terms',
            name: 'Custom Terms',
            disabled: true,
            InputProps: {
              startAdornment: title ? undefined : 'None',
            },
          }}
          errorPath="orderDetail.documentCustomContent"
          form={jotaiForm}
        />
      </Box>

      <ActionButton
        buttonData={{
          label: hasContent ? 'Modify' : 'Add',
          onClick: toggleDocumentCustomContentDialog,
          buttonProps: {
            size: 'large',
            variant: 'text',
          },
        }}
      />
    </>
  )
}

function PredefinedTermsAutocomplete<F extends OrderIncludedTermsCardStates>() {
  const jotaiForm = useJotaiFormContext<F>()
  const selectedTemplateIds = jotaiForm.useSelect(
    useCallback((form) => form.orderDetail.orderFormTemplates.map((template) => template.id) ?? [], [])
  )

  const planLevelTermIds = jotaiForm.useSelect(
    useCallback(
      (form) =>
        form.orderPlans
          .map((plan) => plan.templateIds)
          .flat()
          .filter(onlyUnique)
          .filter(notEmpty) ?? [],
      []
    )
  )
  return (
    <JotaiMuiAutocomplete
      atomOptionsSelector={useCallback(
        (form: OrderIncludedTermsCardStates) =>
          form.templateList?.filter(notEmpty).filter((template) => template.status === DocumentTemplateStatus.Active) ??
          [],
        []
      )}
      textFieldProps={{
        label: 'Predefined Terms',
        name: 'predefinedTerms',
        placeholder: 'Search',
      }}
      atomMultiUpdater={(value, draft) => {
        draft.orderDetail.orderFormTemplates = deepMutable(value) ?? []
      }}
      atomMultiValueSelector={(form) => form.orderDetail.orderFormTemplates ?? []}
      autocompleteProps={{
        renderTags(value, getTagProps) {
          return value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option.name}
              {...getTagProps({ index })}
              key={option.id}
              onDelete={planLevelTermIds.includes(option.id) ? undefined : getTagProps({ index }).onDelete}
            />
          ))
        },
        getOptionLabel(option) {
          return option.name
        },
        multiple: true,
        disableCloseOnSelect: true,
        renderOption(props, option) {
          return (
            <MenuItem {...props} key={option.id} value={option.id} disabled={planLevelTermIds.includes(option.id)}>
              <Checkbox checked={selectedTemplateIds.includes(option.id)} />
              {option.name}
            </MenuItem>
          )
        },
      }}
      errorPath="orderDetail.orderFormTemplates"
      form={jotaiForm}
    />
  )
}

function AccountDocumentsAutocomplete<F extends OrderIncludedTermsCardStates>() {
  const jotaiForm = useJotaiFormContext<F>()
  const selectedAccountDocId = jotaiForm.useSelect(useCallback((form) => form.orderDetail.attachmentId, []))
  return (
    <JotaiMuiAutocomplete
      atomOptionsSelector={useCallback(
        (form: OrderIncludedTermsCardStates) =>
          form.attachments?.filter(notEmpty).filter((attachment) => attachment.name.toLowerCase().endsWith('.pdf')) ??
          [],
        []
      )}
      textFieldProps={{
        label: 'Account Documents',
        name: 'Account Documents',
        placeholder: 'Search',
      }}
      atomUpdater={(value, draft) => {
        draft.orderDetail.attachmentId = value?.id ?? undefined
      }}
      atomValueSelector={(form) =>
        form.attachments?.find((attachment) => attachment.id === form.orderDetail.attachmentId) ?? undefined
      }
      autocompleteProps={{
        getOptionLabel(option) {
          return option.name
        },
        disableCloseOnSelect: true,
        renderOption(props, option) {
          return (
            <li {...props} key={option.id} value={option.id}>
              <Checkbox checked={selectedAccountDocId === option.id} />
              {option.name}
            </li>
          )
        },
      }}
      errorPath="orderDetail.attachmentId"
      form={jotaiForm}
    />
  )
}
