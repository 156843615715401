import pino from 'pino'
import 'pino-pretty'
import env from './env'

const buildLogger = (name: string): pino.Logger => {
  let logLevel = env.NEXT_PUBLIC_LOG_LEVEL || 'error'
  if (process.browser) {
    const storedLogLevel = window.localStorage.getItem('logLevel')
    if (storedLogLevel) {
      logLevel = storedLogLevel
    }
  }

  return pino({
    name,
    level: logLevel,
    prettyPrint: env.NEXT_PUBLIC_LOG_PRETTY === 'true' ? { colorize: true } : false,
    formatters: {
      level(level) {
        return { level }
      },
    },
  })
}

export default buildLogger
