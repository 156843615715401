import { Grid } from '@mui/material'
import { useCallback, useMemo } from 'react'
import { JotaiForm } from '../../../components/state/useJotaiForm'
import { BillyColumn } from '../../../components/table/baseTable'
import TableInCardWithButton from '../../../components/table/tableInCardWithButton'
import {
  Attachment,
  AttachmentFragment,
  DocumentTemplateFragment,
  OrderDetailFragment,
  OrderDocumentTemplateFragment,
  OrderTermsFragment,
  OrderTermsLevelType,
  useDeleteCustomPredefinedTemplateOnOrderMutation,
  useUpsertCustomPredefinedTemplateOnOrderMutation,
} from '../../../generated/graphql'
import { OrderViewPageForm } from '../../../pages/orders/[orderId]'
import { notEmpty } from '../../../util/array'
import buildLogger from '../../../util/logger'
import { useIncludedTermPreviewDialog } from './IncludedTermDialog'
import { useRouter } from 'next/router'
import StatusChip from '@/components/table/cells/statusChip'

const logger = buildLogger('IncludedTermsView')

export type IncludedTerm = TermWithPreview | TermWithDownload

export type TermWithPreview = { name: string; id: string; origin: string; content: string; isModified: boolean }

export function isTypeOfTermWithPreview(term?: IncludedTerm): term is TermWithPreview {
  return (term as TermWithPreview)?.content !== undefined
}

export type TermWithDownload = AttachmentFragment & {
  origin: 'Account Documents'
  isModified: boolean
}

export function isTypeOfTermWithDownload(term?: IncludedTerm): term is TermWithDownload {
  return (term as TermWithDownload)?.origin === 'Account Documents'
}

export const legacyTermsColumns: ReadonlyArray<BillyColumn<DocumentTemplateFragment>> = [
  {
    Header: 'Name',
    accessor: 'name',
  },
]

export const termsColumns: ReadonlyArray<BillyColumn<IncludedTerm>> = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Origin',
    accessor: 'origin',
  },
  {
    Header: '',
    accessor: 'isModified',
    Cell: ({ value }: { value: unknown }) => ((value as boolean) ? <StatusChip value="Modified" /> : <></>),
  },
]

export type IncludedTermsViewState = Pick<OrderViewPageForm, 'orderDetail' | 'accountDocument'>
export const IncludedTermsViewJotai = <F extends IncludedTermsViewState>({
  jotaiForm,
  refresh,
}: {
  jotaiForm: JotaiForm<F>
  refresh?: () => void
}): JSX.Element => {
  const orderDetail = jotaiForm.useSelect(useCallback((form) => form.orderDetail, []))
  const accountDocument = jotaiForm.useSelect(useCallback((form) => form.accountDocument, []))
  return <IncludedTermsView refresh={refresh} orderDetail={orderDetail} accountDocument={accountDocument} />
}

export function IncludedTermsView({
  orderDetail,
  accountDocument,
  refresh,
  allowEdit = true,
  hideOriginAndModified = false,
  title,
}: {
  orderDetail?: OrderDetailFragment | undefined
  accountDocument?: Attachment | undefined
  refresh?: () => void
  allowEdit?: boolean
  hideOriginAndModified?: boolean
  title?: string
}) {
  const termsColumns: ReadonlyArray<BillyColumn<IncludedTerm>> = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Origin',
      accessor: 'origin',
      hidden: hideOriginAndModified,
    },
    {
      Header: '',
      accessor: 'isModified',
      Cell: ({ value }: { value: boolean }): JSX.Element => {
        return value ? <StatusChip value="Modified" /> : <></>
      },
      hidden: hideOriginAndModified,
    },
  ]

  const [, upsertCustomPredefinedTerms] = useUpsertCustomPredefinedTemplateOnOrderMutation()
  const [, deleteCustomPredefinedTerms] = useDeleteCustomPredefinedTemplateOnOrderMutation()
  const router = useRouter()
  const orderId = router.query.orderId as string

  const includedTermsRow: IncludedTerm[] = useMemo(() => {
    const predefinedTermTemplateIds = orderDetail?.orderDocumentTemplates?.map((template) => template.id)
    const orderFormTemplates: (OrderDocumentTemplateFragment | DocumentTemplateFragment)[] | undefined =
      orderDetail?.orderFormTemplates.filter((template) => !predefinedTermTemplateIds?.includes(template.id))
    const orderTemplates = [...(orderFormTemplates || []), ...(orderDetail?.orderDocumentTemplates || [])]
    const orderTemplateRows: TermWithPreview[] =
      orderTemplates?.filter(notEmpty).map((template) => {
        const term = getOrderTerm(template.id, orderDetail)
        return {
          id: template.id,
          name: template.name,
          content: template.content,
          origin: formatTermOrigin(term),
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          isModified: template?.isModified ? template.isModified : false,
        }
      }) ?? []

    const accountDocumentRow: TermWithDownload[] = accountDocument
      ? [
          {
            ...accountDocument,
            origin: 'Account Documents',
            isModified: false,
          },
        ]
      : []

    const customTerm = orderDetail?.documentCustomContent
    const customTermRow: TermWithPreview[] = customTerm?.content
      ? [
          {
            name: customTerm.title ?? '',
            origin: 'Custom Terms',
            id: 'Custom Terms',
            content: customTerm?.content ?? '',
            isModified: false,
          },
        ]
      : []

    return [
      ...[...orderTemplateRows]
        .sort((templateA, templateB) => templateA.name.localeCompare(templateB.name))
        .sort((templateA, templateB) => templateA.origin.split(' ')[0].localeCompare(templateB.origin.split(' ')[0])),
      ...customTermRow,
      ...accountDocumentRow,
    ]
  }, [orderDetail, accountDocument])

  const [toggleIncludedTermPreviewDialog, setActiveTerm] = useIncludedTermPreviewDialog({
    orderId: orderDetail?.id ?? '',
    orderStatus: orderDetail?.status,
    onSubmit: async (termDraft) => {
      await upsertCustomPredefinedTerms({ documentTemplate: { ...termDraft, orderId: orderDetail?.id ?? '' } })
    },
    onRevert: async (termDraft) => {
      await deleteCustomPredefinedTerms({ orderId, templateId: termDraft.id })
    },
    refresh,
    allowEdit,
  })

  const rowHandler = {
    idField: 'id',
    onRowClick: (id: string) => {
      const term = includedTermsRow.find((term) => term.id === id)
      if (term) {
        setActiveTerm(term)
        toggleIncludedTermPreviewDialog()
      }
    },
  }

  return (
    <>
      {!!includedTermsRow.length && (
        <Grid item>
          <TableInCardWithButton
            title={title || 'Included Terms'}
            columns={termsColumns}
            data={includedTermsRow}
            rowHandler={rowHandler}
          />
        </Grid>
      )}
    </>
  )
}

function getOrderTerm(templateId: string, orderDetail?: OrderViewPageForm['orderDetail']) {
  const defaultTerm: OrderTermsFragment = {
    levelType: OrderTermsLevelType.Order,
    planIds: [],
    templateId,
    orderId: orderDetail?.id ?? '',
  }
  return orderDetail?.orderTerms?.find((term) => term?.templateId === templateId) ?? defaultTerm
}

function formatTermOrigin(orderTerm: OrderTermsFragment) {
  switch (orderTerm.levelType) {
    case OrderTermsLevelType.Order:
      return 'Predefined Terms'
    case OrderTermsLevelType.Plan:
      return 'Plan Level Terms'
    default:
      return 'Predefined Terms'
  }
}
