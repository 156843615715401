import { useJotaiFormContext } from '@/components/state/jotaiFormProvider'
import { useEditOrderTimelineReducer } from '@/pageComponents/orders/EditOrderPage/hooks/useEditOrderTimelineReducer'
import { reduceLineItem } from '@/pageComponents/orders/LineItemsEditTable/reduceLineItem'
import { useCallback } from 'react'
import { DeepMutable } from '../../../components/SchemaForm/DeepMutable'
import { CustomMenuItemProps } from '../../../components/menu/actionMenu'
import { ActionType, ChargeType, Feature, RecognitionType } from '../../../generated/graphql'
import { AmendSubscriptionPageState } from '../AmendmentOrderPage/AmendmentOrderPage'
import { NewOrderFormData } from '../EditOrderPage/NewOrderPage'
import { deleteLineItem, disableRamp, duplicateLineItem, enableRamp } from '../EditOrderPage/utils'
import { OrderItemRowActionHandlers } from './LineItemEditContextProvider'
import { OrderItemRowProps } from './orderChargeRow'
import useDynamicFeatureFlag from '@/components/state/useDynamicFeatureFlag'
import { useDryRunActions } from '@/pageComponents/orders/EditOrderPage/context/DryRunActionsContext'

export function useLineItemActions({
  lineItemIndex,
  orderType,
  onClickCustomField,
  onClickArrOverride,
  currency,
}: OrderItemRowProps & OrderItemRowActionHandlers) {
  const jotaiForm = useJotaiFormContext<NewOrderFormData>()
  const {
    lineItem,
    amendmentLineInAmendmentPeriod,
    isMinimumCommitCharge,
    isOnlyInstanceOfCharge,
    isChargeRamped,
    rampDisabledExplanation,
    resetToQuantity,
  } = jotaiForm.useSelect(
    useCallback(
      (form) => {
        return reduceLineItem({
          lineItemIndex,
          orderType,
          orderDetail: form.orderDetail,
          currency,
        })
      },
      [lineItemIndex, orderType, currency]
    )
  )
  const { toggleRampDialog } = useEditOrderTimelineReducer()
  const isRampForAmendmentsAllowed = useDynamicFeatureFlag(Feature.MultipleRampsForAmendment)
  const { queueDryRun } = useDryRunActions()
  const shouldDisableLineItemActions = orderType === 'RESTRUCTURE' && lineItem.action === ActionType.Remove

  /**
   * Handlers
   */
  const handleEnableRamp = useCallback(() => {
    jotaiForm.set((draft) => {
      enableRamp(lineItem)(draft)
    })
    queueDryRun()
  }, [jotaiForm, lineItem, queueDryRun])

  const handleDisableRamp = useCallback(() => {
    jotaiForm.set((draft) => {
      disableRamp(lineItem)(draft)
    })
    queueDryRun()
  }, [jotaiForm, lineItem, queueDryRun])

  const handleLineLevelRamp = useCallback(() => {
    toggleRampDialog('LINE_LEVEL')(lineItem)
  }, [toggleRampDialog, lineItem])

  const handleEditCustomField = () => onClickCustomField?.(lineItemIndex)

  const handleUndoClick = useCallback(() => {
    jotaiForm.set((form) => {
      const amendSubscriptionForm = form as unknown as DeepMutable<AmendSubscriptionPageState>
      const matchingLineItem = amendSubscriptionForm.orderDetail.lineItems.find(
        (li) => li.subscriptionChargeId === lineItem.subscriptionChargeId
      )
      if (matchingLineItem) {
        matchingLineItem.action = ActionType.None
        matchingLineItem.quantity = amendSubscriptionForm.orderDetail.currentSubscription?.charges.find(
          (charge) => charge?.id === matchingLineItem.subscriptionChargeId
        )?.quantity
        matchingLineItem.arrOverride = undefined
      }
    })
    queueDryRun()
  }, [jotaiForm, lineItem.subscriptionChargeId, queueDryRun])

  const handleDuplicateLineItem = useCallback(() => {
    jotaiForm.set((draft) => {
      duplicateLineItem(lineItemIndex)(draft)
    })
    queueDryRun()
  }, [jotaiForm, lineItemIndex, queueDryRun])

  const handleReplaceLineItem = useCallback(() => {
    jotaiForm.set((draft) => {
      duplicateLineItem(lineItemIndex)(draft)
      deleteLineItem(lineItemIndex)(draft)
    })
    queueDryRun()
  }, [jotaiForm, lineItemIndex, queueDryRun])

  const handleDeleteLineItem = useCallback(() => {
    jotaiForm.set((draft) => {
      deleteLineItem(lineItemIndex)(draft)
    })
    queueDryRun()
  }, [jotaiForm, lineItemIndex, queueDryRun])

  const handleLineItemViewArr = () => onClickArrOverride?.(lineItemIndex)

  /**
   * Actions logic
   */
  const resetQuantityAction = {
    children: `Revert to ${resetToQuantity}`,
    onClick: handleUndoClick,
  }

  const duplicateItemAction = {
    children: 'Duplicate',
    disabledExplanation:
      (lineItem.charge.type === ChargeType.Usage && 'Usage line items cannot be duplicated') ||
      (isMinimumCommitCharge && 'Minimum commit line item cannot be duplicated') ||
      (lineItem.action === ActionType.Remove && 'Removed item cannot be duplicated') ||
      (lineItem.chargeDetail.recognitionRule?.recognitionType === RecognitionType.Event &&
        'Charges with event based rev rec cannot be duplicated'),
    onClick: handleDuplicateLineItem,
  }

  const replaceItemAction = {
    children: 'Replace',
    onClick: handleReplaceLineItem,
  }

  const removeItemAction = {
    children: 'Remove',
    disabledExplanation:
      (isOnlyInstanceOfCharge && 'Cannot remove only instance of charge') ||
      (lineItem.isRamp && 'Ramped item cannot be removed') ||
      (orderType === 'RESTRUCTURE' && lineItem.action === ActionType.Remove && 'Item already removed'),
    onClick: handleDeleteLineItem,
  }

  const removeAmendmentItemAction = {
    children: 'Remove',
    disabledExplanation:
      ((lineItem.action === ActionType.Add || lineItem.action === ActionType.Restructure) &&
        isOnlyInstanceOfCharge &&
        'Cannot remove only instance of charge') ||
      (amendmentLineInAmendmentPeriod && 'Cannot remove a line item that ends before amendment start date') ||
      ([ChargeType.Prepaid, ChargeType.OneTime].includes(lineItem.charge.type) &&
        lineItem.action !== ActionType.Add &&
        'Cannot remove an existing prepaid or one time charge'),
    onClick: handleDeleteLineItem,
  }

  const arrOverrideEnabled = useDynamicFeatureFlag(Feature.OrderLineArrOverride)
  const viewLineItemARR = {
    children: 'View ARR',
    onClick: handleLineItemViewArr,
  }

  /**
   * Action menu items logic
   * TODO: refactor to use a map
   * TODO: added unit test
   */
  const actionMenuItems: CustomMenuItemProps[] = []
  if (isChargeRamped) {
    actionMenuItems.push({
      children: 'Remove Ramp',
      onClick: handleDisableRamp,
      disabledExplanation: shouldDisableLineItemActions ? 'This line item is already removed' : undefined,
    })
    actionMenuItems.push({
      children: 'Edit Line Level Ramp',
      onClick: handleLineLevelRamp,
      disabledExplanation: shouldDisableLineItemActions ? 'Cannot edit ramp on removed line item' : undefined,
    })
  } else {
    actionMenuItems.push({
      children: 'Apply Ramp',
      disabledExplanation: rampDisabledExplanation,
      onClick: handleEnableRamp,
    })
  }

  actionMenuItems.push({
    children: 'Edit Custom Fields',
    onClick: handleEditCustomField,
    disabledExplanation: shouldDisableLineItemActions ? 'Cannot edit custom fields on removed line item' : undefined,
  })
  if (arrOverrideEnabled) {
    actionMenuItems.push(viewLineItemARR)
  }
  actionMenuItems.push(duplicateItemAction)
  actionMenuItems.push(removeItemAction)

  /**
   * Amendment menu items logic
   * TODO: refactor to use a map
   * TODO: added unit test
   */
  const amendmentMenuItems: CustomMenuItemProps[] = []

  if (isRampForAmendmentsAllowed && orderType === 'AMENDMENT' && lineItem.action === ActionType.Add) {
    if (lineItem.isRamp) {
      amendmentMenuItems.push({
        children: 'Remove Ramp',
        onClick: handleDisableRamp,
        disabledExplanation: shouldDisableLineItemActions ? 'This line item is already removed' : undefined,
      })
      amendmentMenuItems.push({
        children: 'Edit Line Level Ramp',
        onClick: handleLineLevelRamp,
        disabledExplanation: shouldDisableLineItemActions ? 'Cannot edit ramp on removed line item' : undefined,
      })
    } else {
      amendmentMenuItems.push({
        children: 'Apply Ramp',
        disabledExplanation: rampDisabledExplanation,
        onClick: handleLineLevelRamp,
      })
    }
  }

  amendmentMenuItems.push({
    children: 'Edit Custom Fields',
    onClick: handleEditCustomField,
    disabledExplanation: shouldDisableLineItemActions ? 'Cannot edit custom fields on removed line item' : undefined,
  })

  if (arrOverrideEnabled) {
    amendmentMenuItems.push(viewLineItemARR)
  }
  if (lineItem.action === ActionType.Update || lineItem.action === ActionType.Remove) {
    amendmentMenuItems.push(resetQuantityAction)
  }
  amendmentMenuItems.push(duplicateItemAction)
  if (isMinimumCommitCharge) {
    amendmentMenuItems.push(replaceItemAction)
  }

  if (lineItem.action !== ActionType.Remove) {
    amendmentMenuItems.push(removeAmendmentItemAction)
  }

  return {
    handleEditCustomField,
    amendmentMenuItems,
    actionMenuItems,
  }
}
