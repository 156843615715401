import { OrderInvoicePreviewFragment, useGetOrderInvoicePreviewQuery } from '@/generated/graphql'
import { CardHeader, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import BillyCard from '@/components/card/billyCard'
import DateRangeStart from '@/components/DateRangeStart/DateRangeStart'
import DateRangeEnd from '@/components/DateRangeEnd/DateRangeEnd'
import { formatQuantity } from '@/util/priceTier'
import { billableCurrencyFormat, unitPriceFormatter } from '@/util/currencyUtil'
import React from 'react'
import { makeStyles } from 'tss-react/mui'

export const useOrderInvoicePreviewStyles = makeStyles()((theme, _params, _classes) => ({
  linkText: {
    fontWeight: 550,
    fontSize: '0.875rem',
  },
  cardHeader: {
    display: 'inline-block',
    fontSize: 17,
    fontWeight: 600,
    cursor: 'pointer',
  },
  tableHeader: {
    background: theme.customPalette.backgroundColor1,
    '& *': { fontWeight: 700 },
  },
  rowHeader: {
    fontWeight: 700,
    textAlign: 'right',
  },
  amount: {
    textAlign: 'right',
  },
}))

type OrderInvoicePreviewProps = {
  orderId: string
  currency: string
}

function OrderInvoicePreview(props: OrderInvoicePreviewProps) {
  const { orderId, currency } = props
  const { classes } = useOrderInvoicePreviewStyles()

  const [invoicePreviewResponse] = useGetOrderInvoicePreviewQuery({ variables: { orderId } })
  const invoicePreviews = invoicePreviewResponse.data?.orderInvoicePreview.invoicePreviews

  if (!invoicePreviews?.length) {
    return <></>
  }

  // only 1 invoice preview for now, may change in the future
  const invoicePreview: OrderInvoicePreviewFragment = invoicePreviews[0]
  return (
    <Grid item>
      <BillyCard>
        <CardHeader title="Invoice Items Preview" />
        <Table aria-label="order-invoice-preview-table">
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell>Charge</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell className={classes.amount}>Quantity</TableCell>
              <TableCell className={classes.amount}>Unit Price</TableCell>
              <TableCell className={classes.amount}>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoicePreview.invoiceItems.map((invoiceItem) => {
              const { amount, sellUnitPrice, quantity, periodStartDate, periodEndDate } = invoiceItem
              return (
                <TableRow key={`${invoiceItem.orderLineItemId}-${invoiceItem.periodEndDate}`}>
                  <TableCell>{invoiceItem.chargeId}</TableCell>
                  <TableCell>
                    <DateRangeStart datetime={periodStartDate} />
                  </TableCell>
                  <TableCell>
                    <DateRangeEnd datetime={periodEndDate} />
                  </TableCell>
                  <TableCell className={classes.amount}>{formatQuantity(quantity)}</TableCell>
                  <TableCell className={classes.amount}>
                    {unitPriceFormatter({
                      currency: currency,
                      value: sellUnitPrice,
                    })}
                  </TableCell>
                  <TableCell className={classes.amount}>
                    {billableCurrencyFormat({
                      currency: currency,
                      value: amount,
                    })}
                  </TableCell>
                </TableRow>
              )
            })}
            <TableRow>
              <TableCell colSpan={5}></TableCell>
              <TableCell colSpan={1} className={classes.rowHeader}>
                {billableCurrencyFormat({
                  currency: currency,
                  value: invoicePreview?.total,
                })}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={7} className={classes.rowHeader}>
                &nbsp;
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={5} className={classes.rowHeader}>
                Discounts
              </TableCell>
              <TableCell colSpan={1} className={classes.amount}>
                {billableCurrencyFormat({
                  currency: currency,
                  value: invoicePreview?.totalDiscount,
                })}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </BillyCard>
    </Grid>
  )
}

export default OrderInvoicePreview
