import { BillyMuiField } from '@/components/input/JotaiMuiField'
import { OrderStatus } from '@/generated/graphql'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useErrorHandler } from '../../../components/ErrorHandler/ErrorHandler'
import { useSnackbarHandler } from '../../../components/SnackbarHandler/SnackbarHandler'
import ActionButton from '../../../components/button/actionButton'
import { billyRestClient } from '../../../components/data/billyRestClient'
import BillyGridCell from '../../../components/grid/billyGridCell'
import { WithModalParams, useModal, useModalsContext } from '../../../components/state/context/modalsContext'
import { dateStrFromUnixTime, unixTimeSecondsFromISO8601 } from '../../../util/datetime/luxon/dateUtil'
import { download } from '../../../util/file'
import { TermsContentView } from '../../settings/templates/TermsContentView'
import { IncludedTerm, isTypeOfTermWithDownload, isTypeOfTermWithPreview } from './IncludedTermsView'
import useRevertPredefinedTermDialog from '../useRevertPredefinedTermDialog'
import ReactTinyMCEEditor, { withTableStyleTemplate } from '@/components/input/ReactTinyMCEEditor'

export type TermEdited = {
  id: string
  name: string
  content: string
}
type IncludedTermPreviewDialogProps = {
  onRevert: () => void
  term?: IncludedTerm
  orderId: string
  isEdit?: boolean
  onEdit?: () => void
  onOpen?: () => void
  onSubmit?: (term: TermEdited) => void | Promise<void>
  refresh?: () => void
  orderStatus: OrderStatus | undefined
}

type DialogProps = Omit<WithModalParams, 'onSubmit'> & IncludedTermPreviewDialogProps
const formID = 'IncludedTermDialog'

function IncludedTermDialog(props: DialogProps): JSX.Element {
  useEffect(() => {
    if (props.open) {
      props.onOpen?.()
    }
    // only depend on the open state
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open])
  return (
    <>
      {!props.isEdit && <PreviewDialog {...props} />}
      {!!props.isEdit && <EditPredefinedDialog {...props} />}
      {<DownloadDialog {...props} />}
    </>
  )
}

function EditPredefinedDialog({
  open,
  onClose,
  term,
  onSubmit,
  onRevert,
  refresh,
}: Omit<DialogProps, 'onRevert'> & { onRevert: () => void }): JSX.Element {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [termDraft, setTermDraft] = useState<TermEdited>({
    id: '',
    content: '',
    name: '',
  })

  useEffect(() => {
    if (isTypeOfTermWithPreview(term)) {
      setTermDraft({
        id: term.id,
        name: term.name,
        content: term.content,
      })
    }
  }, [term])

  const errorHandler = useErrorHandler()
  const snackbarHandler = useSnackbarHandler()
  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      async function doAsync() {
        setIsSubmitting(true)
        await onSubmit?.(termDraft)
        setIsSubmitting(false)
        onClose?.()
        refresh?.()
        snackbarHandler.successAlert('Updated successfully')
      }
      doAsync().catch((error) => {
        setIsSubmitting(false)
        errorHandler(error)
      })
    },
    [errorHandler, onSubmit, termDraft, onClose, refresh, snackbarHandler]
  )

  const onEditorChanged = (content: string): void => {
    setTermDraft((prev) => {
      return {
        ...prev,
        content: withTableStyleTemplate(content),
      }
    })
  }

  return isTypeOfTermWithPreview(term) ? (
    <Dialog open={!!open} onClose={onClose} maxWidth={'md'} scroll={'paper'} fullWidth disableEnforceFocus={true}>
      <DialogTitle>{'Customize Predefined Term'}</DialogTitle>
      <DialogContent dividers>
        <form id={formID} onSubmit={handleSubmit}>
          <Stack gap={2}>
            <BillyMuiField
              textFieldProps={{
                value: termDraft.name,
                label: 'Name',
                onChange: (event) => setTermDraft((prev) => ({ ...prev, name: event.target.value })),
              }}
            />
            <ReactTinyMCEEditor style={{ height: 350 }} value={termDraft?.content} onChange={onEditorChanged} />
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        <ActionButton
          buttonData={{
            label: 'Revert',
            onClick: onRevert,
            buttonProps: {
              style: { margin: 0 },
              variant: 'outlined',
              color: 'error',
              hidden: !term.isModified,
            },
          }}
        />
        <div style={{ flexGrow: 1 }} />
        <ActionButton
          buttonData={{
            label: 'Close',
            onClick: onClose,
            buttonProps: {
              variant: 'outlined',
              color: 'inherit',
            },
          }}
        />
        <ActionButton
          buttonData={{
            label: 'Save',
            loading: isSubmitting,
            buttonProps: {
              variant: 'contained',
              type: 'submit',
              form: formID,
            },
          }}
        />
      </DialogActions>
    </Dialog>
  ) : (
    <></>
  )
}

function PreviewDialog({ open, onClose, term, onEdit }: DialogProps): JSX.Element {
  return isTypeOfTermWithPreview(term) ? (
    <Dialog open={!!open} onClose={onClose} maxWidth={'md'} scroll={'paper'} fullWidth>
      <DialogTitle>{term.name}</DialogTitle>
      <DialogContent dividers>
        <TermsContentView dangerouslySetInnerHTML={term.content} />
      </DialogContent>
      <DialogActions>
        <div style={{ flexGrow: 1 }} />
        <ActionButton
          buttonData={{
            label: 'Close',
            onClick: onClose,
            buttonProps: {
              variant: 'outlined',
              color: 'inherit',
            },
          }}
        />
        {!!onEdit && (
          <ActionButton
            buttonData={{
              label: 'Edit',
              onClick: onEdit,
              buttonProps: {
                variant: 'contained',
              },
            }}
          />
        )}
      </DialogActions>
    </Dialog>
  ) : (
    <></>
  )
}

function DownloadDialog({ open, onClose, term }: DialogProps): JSX.Element {
  const errorHandler = useErrorHandler()
  const snackbarHandler = useSnackbarHandler()

  const termWithDownloadDetails = isTypeOfTermWithDownload(term)
    ? [
        {
          label: 'Filename',
          description: term.name ?? '',
          hide: !term.name,
          xs: 6,
        },
        {
          label: 'Last Modified',
          description: dateStrFromUnixTime(unixTimeSecondsFromISO8601(term.updatedOn ?? '')),
          hide: !term.updatedOn,
          xs: 6,
        },
        {
          label: 'Description',
          description: term.description ?? '',
          hide: !term.description,
          xs: 12,
        },
      ]
    : []

  function handleDownload() {
    async function doAsyncDownload() {
      const progressKey = snackbarHandler.inProgressAlert('Downloading...')
      try {
        if (isTypeOfTermWithDownload(term)) {
          await billyRestClient.attachments.getAttachment(term.id)
          download(`/attachments/${term.id}`)
        }
        snackbarHandler.successAlert('Downloaded')
      } finally {
        snackbarHandler.dismissAlert(progressKey)
      }
    }
    doAsyncDownload().catch(errorHandler)
    onClose?.()
  }

  return isTypeOfTermWithDownload(term) ? (
    <Dialog open={!!open} onClose={onClose} maxWidth={'sm'} scroll={'paper'} fullWidth>
      <DialogTitle>{term.name}</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          {termWithDownloadDetails.map((cell, index) => (
            <BillyGridCell {...cell} key={index} />
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <div style={{ flexGrow: 1 }} />
        <ActionButton
          key={'Close'}
          buttonData={{
            label: 'Close',
            onClick: onClose,
            buttonProps: {
              variant: 'outlined',
              color: 'inherit',
            },
          }}
        />
        <ActionButton
          key={'Download'}
          buttonData={{
            label: 'Download',
            onClick: handleDownload,
          }}
        />
      </DialogActions>
    </Dialog>
  ) : (
    <></>
  )
}

export function useIncludedTermPreviewDialog(
  modalProps: Omit<DialogProps, 'term' | 'isEdit' | 'onEdit' | 'onRevert'> & {
    onRevert?: (term: TermEdited) => void | Promise<void>
    allowEdit: boolean
  }
) {
  const [, , toggleModal] = useModalsContext()
  const [term, setTerm] = useState<IncludedTerm | undefined>(undefined)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const termDraft = isTypeOfTermWithPreview(term)
    ? { id: term.id, name: term.name, content: term.content }
    : { id: '', name: '', content: '' }
  const toggleRevertPredefinedDialog = useRevertPredefinedTermDialog({
    termDraft,
    onRevert: () => modalProps.onRevert?.(termDraft),
    onClose: modalProps.onClose,
    refresh: modalProps.refresh,
  })

  const isEditEnabled = modalProps.orderStatus === 'DRAFT' && modalProps.allowEdit && term?.origin !== 'Custom Terms'

  const editProps = {
    isEdit: isEditEnabled ? isEdit : false,
    onEdit: isEditEnabled ? () => setIsEdit(true) : undefined,
    onOpen: isEditEnabled ? () => setIsEdit(false) : undefined,
  }

  useModal<DialogProps>({
    component: IncludedTermDialog,
    schema: {
      key: formID,
      modalProps: {
        ...editProps,
        term,
        ...modalProps,
        onRevert: toggleRevertPredefinedDialog,
      },
    },
  })
  const toggleIncludedTermPreviewDialog = useCallback(() => {
    toggleModal(formID)
  }, [toggleModal])

  return [toggleIncludedTermPreviewDialog, setTerm] as [typeof toggleIncludedTermPreviewDialog, typeof setTerm]
}
