import { usePlanDataGridStyle } from '@/components/AddPlanDataGrid/AddPlanDataGrid'
import { QuickCastSearchInput } from '@/components/QuickCastBar/QuickCastSearchInput'
import { WithQuickAccessPortalProps } from '@/components/QuickCastBar/useQuickCastBarManager'
import BillyLink from '@/components/link/billyLink'
import { useBillyRouter } from '@/components/route/useBillyRouter'
import BillyDataGridPro from '@/components/table/billyDataGridPro'
import TableEmptyContent from '@/components/table/tableEmptyContent'
import { PlanMinimalFragment, useGetPlanMinimalsQuery } from '@/generated/graphql'
import buildLogger from '@/util/logger'
import { Stack } from '@mui/material'
import { GridColDef, GridFilterModel, GridLogicOperator } from '@mui/x-data-grid-pro'
import { useCallback, useMemo, useState } from 'react'
const logger = buildLogger('PlanSearchTable')

export type PlanSearchTableProps = WithQuickAccessPortalProps

export function PlanSearchTableView({ goToOptions, onDismiss }: PlanSearchTableProps): JSX.Element {
  const [planMinimals] = useGetPlanMinimalsQuery()

  const [filterModel, setFilterModel] = useState<GridFilterModel | undefined>(undefined)

  const handleSearch = useCallback((text: string) => {
    const query = text.trim()

    if (query === '') {
      setFilterModel({ items: [] })
    } else {
      // https://mui.com/x/migration/migration-data-grid-v5/#filtering
      setFilterModel({
        items: [
          {
            id: 1,
            field: 'name',
            operator: 'contains',
            value: text,
          },
          {
            id: 2,
            field: 'product_name',
            operator: 'contains',
            value: text,
          },
          {
            id: 3,
            field: 'product_category',
            operator: 'is',
            value: text,
          },
          {
            id: 4,
            field: 'description',
            operator: 'contains',
            value: text,
          },
        ],
        logicOperator: GridLogicOperator.Or,
      })
    }
  }, [])

  return (
    <Stack>
      <QuickCastSearchInput
        onChange={(event) => handleSearch(event.currentTarget.value)}
        inputProps={{
          placeholder: 'Search ...',
        }}
        onGoBack={goToOptions}
      />
      <PlanSearchTable
        loading={!!planMinimals.fetching}
        data={planMinimals.data?.planMinimals || []}
        filterModel={filterModel}
        onDismiss={onDismiss}
      />
    </Stack>
  )
}

export function PlanSearchTable({
  loading,
  data,
  filterModel,
  onDismiss,
}: {
  loading?: boolean
  data: readonly PlanMinimalFragment[]
  filterModel?: GridFilterModel
  onDismiss?: () => void
}): JSX.Element {
  const { classes } = usePlanDataGridStyle()
  const router = useBillyRouter()
  const planMinimals = useMemo(() => {
    return data.map((current) => {
      return {
        id: current.id as string,
        name: current.name,
        description: current.description ?? '',
        product_name: current.product.name ?? '',
        product_category: current.product.productCategory?.name ?? undefined,
        plan: current,
        productId: current.productId,
      }
    })
  }, [data])

  const planSearchTableColumns: GridColDef[] = [
    {
      headerName: 'ID',
      field: 'id',
      align: 'left',
      width: 150,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <BillyLink
            linkProps={{
              onClick: () => {
                router.push(`/products/${params.row.productId}/plans/${params.row.id}`)
                onDismiss?.()
              },
            }}
          >
            {params.value}
          </BillyLink>
        )
      },
    },
    {
      headerName: 'Plan Name',
      field: 'name',
      align: 'left',
      width: 250,
      minWidth: 150,
    },
    {
      headerName: 'Product Name',
      field: 'product_name',
      align: 'left',
      width: 200,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <BillyLink
            linkProps={{
              onClick: () => {
                router.push(`/products/${params.row.productId}`)
                onDismiss?.()
              },
            }}
          >
            {params.value}
          </BillyLink>
        )
      },
    },
    {
      headerName: 'Category',
      field: 'product_category',
      align: 'left',
      width: 200,
      minWidth: 150,
    },
    {
      headerName: 'Description',
      field: 'description',
      align: 'left',
      width: 250,
      minWidth: 150,
    },
  ]

  return (
    <BillyDataGridPro
      classes={classes}
      variant="stacked"
      loading={loading}
      disableRowSelectionOnClick
      disableColumnMenu={false}
      disableMultipleRowSelection={false}
      disableColumnResize={false}
      rows={planMinimals}
      slots={{
        noRowsOverlay: TableEmptyContent,
        noResultsOverlay: TableEmptyContent,
      }}
      slotProps={{
        noRowsOverlay: { message: 'No Plans.' },
        noResultsOverlay: { message: 'No Plans Found.' },
      }}
      initialState={{
        sorting: {
          sortModel: [{ field: 'name', sort: 'asc' }],
        },
      }}
      filterModel={filterModel}
      columns={planSearchTableColumns}
      autoHeight={false}
    />
  )
}
