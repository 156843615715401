import { DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material'
import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { deepMutable } from '../../components/SchemaForm/DeepMutable'
import ActionButton from '../../components/button/actionButton'
import BillyGridCell, { GridCellProps } from '../../components/grid/billyGridCell'
import BillyLink from '../../components/link/billyLink'
import StatusChip from '../../components/table/cells/statusChip'
import { ChargeDetailFragment, ChargeType, LineItemFragment, useGetChargeQuery } from '../../generated/graphql'
import { formatBillingCycle, getBillingCycleDisplayText } from '../../util/billingCycleDisplayUtil'
import { isRecurringCharge, lookupChargeModel } from '../../util/charge'
import { quantityFormat, unitPriceFormatter } from '../../util/currencyUtil'
import { formatUnixDate } from '../../util/datetime/luxon/dateUtil'
import buildLogger from '../../util/logger'
import { toTitleCase } from '../../util/string'
import ViewDefaultPriceAttributes from '../rateCard/useGetDefaultPriceAttributes'
import { PriceTiersTable } from './PriceTiersTable'

const logger = buildLogger('chargeDialogBody')

const useStyles = makeStyles()((theme, _params, _classes) => ({
  chargeHeader: {
    marginBottom: theme.spacing(2),
  },
  chargeSubtitle: {
    color: theme.customPalette.textGray,
  },
  chargeTitle: {
    color: theme.customPalette.textDark,
  },
  container: {},
  copyButton: {
    marginLeft: theme.spacing(0.5),
  },

  detailRow: {
    marginBottom: theme.spacing(2),
  },
  dialogTitle: {
    color: theme.customPalette.textDark,
    fontSize: 20,
  },
  label: {
    display: 'flex',
    alignSelf: 'bottom',
    color: theme.customPalette.textDark,
    fontWeight: theme.typography.fontWeightRegular,
  },
  value: {
    display: 'flex',
    alignSelf: 'bottom',
    color: theme.customPalette.textGray,
    fontWeight: theme.typography.fontWeightRegular,
  },
}))

export type ChargeDialogBodyProps = {
  chargeId: string
  planId: string
  onClose?: () => void
} & Pick<ChargeDialogDisplayProps, 'currency' | 'lineItem'>

type ChargeDialogDisplayProps = {
  currency?: string
  chargeDetail?: ChargeDetailFragment
  lineItem?: Pick<LineItemFragment, 'effectiveDate' | 'attributeReferences' | 'listUnitPrice'>
}

type WithChargeDetailCellDisplayParams = { hide?: boolean; alwaysLastRow?: boolean }

export const getChargeDetailsGridCells = ({ chargeDetail, lineItem, currency }: ChargeDialogDisplayProps) => {
  if (!chargeDetail) return []
  const unitPrice = lineItem?.listUnitPrice || chargeDetail?.amount
  const showPriceTier = !!chargeDetail.priceTiers?.length

  const chargeDetailCells: readonly ChargeDetailCell[] = [
    {
      label: 'Charge ID',
      description: chargeDetail.id ?? '',
      variant: 'copy-description' as const,
      hide: !chargeDetail.id,
    },
    {
      label: 'External ID',
      description: chargeDetail.externalId ?? '',
      hide: !chargeDetail.externalId,
    },
    {
      label: 'ERP ID',
      description: chargeDetail.erpId ?? '',
      hide: !chargeDetail.erpId,
    },
    {
      label: 'Charge Type',
      description: toTitleCase(chargeDetail.type),
      hide: !chargeDetail.type,
    },
    {
      label: 'Charge Model',
      description: lookupChargeModel(chargeDetail.chargeModel),
      hide: !chargeDetail.chargeModel,
    },
    {
      label: 'Recurring',
      description: Intl.NumberFormat('en-US', {
        style: 'percent',
        maximumFractionDigits: 2,
      }).format((chargeDetail?.percent || 0) / 100.0),

      hide: chargeDetail.type !== ChargeType.PercentageOf,
    },
    {
      label: 'Recurring',
      description: `${toTitleCase(chargeDetail.type)} ${getBillingCycleDisplayText({
        cycle: chargeDetail.recurrence,
      })}`,
      hide: !isRecurringCharge(chargeDetail.type),
    },
    {
      label: 'Unit Price',
      description: unitPriceFormatter({ currency, value: unitPrice }) || 'N/A',
      hide: showPriceTier,
    },
    {
      label: 'Is List Price Editable',
      description: chargeDetail.isListPriceEditable ? 'Yes' : 'No',
    },
    {
      label: 'Price Tiers',
      description: (
        <>
          {!!chargeDetail.priceTiers?.length && (
            <PriceTiersTable
              priceTiers={deepMutable(chargeDetail.priceTiers)}
              currency={currency}
              minQuantity={chargeDetail.minQuantity}
              maxQuantity={chargeDetail.maxQuantity}
            />
          )}
        </>
      ),
      sm: 6,
      hide: !showPriceTier,
      alwaysLastRow: true,
    },
    {
      label: 'Unit of Measure',
      description: chargeDetail?.unitOfMeasure?.name,
      hide: !chargeDetail?.unitOfMeasure?.name,
    },
    {
      label: 'Percent Derived From',
      description: toTitleCase(
        `${chargeDetail.percentDerivedFrom}`.toString().toLowerCase().replaceAll('_amount', ' price')
      ),
      hide: chargeDetail.type !== ChargeType.PercentageOf || !chargeDetail.percentDerivedFrom,
    },
    {
      label: 'Effective Date',
      description: formatUnixDate(lineItem?.effectiveDate ?? 0),
      hide: !lineItem?.effectiveDate,
    },
    {
      label: 'Billing Term',
      description: toTitleCase(chargeDetail?.billingTerm ?? ''),
      hide: !chargeDetail?.billingTerm,
    },
    {
      label: 'Billing Cycle',
      description: chargeDetail?.billingCycle ? formatBillingCycle(chargeDetail.billingCycle) : '',
      hide: !chargeDetail?.billingCycle,
    },
    {
      label: 'Is Event Based',
      description: chargeDetail.isEventBased ? 'Yes' : 'No',
    },
    {
      label: 'Revenue Recognition Rule',
      description: chargeDetail?.recognitionRule?.name,
      hide: !chargeDetail?.recognitionRule?.name,
    },
    {
      label: 'Minimum Quantity',
      description: quantityFormat(chargeDetail.minQuantity),
      hide: !chargeDetail.minQuantity && chargeDetail.minQuantity !== 0,
    },
    {
      label: 'Default Quantity',
      description: quantityFormat(chargeDetail.defaultQuantity),
      hide: !chargeDetail.defaultQuantity && chargeDetail.defaultQuantity !== 0,
    },
    {
      label: 'Maximum Quantity',
      description: quantityFormat(chargeDetail.maxQuantity),
      hide: !chargeDetail.maxQuantity && chargeDetail.maxQuantity !== 0,
    },
    {
      label: 'Duration In Months',
      description: `${chargeDetail.durationInMonths}`,
      hide: !chargeDetail.durationInMonths,
    },
    {
      label: 'Minimum Commit Charge',
      description: chargeDetail.minimumCommitBaseChargeId ?? '',
      hide: chargeDetail.type !== ChargeType.Usage || !chargeDetail.minimumCommitBaseChargeId,
    },
    {
      label: 'Rate Card',
      description: (
        <>
          {!!chargeDetail?.rateCardId && (
            <BillyLink nextProps={{ href: `/settings/rate-cards/${chargeDetail?.rateCardId}` }} target="_blank">
              {chargeDetail?.rateCardId}
            </BillyLink>
          )}
        </>
      ),
      hide: !chargeDetail?.rateCardId,
    },
    {
      label: 'Track ARR',
      description: chargeDetail.shouldTrackArr ? 'Yes' : 'No',
    },
  ].filter((cell) => !cell.hide)

  return chargeDetailCells
}

export type ChargeDetailCell = GridCellProps & WithChargeDetailCellDisplayParams
// Purpose of this body it to display  charge details for both subscription and order
// since they have different types so the components exposes props that could be used in both cases
// used by:
// - lineItemDialog(amendment order/cancellation order)
//   - jotai
// - chargesTable(order page)
//   - passed as an object
export const ChargeDialogBodyWithQuery: React.FC<React.PropsWithChildren<ChargeDialogBodyProps>> = ({
  chargeId,
  planId,
  lineItem,
  currency,
  onClose,
}) => {
  const handleClose = (): void => {
    onClose && onClose()
  }

  const [chargeDetailResponse] = useGetChargeQuery({
    variables: { chargeId, planId },
    pause: !chargeId,
  })
  const chargeDetail = chargeDetailResponse.data?.chargeDetail

  return chargeDetail ? (
    <>
      <ChargeDialogBody chargeDetail={chargeDetail} lineItem={lineItem} currency={currency} />
      <DialogActions>
        <ActionButton
          buttonData={{
            label: 'Close',
            onClick: handleClose,
            color: 'primary',
          }}
        />
      </DialogActions>
    </>
  ) : (
    <></>
  )
}

export function ChargeDialogBody({ chargeDetail, lineItem, currency }: ChargeDialogDisplayProps) {
  const { classes } = useStyles()

  const cellsPerRow = 4

  const gridCellSize = { xs: 12, sm: 12 / cellsPerRow }
  const getCellOrder = (length: number, index: number, alwaysLastRow?: boolean): number => {
    const getLastRowOrder = (length: number): number => {
      return length - (length % cellsPerRow) + 2
    }
    return alwaysLastRow ? getLastRowOrder(length) : index + 1
  }

  const chargeDetailCells = getChargeDetailsGridCells({
    chargeDetail,
    lineItem,
    currency,
  })

  return chargeDetail ? (
    <>
      <DialogTitle>
        <Grid display="flex" justifyContent={'space-between'} alignItems="center">
          <Typography className={classes.dialogTitle} component="p" fontWeight={600}>
            {'Charge Details'}
          </Typography>
          {!chargeDetail?.isRenewable && (
            <StatusChip value="Non-Renewable" color="disabled" chipProps={{ size: 'small' }} />
          )}
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container>
          <Grid container className={classes.chargeHeader}>
            <Grid item xs={8}>
              <Typography
                className={classes.chargeTitle}
                component="p"
                variant="subtitle1"
                fontWeight={600}
              >{`${chargeDetail?.name}`}</Typography>
            </Grid>
            {chargeDetail?.description && (
              <Grid item xs={12}>
                <Typography
                  className={classes.chargeSubtitle}
                  component="p"
                  variant="body1"
                >{`${chargeDetail?.description}`}</Typography>
              </Grid>
            )}
          </Grid>
          <Grid container spacing={1} className={classes.container}>
            {chargeDetailCells.map((cell, index) => (
              <BillyGridCell
                {...gridCellSize}
                {...cell}
                key={index}
                order={getCellOrder(chargeDetailCells.length, index, cell.alwaysLastRow)}
              />
            ))}
          </Grid>
          {lineItem?.attributeReferences ? (
            <>
              <Grid container spacing={1} pt={'1rem'}>
                <Grid item xs={8}>
                  <Typography className={classes.chargeTitle} component="p" variant="subtitle1">
                    {'Price Attributes'}
                  </Typography>
                </Grid>
              </Grid>
              {lineItem?.attributeReferences && chargeDetail?.rateCardId && (
                <Grid container spacing={1} className={classes.container}>
                  <ViewDefaultPriceAttributes
                    attributeReferences={lineItem?.attributeReferences}
                    rateCardId={chargeDetail?.rateCardId}
                    {...gridCellSize}
                  />
                </Grid>
              )}
            </>
          ) : (
            <></>
          )}
        </Grid>
      </DialogContent>
    </>
  ) : (
    <></>
  )
}

export default ChargeDialogBodyWithQuery
