import { z } from 'zod'
import {
  ApprovalFlowInstanceWorkflowStatus,
  InputApprovalFlowSubmitterNote,
  useUpsertApprovalFlowSubmitterNoteMutation,
} from '@/generated/graphql'
import { BillyTsForm } from '@/components/BillyTSForm/BillyTsForm'
import { useForm, UseFormReturn } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useBillyRouter } from '@/components/route/useBillyRouter'
import { useErrorHandler } from '@/components/ErrorHandler/ErrorHandler'
import ActionButton from '@/components/button/actionButton'
import { WithModalParams, useModal, useModalsContext } from '@/components/state/context/modalsContext'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { useCallback } from 'react'
import { JotaiForm } from '@/components/state/useJotaiForm'
import { OrderApprovalFlowsViewForm } from './orderApprovalFlowsView'

const approvalSubmitterNoteSchema = z.object({
  note: z.string().describe('Note // Enter a note').max(1000),
})

type DialogProps = WithModalParams & {
  jotaiForm: JotaiForm<OrderApprovalFlowsViewForm>
  form?: UseFormReturn<
    {
      note: string
    },
    unknown,
    undefined
  >
  submitterNote?: InputApprovalFlowSubmitterNote | null | undefined
}
const formID = 'ApprovalFlowSubmitterNoteDialog'

function ApprovalFlowSubmitterNoteDialog({ open, onClose, onSubmit, form, submitterNote }: DialogProps) {
  return (
    <Dialog open={!!open} onClose={onClose} maxWidth={'md'} scroll={'paper'} fullWidth>
      <BillyTsForm
        ignoreGrid
        form={form}
        props={{
          note: {
            layout: { xs: 12 },
            multiline: true,
            minRows: 4,
          },
        }}
        onSubmit={(data) => {
          onSubmit?.(data)
          onClose?.()
        }}
        schema={approvalSubmitterNoteSchema}
        defaultValues={{
          note: submitterNote?.note || undefined,
        }}
      >
        {({ ...fields }) => (
          <>
            <DialogTitle>{submitterNote?.note ? 'Edit' : 'Add'} Note</DialogTitle>
            <DialogContent dividers>{Object.values(fields)}</DialogContent>
            <DialogActions>
              <div style={{ flexGrow: 1 }} />
              <ActionButton
                key={'Cancel'}
                buttonData={{
                  label: 'Cancel',
                  onClick: onClose,
                  color: 'inherit',
                  buttonProps: { variant: 'outlined', type: 'button' },
                }}
              />
              <ActionButton
                key={'Save'}
                buttonData={{
                  label: 'Save',
                  buttonProps: {
                    type: 'submit',
                  },
                }}
              />
            </DialogActions>
          </>
        )}
      </BillyTsForm>
    </Dialog>
  )
}

export function useApprovalFlowSubmitterNoteDialog(modalProps: DialogProps) {
  const [, , toggleModal] = useModalsContext()
  const errorHandler = useErrorHandler()
  const billyRouter = useBillyRouter()
  const orderId = billyRouter.query.orderId as string
  const [, upsertApprovalFlowSubmitterNote] = useUpsertApprovalFlowSubmitterNoteMutation()
  const submitterNote = modalProps.jotaiForm.useSelect(
    (form) =>
      form.orderDetail?.approvalFlows?.find(
        (flow) => flow.workflowStatus === ApprovalFlowInstanceWorkflowStatus.Preview
      )?.submitterNote
  )

  const billyForm = useForm<z.infer<typeof approvalSubmitterNoteSchema>>({
    resolver: zodResolver(approvalSubmitterNoteSchema),
  })

  const onSubmit = async (data: { note: string }) => {
    try {
      const response = await upsertApprovalFlowSubmitterNote({
        approvalFlowSubmitterNote: { ...data, orderId, id: submitterNote?.id } as InputApprovalFlowSubmitterNote,
      })
      if (response.data) {
        modalProps.jotaiForm.set((draft) => {
          draft.orderDetail?.approvalFlows?.forEach((flow) => {
            if (flow.workflowStatus === ApprovalFlowInstanceWorkflowStatus.Preview) {
              flow.submitterNote = response.data?.upsertApprovalFlowSubmitterNote
            }
          })
        })
      }
    } catch (error) {
      errorHandler(error)
    }
  }

  useModal<DialogProps, OrderApprovalFlowsViewForm>({
    component: ApprovalFlowSubmitterNoteDialog,
    schema: {
      key: formID,
      modalProps: {
        ...modalProps,
        submitterNote,
        form: billyForm,
        onSubmit,
      },
    },
  })
  const toggleApprovalFlowSubmitterNoteDialog = useCallback(() => {
    toggleModal(formID)
  }, [toggleModal])

  return [toggleApprovalFlowSubmitterNoteDialog]
}
