import { useMemo } from 'react'
import { deepMutable } from '../../components/SchemaForm/DeepMutable'
import { JotaiForm } from '../../components/state/useJotaiForm'
import { WithUrql, useJotaiUrqlQuery } from '../../components/state/useJotaiUrqlQuery'
import {
  ApprovalSegmentsDocument,
  ApprovalSegmentsQuery,
  ApprovalSegmentsQueryVariables,
} from '../../generated/graphql'
import { CommonOrderFormPageState } from './EditOrderPage/CommonOrderFormPageState'

export function useUpdateApprovalSegments<
  F extends Pick<CommonOrderFormPageState, 'orderDetail' | 'approvalSegments'> & WithUrql
>({ jotaiForm }: { jotaiForm: JotaiForm<F>; pause?: boolean }) {
  useJotaiUrqlQuery<F, ApprovalSegmentsQuery, ApprovalSegmentsQueryVariables>(
    useMemo(
      () => ({
        document: ApprovalSegmentsDocument,
        jotaiForm,
        variables: (form: F) => {
          return { userId: form?.orderDetail?.owner?.id }
        },
        pause: (form: F) => !form.orderDetail?.owner?.id,
        onData: (data, draft) => {
          draft.approvalSegments = deepMutable(data.approvalSegments)
          const shouldUpdateApprovalSegment = !data.approvalSegments
            .map((segment) => segment?.id)
            .includes(draft.orderDetail.approvalSegment?.id ?? '')
          if (shouldUpdateApprovalSegment) {
            draft.orderDetail.approvalSegment = data.approvalSegments[0]
          }
        },
      }),
      [jotaiForm]
    )
  )
}
