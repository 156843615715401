import { CancelAndRestructureOrderDetail } from '@/pageComponents/orders/CancelAndRestructureOrderForm'
import { ButtonData, getMenuAndPrimaryButton, shouldButtonShowIn } from '../components/button/actionButton'
import {
  AccountFragment,
  BillingTerm,
  CancelAndRestructureOrderDetailFragment,
  Cycle,
  ElectronicSignatureProvider,
  ElectronicSignatureStatus,
  OpportunityFragment,
  OrderDetailFragment,
  OrderStartDateType,
  OrderStatus,
  OrderType,
  Role,
  Status,
} from '../generated/graphql'
import { getOrderApprovalFlowsError } from '../pageComponents/orders/orderApprovalFlowsView'
import { NOT_APPLICABLE, OrderViewPageForm } from '../pages/orders/[orderId]'
import { luxonDayStart, unixTimeSecondsNow } from './datetime/luxon/dateUtil'
import { canExecuteOrder, canMutateOrder, isAdminRole } from './roleUtils'
import BillyLink from '@/components/link/billyLink'
import { Typography } from '@mui/material'
import { billableCurrencyFormat } from './currencyUtil'
import EntityCell from '@/components/table/cells/EntityCell'
import { deepMutable } from '@/components/SchemaForm/DeepMutable'
import { omit } from 'lodash'

export function getDefaultOrderDetail(): OrderDetailFragment {
  return {
    account: {
      name: '',
    },
    accountDetail: {
      address: {
        __typename: 'AccountAddress',
        city: '',
        country: '',
        state: undefined,
        streetAddressLine1: '',
        streetAddressLine2: undefined,
        zipcode: '',
      },
      __typename: 'AccountDetail',
      crmId: undefined,
      currency: undefined,
      hasAutomaticPayment: undefined,
      id: '',
      name: '',
    },
    approvalFlows: [],
    orderType: OrderType.New,
    status: OrderStatus.Draft,
    startDate: luxonDayStart(unixTimeSecondsNow()).toUnixInteger(),
    lineItems: [],
    billingCycle: { cycle: Cycle.Year, step: 1 },
    paymentTerm: '', // the real default is set later
    termLength: { cycle: Cycle.Year, step: 1 },
    orderFormTemplates: [],
    sfdcOpportunityId: '',
    sfdcOpportunityName: '',
    sfdcOpportunityStage: '',
    sfdcOpportunityType: '',
    endDate: 0,
    autoRenew: true,
    compositeOrderId: '',
    startDateType: OrderStartDateType.Fixed,
  }
}

export function getDefaultCancelAndRestructureOrderDetail(): CancelAndRestructureOrderDetail {
  return {
    account: {
      name: '',
    },
    autoRenew: false,
    billingAnchorDate: 0,
    billingContact: {
      id: '',
      email: '',
      firstName: '',
    },
    billingCycle: {
      cycle: Cycle.Year,
      step: 1,
    },
    billingTerm: BillingTerm.UpFront,
    documentMasterTemplateId: '',
    startDate: 0,
    endDate: 0,
    id: '',
    lineItems: [],
    name: '',
    orders: [],
    owner: {
      id: '',
      displayName: '',
      state: Status.Active,
      email: '',
      role: Role.Sales,
    },
    paymentTerm: '',
    predefinedDiscounts: [],
    purchaseOrderNumber: '',
    purchaseOrderRequiredForInvoicing: false,
    orderType: OrderType.Restructure,
    approvalFlows: [],
    orderFormTemplates: [],
    accountDetail: {
      address: {
        __typename: 'AccountAddress',
        city: '',
        country: '',
        state: undefined,
        streetAddressLine1: '',
        streetAddressLine2: undefined,
        zipcode: '',
      },
      __typename: 'AccountDetail',
      crmId: undefined,
      currency: undefined,
      hasAutomaticPayment: undefined,
      id: '',
      name: '',
    },
  }
}

export function getOrderViewActionButtons({
  variant = 'menu-and-primary',
  steppingFormat,
  form,
  role,
  orderCanBeMarkedPrimary,
  orderCanBeSynced,
  isOpportunityClosed,
  handlePdfDownload,
  handleWordDocDownload,
  onSubmitForApproval,
  handleSyncToCRM,
  deleteDraftOrder,
  changeOrderStatus,
  onMarkAsExecuted,
  onMarkOrderAsPrimary,
  onRequestEsignature,
  isEsignInProgress,
  isSalesRoomLinkGenerated,
  toggleSalesRoomLinkVoidedDialog,
  toggleEsignInProgressDialog,
  esignStatus,
  esignProvider,
}: {
  variant?: 'single-menu' | 'menu-and-primary'
  steppingFormat?: string
  form: Pick<OrderViewPageForm, 'orderDetail'>
  role: Role
  orderCanBeMarkedPrimary?: boolean | null
  orderCanBeSynced?: boolean | null
  isOpportunityClosed?: boolean | null
  handlePdfDownload?: () => void
  handleWordDocDownload?: () => void
  onSubmitForApproval?: () => void
  handleSyncToCRM?: () => void
  deleteDraftOrder?: () => void
  changeOrderStatus?: (status: OrderStatus) => void
  onMarkAsExecuted?: () => void
  onMarkOrderAsPrimary?: () => void
  onRequestEsignature?: () => void
  isEsignInProgress?: boolean | null
  toggleEsignInProgressDialog?: () => void
  isSalesRoomLinkGenerated?: boolean | null
  toggleSalesRoomLinkVoidedDialog?: () => void
  esignStatus?: ElectronicSignatureStatus
  esignProvider?: ElectronicSignatureProvider | null
}) {
  const orderData = form.orderDetail
  const orderId = form.orderDetail?.id ?? ''
  const isComposite = form.orderDetail?.compositeOrderId
  const hasContacts = !!form.orderDetail?.shippingContact && !!form.orderDetail?.billingContact

  let actionsMenu: ButtonData<OrderStatus>[] = []

  let primaryButton: ButtonData | undefined = undefined

  function syncToCrmOrderStatuses() {
    if (!orderCanBeSynced) {
      return []
    }

    if (isAdminRole(role)) {
      return [OrderStatus.Draft, OrderStatus.Submitted, OrderStatus.Approved, OrderStatus.Executed, OrderStatus.Expired]
    }

    return canMutateOrder(role) ? [OrderStatus.Draft, OrderStatus.Submitted, OrderStatus.Approved] : []
  }

  if (orderData) {
    const allActionsMenu = [
      // most frequent actions
      {
        label: 'Download Order Form PDF',
        disabledExplanation: isComposite ? 'This order is part of a composite order' : undefined,
        onClick: handlePdfDownload,
      },
      {
        label: 'Download Order Form DOC',
        disabledExplanation: isComposite
          ? 'This order is part of a composite order'
          : isEsignInProgress &&
            (esignStatus === ElectronicSignatureStatus.Completed ||
              esignStatus === ElectronicSignatureStatus.PartiallySigned)
          ? 'Signed order forms are not available for download in the DOC format'
          : undefined,
        onClick: handleWordDocDownload,
        show: isAdminRole(role) ? undefined : [],
      },
      {
        label: 'Make Primary',
        disabledExplanation: isComposite ? 'This order is part of a composite order' : undefined,
        onClick: onMarkOrderAsPrimary,
        show:
          canMutateOrder(role) && orderCanBeMarkedPrimary
            ? [OrderStatus.Draft, OrderStatus.Submitted, OrderStatus.Approved]
            : [],
      },
      {
        label: 'Sync to CRM',
        disabledExplanation: isComposite ? 'This order is part of a composite order' : undefined,
        onClick: () => handleSyncToCRM?.(),
        show: syncToCrmOrderStatuses(),
      },

      // Primary order related operations
      {
        label: 'Submit for Approval',
        disabledExplanation: isComposite
          ? 'This order is part of a composite order'
          : getOrderApprovalFlowsError(form.orderDetail?.approvalFlows || [])?.[0],
        onClick: onSubmitForApproval,
        show: canMutateOrder(role) ? [OrderStatus.Draft] : [],
        primary: true,
      },
      {
        label: isEsignInProgress ? 'View eSignature Details' : 'Request eSignature',
        disabledExplanation: isComposite ? 'This order is part of a composite order' : undefined,
        onClick: onRequestEsignature,
        show: (() => {
          if (!esignProvider) {
            return []
          }
          switch (orderData.status) {
            case OrderStatus.Approved:
              return isEsignInProgress ? [OrderStatus.Approved] : canMutateOrder(role) ? [OrderStatus.Approved] : []
            case OrderStatus.Executed: {
              return isEsignInProgress ? [OrderStatus.Executed] : []
            }
            default:
              return []
          }
        })(),
        primary: ElectronicSignatureStatus.Completed !== esignStatus,
      },
      {
        label: 'Mark as Executed',
        onClick: onMarkAsExecuted,
        disabledExplanation: isOpportunityClosed
          ? 'This opportunity is closed'
          : isComposite
          ? 'This order is part of a composite order'
          : !hasContacts
          ? 'Missing shipping or billing contact'
          : '',
        show: canExecuteOrder(role) ? [OrderStatus.Approved] : [],
        primary: true,
      },
      {
        label: 'Revert to Draft',
        disabledExplanation: isComposite ? 'This order is part of a composite order' : undefined,
        onClick: () =>
          isEsignInProgress
            ? toggleEsignInProgressDialog?.()
            : isSalesRoomLinkGenerated
            ? toggleSalesRoomLinkVoidedDialog?.()
            : changeOrderStatus?.(OrderStatus.Draft),
        show: canMutateOrder(role) ? [OrderStatus.Submitted, OrderStatus.Approved] : [],
        primary: true,
      },

      // More order related operations
      {
        label: 'Edit Order',
        onClick: `/orders/${orderId}/edit${steppingFormat ? `?steppingFormat=${steppingFormat}` : ''}`,
        disabledExplanation:
          orderData.compositeOrderId && [OrderType.Restructure, OrderType.Cancel].includes(orderData.orderType)
            ? 'This order is part of a cancel and restructure order'
            : undefined,
        show: canMutateOrder(role) ? [OrderStatus.Draft, OrderStatus.Expired] : [],
      },
      {
        label: 'Duplicate Order',
        disabledExplanation: isComposite ? 'This order is part of a composite order' : undefined,
        onClick: `/orders/new?sourceId=${orderId}`,
        show:
          canMutateOrder(role) && (orderData.orderType === OrderType.New || orderData.orderType === OrderType.Renewal)
            ? undefined
            : [],
      },
      {
        label: 'Delete Order',
        disabledExplanation: isComposite ? 'This order is part of a composite order' : undefined,
        onClick: () => deleteDraftOrder?.(),
        show: canMutateOrder(role) ? [OrderStatus.Draft, OrderStatus.Expired] : [],
      },
    ].filter(shouldButtonShowIn(orderData.status))

    if (variant === 'single-menu') {
      actionsMenu = allActionsMenu
      primaryButton = undefined
    }

    if (variant === 'menu-and-primary') {
      const separated = getMenuAndPrimaryButton(allActionsMenu)
      actionsMenu = separated.actionsMenu
      primaryButton = separated.primaryButton
    }
  }

  return { actionsMenu, primaryButton }
}

export const debounceDelayMillis = 1000

export const getCompositeOrderDetailRows = ({
  cancelAndRestructureOrderData,
  account,
  entityId,
  classes,
  opportunityData,
}: {
  cancelAndRestructureOrderData: CancelAndRestructureOrderDetailFragment | null
  account: AccountFragment
  classes: Record<string, string>
  opportunityData?: OpportunityFragment | null
  entityId?: string | null
}) => {
  const currency = cancelAndRestructureOrderData?.account.currency
  return [
    {
      cells: [
        {
          label: 'Account',
          content: (
            <BillyLink nextProps={{ href: `/accounts/${account?.id}` }}>
              <Typography className={classes.linkText}>{account?.name}</Typography>
            </BillyLink>
          ),
        },
        ...(entityId
          ? [
              {
                label: 'Entity',
                content: <EntityCell value={entityId} />,
              },
            ]
          : []),
      ],
    },
    {
      cells: cancelAndRestructureOrderData
        ? [
            {
              label: 'CRM Opportunity Name',
              content: opportunityData ? (
                <BillyLink nextProps={{ href: `/opportunities/${opportunityData?.opportunityId}` }}>
                  <Typography className={classes.linkText}>{opportunityData?.name}</Typography>
                </BillyLink>
              ) : undefined,
            },
            {
              label: 'ARR (MRR) Now',
              content:
                cancelAndRestructureOrderData?.arr == null
                  ? NOT_APPLICABLE
                  : `${billableCurrencyFormat({
                      currency,
                      value: cancelAndRestructureOrderData.arr,
                    })} (${billableCurrencyFormat({
                      currency,
                      value: cancelAndRestructureOrderData.arr / 12,
                    })})`,
            },
            {
              label: 'Delta ARR (MRR)',
              content:
                cancelAndRestructureOrderData?.deltaArr == null
                  ? NOT_APPLICABLE
                  : `${billableCurrencyFormat({
                      currency,
                      value: cancelAndRestructureOrderData.deltaArr,
                    })} (${billableCurrencyFormat({
                      currency,
                      value: cancelAndRestructureOrderData.deltaArr / 12,
                    })})`,
            },
            {
              label: 'TCV',
              content:
                cancelAndRestructureOrderData?.tcv == null
                  ? NOT_APPLICABLE
                  : `${billableCurrencyFormat({
                      currency,
                      value: cancelAndRestructureOrderData.tcv,
                    })} (${billableCurrencyFormat({
                      currency,
                      value: cancelAndRestructureOrderData.tcv / 12,
                    })})`,
            },
          ]
        : [],
    },
  ]
}

export const mapOnDryRunData = ({
  orderDetail,
  data,
}: {
  orderDetail: OrderDetailFragment
  data: OrderDetailFragment
}) => {
  return deepMutable({
    ...orderDetail,
    ...omit(data, ['sfdcOpportunityId', 'sfdcOpportunityName', 'sfdcOpportunityStage', 'sfdcOpportunityType']),
  })
}
