import { memo, useState } from 'react'
import { Box, Collapse, Link, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import NextLink from 'next/link'
import { makeStyles } from 'tss-react/mui'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { useSideDrawerLinks } from './useSideDrawerLinks'
import { useRouter } from 'next/router'

export type NavLink = {
  icon?: JSX.Element | string
  text: string
  link: string
  show?: boolean
  selected?: boolean
  inset?: boolean
  isExternal?: boolean
}

export type NavMenu = {
  icon: JSX.Element | string
  text: string
  menu: NavLink[]
  show?: boolean
  selected?: boolean
}

export const isTypeOfNavMenu = (item: NavLink | NavMenu): item is NavMenu => {
  return (item as NavMenu).menu !== undefined
}

const isTypeOfNavLink = (item: NavLink | NavMenu): item is NavLink => {
  return (item as NavLink).link !== undefined
}

const useStyles = makeStyles<{
  selected?: boolean
}>()((theme, { selected }, _classes) => ({
  link: {
    height: 52,
    borderRadius: 8,
    marginBottom: 2,
    padding: theme.spacing(1.75, 2),
    color: selected ? theme.customPalette.selectedColor1 : theme.palette.text.primary,
    '&:hover': {
      backgroundColor: selected ? theme.customPalette.selectedBackgroundColor1 : theme.customPalette.hoverColor2,
    },
    '& .MuiListItemIcon-root': {
      minWidth: 40,
    },
    '& .MuiTypography-root': {
      fontWeight: 600,
    },
    backgroundColor: selected ? theme.customPalette.selectedBackgroundColor1 : theme.palette.background.paper,
  },
  menu: {
    height: 52,
    borderRadius: 8,
    marginBottom: 2,
    padding: theme.spacing(1.75, 2),
    '&:hover': {
      backgroundColor: theme.customPalette.hoverColor2,
    },
    ' &.Mui-selected:hover': {
      color: theme.customPalette.selectedColor1,
    },
    '& .MuiListItemIcon-root': {
      minWidth: 40,
    },
    '& .MuiTypography-root': {
      fontWeight: 600,
    },
    color: selected ? theme.customPalette.selectedColor1 : theme.palette.text.primary,
  },
}))

function NavLinkListItem(props: NavLink) {
  const { classes } = useStyles({ selected: !!props.selected })
  return (
    <NextLink
      href={props.isExternal ? props.link : `/${props.link}`}
      key={props.text}
      passHref
      target={props.isExternal ? '_blank' : undefined}
      rel={props.isExternal ? 'noreferrer' : undefined}
    >
      <Link variant="inherit" color="inherit">
        <ListItem className={classes.link} selected={!!props.selected}>
          {!!props.icon && <ListItemIcon>{props.icon}</ListItemIcon>}
          <ListItemText inset={props.inset}>
            <Typography component="span" variant="body2">
              {props.text}
            </Typography>
          </ListItemText>
        </ListItem>
      </Link>
    </NextLink>
  )
}

function NavMenuListItem({
  open,
  setOpen,
  ...props
}: NavMenu & {
  open?: boolean
  setOpen?: (setTo: boolean) => void
}): JSX.Element {
  const { classes } = useStyles({ selected: props.selected })
  return (
    <Box>
      <ListItemButton className={classes.menu} onClick={() => setOpen?.(!open)}>
        {!!props.icon && <ListItemIcon>{props.icon}</ListItemIcon>}
        <ListItemText>
          <Typography component="span" variant="body2">
            {props.text}
          </Typography>
        </ListItemText>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {props.menu.map((item, index) => (
          <NavLinkListItem {...item} key={index} />
        ))}
      </Collapse>
    </Box>
  )
}

const SideDrawerNavWithoutMemo = (): JSX.Element => {
  const sideDrawerLinks = useSideDrawerLinks()

  const [openIndex, setOpenIndex] = useState<number>(
    sideDrawerLinks.findIndex((item) => isTypeOfNavMenu(item) && item.menu.some((item) => item.selected))
  )
  const router = useRouter()

  return (
    <Box component="nav" style={{ margin: 8 }}>
      {sideDrawerLinks.map((item, index) => {
        if (isTypeOfNavMenu(item)) {
          return (
            <NavMenuListItem
              {...item}
              key={index}
              open={openIndex === index}
              setOpen={(setTo?: boolean) => {
                if (setTo) {
                  setOpenIndex(index)
                  void router.push(`/${item.menu[0].link}`)
                } else {
                  setOpenIndex(-1)
                }
              }}
            />
          )
        } else if (isTypeOfNavLink(item)) {
          return <NavLinkListItem {...item} key={index} />
        }
      })}
    </Box>
  )
}

export const SideDrawerNav = memo(SideDrawerNavWithoutMemo)
