import ActionButton from '@/components/button/actionButton'
import { InputAttributeReferenceWithAttributeName } from '@/components/EditChargeForm/IEditCharge'
import BillyGridCell from '@/components/grid/billyGridCell'
import { WithBaseModalParams, useModal, useModalsContext } from '@/components/state/context/modalsContext'
import { useJotaiFormContext } from '@/components/state/jotaiFormProvider'
import { LineItemFragment, useGetPriceAttributesQuery, useGetRateCardQuery } from '@/generated/graphql'
import { useDryRunActions } from '@/pageComponents/orders/EditOrderPage/context/DryRunActionsContext'
import { NewOrderFormData } from '@/pageComponents/orders/EditOrderPage/NewOrderPage'
import { isSubsetArray } from '@/util/array'
import { unitPriceFormatter } from '@/util/currencyUtil'
import { toTitleCase } from '@/util/string'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Radio,
  Typography,
} from '@mui/material'
import { DataGridPro, GridRowId, GridToolbar } from '@mui/x-data-grid-pro'
import { useCallback, useEffect, useState } from 'react'
import { createPriceTableRowsAndColumns } from './RateCardView'

type RateCardAttributeSelectDialogProps = WithBaseModalParams & {
  rateCardId: string
  readonly selectedAttributes: LineItemFragment['attributeReferences']
  onSubmit: (selectedAttributes: Array<InputAttributeReferenceWithAttributeName>) => void
  modalKey?: number | string
}

type WithFx = {
  currency?: string
  startDate?: number
}

function RateCardAttributeSelectDialog({
  open,
  onClose,
  rateCardId,
  onSubmit,
  selectedAttributes,
  currency,
  startDate,
}: RateCardAttributeSelectDialogProps & WithFx) {
  const [rateCardResponse] = useGetRateCardQuery({
    variables: {
      id: rateCardId,
      targetCurrencyCode: currency,
      effectiveDate: startDate,
    },
    pause: !rateCardId,
  })
  const [priceAttributesResponse] = useGetPriceAttributesQuery()
  const loading = rateCardResponse.fetching || priceAttributesResponse.fetching
  const rateCard = rateCardResponse.data?.rateCard
  const priceAttributes = priceAttributesResponse.data?.priceAttributes || []

  const priceTable = rateCard?.priceTable

  const { rows, columns } = createPriceTableRowsAndColumns({
    priceTable,
    priceAttributes,
    currency: rateCard?.currencyCode,
  })
  const defaultSelectedValues = selectedAttributes?.map((attribute) => attribute?.attributeValue) || []
  const defaultSelectedRowId = rows.find((row) => {
    const rowValues = Object.keys(row).map((rowKey) => row[rowKey])
    return isSubsetArray(rowValues, defaultSelectedValues)
  })?.id
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>(
    defaultSelectedRowId !== undefined ? [defaultSelectedRowId] : []
  )
  const selectedId = selectionModel[0]
  const selectedRow = selectionModel.length > 0 && rows.find((row) => row.id === (selectedId as number))

  useEffect(() => {
    if (defaultSelectedRowId !== undefined) {
      setSelectionModel([defaultSelectedRowId])
    }
  }, [defaultSelectedRowId])

  const onSave = () => {
    if (selectedRow) {
      const attributeReference: Array<InputAttributeReferenceWithAttributeName> = []
      Object.keys(selectedRow).forEach((attributeName) => {
        if (attributeName !== 'price' && attributeName !== 'id') {
          const attributeDefinitionId = priceAttributesResponse.data?.priceAttributes.find((attribute) => {
            return attribute.name === attributeName
          })?.id
          const attributeValue = selectedRow[attributeName].toString()
          if (attributeDefinitionId) {
            attributeReference.push({
              attributeName,
              attributeDefinitionId,
              attributeValue,
            })
          }
        }
      })
      onSubmit(attributeReference)
      onClose?.()
    }
  }
  return (
    <Dialog open={!!open} onClose={onClose} fullWidth maxWidth="lg" style={{ height: '100%' }}>
      <DialogTitle>Select Rate Card Attributes</DialogTitle>
      <Divider />
      <DialogContent>
        {selectedRow && (
          <>
            <Typography variant="h6">Current Selection</Typography>
            <Grid container direction="row" spacing={2} paddingBottom={2}>
              {Object.keys(selectedRow).map((name, index) => (
                <>
                  {name !== 'id' && (
                    <Grid item xs={4}>
                      <BillyGridCell
                        label={toTitleCase(name)}
                        description={
                          name === 'price'
                            ? unitPriceFormatter({ value: selectedRow[name], currency: rateCard?.currencyCode })
                            : selectedRow[name].toString()
                        }
                        key={index}
                      />
                    </Grid>
                  )}
                </>
              ))}
            </Grid>
          </>
        )}
        <DataGridPro
          unstable_headerFilters
          loading={loading}
          checkboxSelection
          disableColumnMenu={false}
          disableMultipleRowSelection={true}
          disableColumnResize={false}
          autoHeight={true}
          rows={rows}
          sx={{
            '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
              display: 'none',
            },
          }}
          pinnedRows={
            selectedRow
              ? {
                  top: [{ ...selectedRow, id: rows.length + 1 }],
                }
              : undefined
          }
          slots={{ toolbar: GridToolbar, baseCheckbox: Radio }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              // This is disabled due to a bug in DataGridPro that throws an error when the print is canceled
              printOptions: { disableToolbarButton: true },
              csvOptions: { allColumns: true, getRowsToExport: () => rows.map((row) => row.id) },
            },
          }}
          columns={columns}
          rowSelectionModel={selectionModel}
          onRowSelectionModelChange={(selection) => {
            if (selection.length > 1) {
              const selectionSet = new Set(selectionModel)
              const result = selection.filter((row) => !selectionSet.has(row))
              setSelectionModel(result)
            } else {
              setSelectionModel(selection)
            }
          }}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <ActionButton
            buttonData={{
              disabledExplanation: selectedRow ? undefined : 'Please select an attribute',
              onClick: onSave,
              label: 'Save',
            }}
          />
        </>
      </DialogActions>
    </Dialog>
  )
}

export function useRateCardAttributeSelectDialog(modalProps: RateCardAttributeSelectDialogProps) {
  const [, , toggleModal] = useModalsContext()
  const key = 'rate-card-attribute-select' + modalProps.modalKey
  useModal({
    component: RateCardAttributeSelectDialog,
    schema: {
      key,
      modalProps,
    },
  })
  return useCallback(() => {
    toggleModal(key)
  }, [toggleModal, key])
}

export function useOrderLineItemRateCardAttributeSelectDialog() {
  const [, , toggleModal] = useModalsContext()
  const key = 'rate-card-attribute-select'

  const [lineItemIndex, setLineItemIndex] = useState<number>(0)
  const jotaiForm = useJotaiFormContext<NewOrderFormData>()
  const { queueDryRun } = useDryRunActions()

  const modalProps: WithFx & Omit<RateCardAttributeSelectDialogProps, 'onSubmit'> = jotaiForm.useSelect(
    useCallback(
      (form) => {
        const lineItem = form.orderDetail.lineItems[lineItemIndex]
        return {
          rateCardId: lineItem?.charge.rateCardId || '',
          selectedAttributes: lineItem?.attributeReferences,
          currency: form.orderDetail.account.currency ?? undefined,
          startDate: form.orderDetail.startDate,
        }
      },
      [lineItemIndex]
    )
  )

  useModal({
    component: RateCardAttributeSelectDialog,
    schema: {
      key,
      modalProps: {
        ...modalProps,
        onSubmit: (selectedAttributes) => {
          jotaiForm.set((draft) => {
            const lineItem = draft.orderDetail.lineItems[lineItemIndex]
            lineItem.attributeReferences = selectedAttributes
          })
          queueDryRun()
        },
      },
    },
  })
  return useCallback(
    (index: number) => {
      setLineItemIndex(index)
      toggleModal(key)
    },
    [toggleModal, key]
  )
}
