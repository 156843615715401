import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { CardContent, Grid, Typography } from '@mui/material'
import { CombinedError } from '@urql/core'
import { makeStyles } from 'tss-react/mui'
import * as yup from 'yup'
import ActionButton from '../../components/button/actionButton'
import BillyCard from '../../components/card/billyCard'
import GqlErrorDisplay from '../../components/error/gqlErrorDisplay'
import JotaiMuiField from '../../components/input/JotaiMuiField'
import CenteredLayout from '../../components/layout/centeredLayout'
import BillyLink from '../../components/link/billyLink'
import { JotaiFormContext } from '../../components/state/jotaiFormProvider'
import JotaiReadValue from '../../components/state/jotaiReadValue'
import { JotaiForm } from '../../components/state/useJotaiForm'
import useJotaiOnSubmit from '../../components/state/useJotaiOnSubmit'
import { AuthMethod, UserAuthInfo } from '../../generated/graphql'
import { useRouter } from 'next/router'
import BillyAlert from '../../components/alerts/alert'
import { useErrorHandler } from '../../components/ErrorHandler/ErrorHandler'

const emailSchema: any = yup
  .object({
    email: yup.string().email('Please enter a valid email address'),
  })
  .defined()

const passwordSchema: any = yup
  .object({
    password: yup.string().required(),
  })
  .defined()

export enum LoginCardType {
  Email = 'Email',
  Password = 'Password',
  Message = 'Message',
}

export type LoginFormAtomType = {
  badPassword: boolean
  email: string
  getUserAuthInfoError?: CombinedError
  password: string
  showCard: LoginCardType
  message: string
  skipTransition: boolean
  userAuthInfo?: UserAuthInfo
  unknownError: boolean
  yupErrors: Record<string, string>
}

const cardWidth = 325
const transitionTime = 0.5

type LoginPagePropsType = Readonly<{
  buildPasswordLoginUrl: (userAuthInfo: UserAuthInfo) => string
  redirectToCognitoLogin: (email: string, userAuthInfo?: UserAuthInfo) => void
  redirectToForgotPasswordCognito: (email: string, userAuthInfo?: UserAuthInfo) => void
  submitEmail: (email: string) => void
  sendEmailLink: (email: string) => void
  currentYear: string
}>

export const useLoginFormStyles = makeStyles<{ skipTransition?: boolean }>()((_theme, { skipTransition }) => ({
  card: {
    position: 'relative',
    transition: `transform ${skipTransition ? 0 : transitionTime}s`,
    width: cardWidth,
    float: 'left',
    left: `calc(50% - ${cardWidth / 2}px)`,
  },
  noDisplay: {
    display: 'none',
  },
}))

function LoginForm({
  buildPasswordLoginUrl,
  redirectToCognitoLogin,
  redirectToForgotPasswordCognito,
  submitEmail,
  currentYear,
  sendEmailLink,
}: LoginPagePropsType): JSX.Element {
  const jotaiForm = useContext<JotaiForm<LoginFormAtomType>>(JotaiFormContext)

  useEffect(() => {
    const timer = setTimeout(() => {
      jotaiForm.set((form) => {
        form.skipTransition = false
      })
    }, 100)
    return () => clearTimeout(timer)
  }, [jotaiForm])

  const errorHandler = useErrorHandler()
  const router = useRouter()
  const { logout, ...routerQuery } = router?.query || {}

  const [showLogoutMessage, setShowLogoutMessage] = useState(logout === 'true')
  const showCard = jotaiForm.useSelect(useCallback((form) => form.showCard, []))
  const enableNext = jotaiForm.useSelect(useCallback((form) => form.email.length > 0, []))
  const enableLogin = jotaiForm.useSelect(useCallback((form) => form.password.length > 0, []))
  const skipTransition = jotaiForm.useSelect(useCallback((form) => form.skipTransition, []))
  const message = jotaiForm.useSelect(useCallback((form) => form.message, []))
  const passwordFormRef = useRef<HTMLFormElement>(null)
  const emailFieldRef = useRef<HTMLInputElement>(null)
  const passwordFieldRef = useRef<HTMLInputElement>(null)

  const { classes, cx } = useLoginFormStyles({ skipTransition })

  const removeLogoutMessage = useCallback(() => {
    setShowLogoutMessage(false)
    router
      .replace({
        query: { ...routerQuery },
      })
      .catch(errorHandler)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorHandler, router])

  const onEmailSubmit = useJotaiOnSubmit({
    atom: jotaiForm.atom,
    onSubmit: useCallback(
      (form: LoginFormAtomType) => {
        submitEmail(form.email)
        removeLogoutMessage()
      },
      [submitEmail, removeLogoutMessage]
    ),
    schema: emailSchema,
    onError: () => removeLogoutMessage(),
  })

  const onPasswordSubmit = useJotaiOnSubmit({
    atom: jotaiForm.atom,
    onSubmit: useCallback(
      (form: LoginFormAtomType) => {
        const passwordForm = passwordFormRef.current
        if (passwordForm && form.userAuthInfo) {
          jotaiForm.set((form) => {
            form.badPassword = false
          })
          passwordForm.action = buildPasswordLoginUrl(form.userAuthInfo)
          passwordForm.submit()
        }
      },
      [buildPasswordLoginUrl, jotaiForm]
    ),
    schema: passwordSchema,
  })

  useEffect(() => {
    const timer = setTimeout(() => {
      if (showCard == LoginCardType.Password) {
        passwordFieldRef.current?.focus()
      } else if (showCard == LoginCardType.Email) {
        emailFieldRef.current?.focus()
      }
    }, transitionTime * 1000)
    return () => clearTimeout(timer)
  }, [showCard])

  return (
    <>
      <CenteredLayout currentYear={currentYear}>
        <div>
          {showLogoutMessage && <BillyAlert alertType="success" message="You have successfully logged out" showIcon />}
          <BillyCard className={cx(classes.card, showCard != LoginCardType.Email && classes.noDisplay)}>
            <CardContent>
              <form
                noValidate={true}
                onSubmit={(event) => {
                  event.preventDefault()
                  onEmailSubmit()
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6">Welcome to Subskribe</Typography>
                  </Grid>
                  <JotaiReadValue
                    atomSelector={(form: LoginFormAtomType) => form.getUserAuthInfoError}
                    form={jotaiForm}
                    render={(getUserAuthInfoError) => (
                      <>
                        {getUserAuthInfoError && (
                          <Grid item xs={12}>
                            <GqlErrorDisplay error={getUserAuthInfoError} />
                          </Grid>
                        )}
                      </>
                    )}
                  />
                  <Grid item xs={12}>
                    <JotaiMuiField
                      atomSelector={(form) => form.email}
                      atomUpdater={(value, draft) => (draft.email = value)}
                      errorPath="email"
                      form={jotaiForm}
                      textFieldProps={{
                        inputRef: emailFieldRef,
                        label: 'Email',
                        size: 'small',
                        type: 'email',
                        inputProps: { 'aria-label': 'Email' },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ActionButton
                      buttonData={{
                        buttonProps: { size: 'large', type: 'submit' },
                        disabledExplanation: enableNext ? '' : 'Please enter your email',
                        label: 'Next',
                        fullWidth: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </BillyCard>
          <BillyCard className={cx(classes.card, showCard != LoginCardType.Password && classes.noDisplay)}>
            <CardContent>
              <form
                method="post"
                ref={passwordFormRef}
                onSubmit={(event) => {
                  event.preventDefault()
                  onPasswordSubmit()
                }}
              >
                <JotaiReadValue
                  atomSelector={(form: LoginFormAtomType) => form.email}
                  form={jotaiForm}
                  render={(email) => <input name="email" type="hidden" value={email} />}
                />
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6">Enter Password</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <JotaiReadValue
                      atomSelector={(form: LoginFormAtomType) => ({
                        badPassword: form.badPassword,
                        unknownError: form.unknownError,
                      })}
                      form={jotaiForm}
                      render={({ badPassword, unknownError }) => (
                        <JotaiMuiField
                          atomSelector={(form) => form.password}
                          atomUpdater={(value, draft) => (draft.password = value)}
                          errorPath="password"
                          form={jotaiForm}
                          textFieldProps={{
                            label: 'Password',
                            inputRef: passwordFieldRef,
                            size: 'small',
                            type: 'password',
                            error: badPassword || unknownError,
                            helperText:
                              (badPassword && "Sorry, that password isn't correct") ||
                              (unknownError && 'Sorry, something went wrong when trying to login'),
                            inputProps: { 'aria-label': 'Password' },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ActionButton
                      buttonData={{
                        buttonProps: { size: 'large', type: 'submit' },
                        disabledExplanation: enableLogin ? '' : 'Please enter your password',
                        label: 'Login',
                        fullWidth: true,
                      }}
                    />
                  </Grid>
                  <JotaiReadValue
                    atomSelector={(form: LoginFormAtomType) => ({ email: form.email, userAuthInfo: form.userAuthInfo })}
                    form={jotaiForm}
                    render={({ email, userAuthInfo }) => (
                      <>
                        {userAuthInfo?.authMethod !== AuthMethod.PasswordOnly && (
                          <Grid item xs={12}>
                            <ActionButton
                              buttonData={{
                                buttonProps: { size: 'large', variant: enableLogin ? 'outlined' : 'contained' },
                                label: 'Login with SSO',
                                fullWidth: true,
                                onClick: () => redirectToCognitoLogin(email, userAuthInfo),
                              }}
                            />
                          </Grid>
                        )}
                        {userAuthInfo?.isEmailLinkLoginEnabled && (
                          <Grid item xs={12}>
                            <BillyLink
                              linkProps={{
                                href: '',
                                onClick: (e) => {
                                  e.preventDefault()
                                  sendEmailLink(email)
                                },
                              }}
                            >
                              Login using email link
                            </BillyLink>
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <BillyLink
                            linkProps={{
                              href: '',
                              onClick: (e) => {
                                e.preventDefault()
                                redirectToForgotPasswordCognito(email, userAuthInfo)
                              },
                            }}
                          >
                            Forgot password?
                          </BillyLink>
                        </Grid>
                      </>
                    )}
                  />
                  <Grid item xs={12}>
                    <BillyLink
                      linkProps={{
                        href: '',
                        onClick: (e) => {
                          e.preventDefault()
                          jotaiForm.set((form) => {
                            form.showCard = LoginCardType.Email
                          })
                        },
                      }}
                    >
                      Go back to email
                    </BillyLink>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </BillyCard>
          <BillyCard className={cx(classes.card, showCard != LoginCardType.Message && classes.noDisplay)}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography>{message}</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </BillyCard>
        </div>
      </CenteredLayout>
    </>
  )
}

export default LoginForm
