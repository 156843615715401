import React, { useCallback, useMemo } from 'react'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import NotInterestedIcon from '@mui/icons-material/NotInterested'
import PendingIcon from '@mui/icons-material/Pending'
import PendingActionsIcon from '@mui/icons-material/PendingActions'
import QuizIcon from '@mui/icons-material/Quiz'
import WatchLaterIcon from '@mui/icons-material/WatchLater'
import EditIcon from '@mui/icons-material/Edit'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  Tooltip,
  Typography,
} from '@mui/material'
import List from '@mui/material/List'
import { makeStyles } from 'tss-react/mui'
import ActionButton from '../../components/button/actionButton'
import JotaiUrqlErrors from '../../components/error/jotaiUrqlErrors'
import BillyResponsiveGridContainer from '../../components/grid/billyResponsiveGridContainer'
import BillyResponsiveGridItem from '../../components/grid/billyResponsiveGridItem'
import JotaiMuiField from '../../components/input/JotaiMuiField'
import JotaiMuiRadioGroup from '../../components/input/JotaiMuiRadioGroup'
import BillyLink from '../../components/link/billyLink'
import { JotaiUrqlBillySnackBar } from '../../components/snackBar/useJotaiUrqlBillySnackBar'
import JotaiReadArray from '../../components/state/jotaiReadArray'
import JotaiReadValue from '../../components/state/jotaiReadValue'
import useJotaiForm, { JotaiForm } from '../../components/state/useJotaiForm'
import useJotaiOnSubmit from '../../components/state/useJotaiOnSubmit'
import { useJotaiUrqlMutation } from '../../components/state/useJotaiUrqlMutation'
import { WithUrql, useJotaiUrqlQuery } from '../../components/state/useJotaiUrqlQuery'
import { ReadMore } from '../../components/typography/ReadMore'
import {
  ApprovalFlowInstanceStatus,
  ApprovalFlowInstanceWorkflowStatus,
  Feature,
  GetCurrentUserDocument,
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables,
  GetOrderDetailDocument,
  GetOrderDetailQuery,
  GetOrderDetailQueryVariables,
  InputApprovalFlowInstanceStateUpdates,
  OrderDetailFragment,
  Status,
  UpdateOrderApprovalStateDocument,
} from '../../generated/graphql'
import { notEmpty } from '../../util/array'
import { formatUnixDate } from '../../util/datetime/luxon/dateUtil'
import buildLogger from '../../util/logger'
import { isAdminRole } from '../../util/roleUtils'
import { deepMutable } from '../../components/SchemaForm/DeepMutable'
import WithDynamicFeatureFlag from '@/components/WithDynamicFeatureToggle/WithDynamicFeatureToggle'
import { useApprovalFlowSubmitterNoteDialog } from './ApprovalSubmitterNoteDialog'

const logger = buildLogger('OrderApprovalFlowsView')

const DISABLED_USER = ' [Disabled]'

const useStyles = makeStyles()((theme, _params, _classes) => ({
  accordionContainer: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(1),
  },
  approvalFlowsContainer: {
    backgroundColor: theme.palette.background.default,
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.015) 0 0)', // Make 1.5% darker
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  approvalStatus: {
    color: theme.palette.text.secondary,
    paddingLeft: '2rem',
  },
  disabledIcon: {
    color: theme.palette.action.disabled,
  },
  textSecondary: {
    color: theme.palette.text.secondary,
  },
}))

export interface OrderApprovalFlowsViewForm extends WithUrql {
  approvalDialogData?: {
    approvalFlowInstance: ApprovalFlowInstance
    approvalState: ApprovalState
    orderApprovalFlow: OrderDetailFragment['approvalFlows'][0]
  }
  approvalDialogRadio: 'Approve' | 'Reject'
  approvalDialogNote?: string
  currentUser?: GetCurrentUserQuery['currentUser']
  orderDetail?: OrderDetailFragment
}

export function OrderApprovalFlowsView({ orderId }: { orderId?: string }): JSX.Element {
  const { classes } = useStyles()

  const jotaiForm = useJotaiForm<OrderApprovalFlowsViewForm>(
    React.useMemo(
      () => ({
        defaultValue: {
          approvalDialogRadio: 'Approve',
        },
      }),
      []
    )
  )

  useJotaiUrqlQuery<OrderApprovalFlowsViewForm, GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    useMemo(
      () => ({
        document: GetCurrentUserDocument,
        jotaiForm,
        onData: (data, draft) => (draft.currentUser = data.currentUser),
        queryOpts: { requestPolicy: 'cache-first' },
      }),
      [jotaiForm]
    )
  )

  useJotaiUrqlQuery<OrderApprovalFlowsViewForm, GetOrderDetailQuery, GetOrderDetailQueryVariables>(
    useMemo(
      () => ({
        document: GetOrderDetailDocument,
        jotaiForm,
        onData: (data, draft) => (draft.orderDetail = deepMutable(data.orderDetail)),
        pause: () => !orderId,
        queryOpts: { requestPolicy: 'cache-first' },
        variables: () => ({ id: orderId || '' }),
      }),
      [jotaiForm, orderId]
    )
  )

  const orderApprovalFlows = (
    <JotaiReadValue
      atomSelector={(form: OrderApprovalFlowsViewForm) => form.orderDetail?.approvalFlows}
      form={jotaiForm}
      render={(approvalFlows) => (
        <>
          {!!approvalFlows?.length && (
            <JotaiReadArray
              atomSelector={(form: OrderApprovalFlowsViewForm) => deepMutable(form.orderDetail?.approvalFlows)}
              form={jotaiForm}
              render={(approvalFlow, index) => (
                <OrderApprovalFlowView index={index} jotaiForm={jotaiForm} orderApprovalFlow={approvalFlow} />
              )}
            />
          )}
        </>
      )}
    />
  )

  const dialog = useMemo(
    () => (
      <JotaiReadValue
        atomSelector={(form: OrderApprovalFlowsViewForm) => !!form.approvalDialogData}
        form={jotaiForm}
        render={(isOpen) => <OrderApprovalDialog isOpen={isOpen} jotaiForm={jotaiForm} />}
      />
    ),
    [jotaiForm]
  )

  const showApprovalFlows = jotaiForm.useSelect(
    useCallback((form) => !!form.approvalDialogData || !!form.orderDetail?.approvalFlows.length, [])
  )
  return (
    <>
      {showApprovalFlows && (
        <div className={classes.approvalFlowsContainer}>
          {orderApprovalFlows}
          {dialog}
        </div>
      )}
    </>
  )
}

type approvalDisplayStatus = 'Approved' | 'Rejected' | 'Cancelled' | 'Needs Approval' | 'Submitted'
export function OrderApprovalFlowView({
  index,
  jotaiForm,
  orderApprovalFlow,
}: {
  index: number
  jotaiForm: JotaiForm<OrderApprovalFlowsViewForm>
  orderApprovalFlow: OrderDetailFragment['approvalFlows'][0]
}): JSX.Element {
  const { classes } = useStyles()

  const currentUser = jotaiForm.useSelect(useCallback((form) => form.currentUser, []))
  const submitterName = jotaiForm.useSelect(useCallback((form) => form.orderDetail?.submittedBy?.displayName, []))

  const approvalFlowInstances = orderApprovalFlow.approvalFlowInstances
    .filter((state) => state.status != ApprovalFlowInstanceStatus.NotApplicable)
    .map((approvalFlowInstance) => (
      <ApprovalFlowInstanceView
        approvalFlowInstance={approvalFlowInstance}
        key={approvalFlowInstance.id}
        jotaiForm={jotaiForm}
        orderApprovalFlow={orderApprovalFlow}
        workflowStatus={orderApprovalFlow.workflowStatus}
      />
    ))

  const isPreview = orderApprovalFlow.workflowStatus == ApprovalFlowInstanceWorkflowStatus.Preview

  let status: approvalDisplayStatus = 'Submitted'
  if (orderApprovalFlow.approvalStatus == ApprovalFlowInstanceStatus.Approved) {
    status = 'Approved'
  } else if (orderApprovalFlow.approvalStatus == ApprovalFlowInstanceStatus.Rejected) {
    status = 'Rejected'
  } else if (orderApprovalFlow.approvalStatus == ApprovalFlowInstanceStatus.Cancelled) {
    status = 'Cancelled'
  } else if (orderApprovalFlow.approvalStatus == ApprovalFlowInstanceStatus.NeedsApproval) {
    status = 'Needs Approval'
  }
  const error = getOrderApprovalFlowError(orderApprovalFlow)

  let displayDate = formatUnixDate(orderApprovalFlow.createdOn)
  if (orderApprovalFlow.approvalStatus != ApprovalFlowInstanceStatus.AwaitingApproval) {
    displayDate = formatUnixDate(orderApprovalFlow.updatedOn)
  }

  const isAutoApprove = approvalFlowInstances.length === 0

  const currentUserCanApprove = orderApprovalFlow.approvalFlowInstances.some((instance) =>
    instance.data.states.some((approvalState) => {
      const usersInState = approvalState.approvalGroup.users
      const currentUserPresentInState = usersInState.find((user) => user?.email == currentUser?.email)
      return (
        currentUserPresentInState &&
        approvalState.status == ApprovalFlowInstanceStatus.AwaitingApproval &&
        orderApprovalFlow.workflowStatus == ApprovalFlowInstanceWorkflowStatus.InProgress
      )
    })
  )

  const [toggleSubmitterNoteDialog] = useApprovalFlowSubmitterNoteDialog({
    jotaiForm,
  })

  const showSubmitter = status === 'Submitted'

  let headerText = `${status} ${displayDate}` + (showSubmitter && submitterName ? ` (By : ${submitterName})` : '')

  if (isAutoApprove) {
    headerText = isPreview ? 'Will be auto approved' : `Automatically approved ${displayDate}`
  } else if (isPreview) {
    headerText = 'Preview'
  }

  return (
    <Accordion defaultExpanded={currentUserCanApprove}>
      <AccordionSummary
        expandIcon={isAutoApprove ? undefined : <ExpandMoreIcon />}
        sx={{ paddingLeft: 3, pointerEvents: isAutoApprove ? 'none' : undefined }}
      >
        {error ? (
          <Alert variant="outlined" severity="warning" sx={{ width: '100%' }}>
            {error}
          </Alert>
        ) : (
          <>
            <Typography variant="body1">{index > 0 && <>Past </>}Approvals</Typography>
            <Typography variant="body1" className={classes.approvalStatus}>
              {headerText}
            </Typography>
          </>
        )}
      </AccordionSummary>
      {!isAutoApprove && (
        <AccordionDetails>
          <WithDynamicFeatureFlag status="enabled" feature={Feature.ApprovalFlowSubmitterNote}>
            <Box pb={2} pl={4}>
              <Box display="flex" alignItems="center">
                <Typography variant="body2">
                  <strong>Note for Approvers</strong>
                </Typography>
                {orderApprovalFlow.workflowStatus === ApprovalFlowInstanceWorkflowStatus.Preview && (
                  <IconButton
                    aria-label="edit approval note"
                    size="small"
                    color="primary"
                    onClick={toggleSubmitterNoteDialog}
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </Box>
              <ReadMore lineClampCount={1} fontSize="small" className={classes.textSecondary} pr={2}>
                {orderApprovalFlow.submitterNote?.note}
              </ReadMore>
            </Box>
          </WithDynamicFeatureFlag>
          <BillyResponsiveGridContainer
            autoWidthByXs={300}
            gridProps={{ spacing: 2, className: classes.accordionContainer }}
          >
            {approvalFlowInstances}
          </BillyResponsiveGridContainer>
        </AccordionDetails>
      )}
    </Accordion>
  )
}

type ApprovalFlowInstance = OrderDetailFragment['approvalFlows'][0]['approvalFlowInstances'][0]

function getOrderApprovalFlowError(orderApprovalFlow: OrderDetailFragment['approvalFlows'][0]) {
  switch (orderApprovalFlow?.approvalStatus) {
    case ApprovalFlowInstanceStatus.ErrorOrderOwnerIsMissingToFindApprovalSegment:
      return 'Order owner is missing to find approval segment so this order cannot be routed for approval.'
    case ApprovalFlowInstanceStatus.ErrorOrderOwnerNotPartOfAnyApprovalSegment:
      return 'Order owner is not part of any approval segment so this order cannot be routed for approval. Please contact your organization’s Subskribe administrator for assistance.'
    case ApprovalFlowInstanceStatus.ErrorOrderOwnerNotPartOfApprovalSegmentSelectedOnOrder:
      return 'Order owner is not part of approval segment selected on order so this order cannot be routed for approval.'
    case ApprovalFlowInstanceStatus.ErrorOrderOwnerPartOfMultipleApprovalSegments:
      return 'Order owner is part of multiple approval segments so this order cannot be routed for approval.'
  }
}

export function getOrderApprovalFlowsError(flows: OrderDetailFragment['approvalFlows']) {
  return flows
    .filter(notEmpty)
    .map((flow) => getOrderApprovalFlowError(flow))
    .filter(notEmpty)
}

function ApprovalFlowInstanceView({
  approvalFlowInstance,
  jotaiForm,
  orderApprovalFlow,
  workflowStatus,
}: {
  approvalFlowInstance: ApprovalFlowInstance
  jotaiForm: JotaiForm<OrderApprovalFlowsViewForm>
  orderApprovalFlow: OrderDetailFragment['approvalFlows'][0]
  workflowStatus: ApprovalFlowInstanceWorkflowStatus
}): JSX.Element {
  const approvalStates = approvalFlowInstance.data.states.map((state) => (
    <ApprovalStateView
      approvalFlowInstance={approvalFlowInstance}
      approvalState={state}
      jotaiForm={jotaiForm}
      key={state.name}
      orderApprovalFlow={orderApprovalFlow}
      workflowStatus={workflowStatus}
    />
  ))

  return (
    <BillyResponsiveGridItem autoSize>
      <ShowApprovalFlowInstanceNameWithToolTip approvalFlowInstance={approvalFlowInstance} />
      <List>{approvalStates}</List>
    </BillyResponsiveGridItem>
  )
}

function ShowApprovalFlowInstanceNameWithToolTip({
  approvalFlowInstance,
}: {
  approvalFlowInstance: ApprovalFlowInstance
}): JSX.Element {
  if (approvalFlowInstance.data.description == null || approvalFlowInstance.data.description == '') {
    return (
      <Typography variant="body2">
        <strong> {approvalFlowInstance.data.name} </strong>
      </Typography>
    )
  }

  return (
    <Tooltip
      title={<div style={{ whiteSpace: 'pre-line' }}>{approvalFlowInstance.data.description}</div>}
      placement="top"
    >
      <Typography variant="body2">
        <strong> {approvalFlowInstance.data.name}</strong>
      </Typography>
    </Tooltip>
  )
}

type ApprovalState = ApprovalFlowInstance['data']['states'][0]

function ApprovalStateView({
  approvalFlowInstance,
  approvalState,
  jotaiForm,
  orderApprovalFlow,
  workflowStatus,
}: {
  approvalFlowInstance: ApprovalFlowInstance
  approvalState: ApprovalState
  jotaiForm: JotaiForm<OrderApprovalFlowsViewForm>
  orderApprovalFlow: OrderDetailFragment['approvalFlows'][0]
  workflowStatus: ApprovalFlowInstanceWorkflowStatus
}): JSX.Element {
  const { classes } = useStyles()

  const currentUser = jotaiForm.useSelect(useCallback((form) => form.currentUser, []))
  const orderOwner = jotaiForm.useSelect(useCallback((form) => form.orderDetail?.owner, []))

  const usersInState = approvalState.approvalGroup.users
  const currentUserPresentInState = usersInState.find((user) => user?.email && user?.email === currentUser?.email)
  const isCurrentUserOwner = orderOwner?.id === currentUser?.id
  const currentUserCanApproveState =
    ((!isCurrentUserOwner && currentUserPresentInState) || isAdminRole(currentUser?.role)) &&
    approvalState.status == ApprovalFlowInstanceStatus.AwaitingApproval &&
    workflowStatus == ApprovalFlowInstanceWorkflowStatus.InProgress

  const { approvedBy } = approvalState

  const sortedApproveBys =
    approvedBy &&
    approvedBy.length > 0 &&
    [...approvedBy].sort((userA, userB) => (userA?.time ?? Infinity) - (userB?.time ?? Infinity))

  const approvedTime = sortedApproveBys && sortedApproveBys.length > 0 ? formatUnixDate(sortedApproveBys[0]?.time) : ''

  const isFlowRejected = orderApprovalFlow.approvalStatus == ApprovalFlowInstanceStatus.Rejected
  const isPreview = orderApprovalFlow.workflowStatus == ApprovalFlowInstanceWorkflowStatus.Preview
  const openDialog = useCallback(
    () =>
      jotaiForm.set((form) => {
        form.approvalDialogData = deepMutable({
          approvalFlowInstance,
          approvalState,
          orderApprovalFlow,
        })
        form.approvalDialogRadio = 'Approve'
      }),
    [approvalFlowInstance, approvalState, jotaiForm, orderApprovalFlow]
  )

  // default: pending
  let icon = <WatchLaterIcon />
  let caption = (
    <Typography className={classes.textSecondary} variant="body2">
      {'Approval Requested'}
    </Typography>
  )

  if (isPreview) {
    // preview
    icon = <PendingActionsIcon />
    caption = (
      <Typography className={classes.textSecondary} variant="body2">
        {`Approver`}
      </Typography>
    )
  } else if (currentUserCanApproveState) {
    // requested
    icon = (
      <BillyLink linkProps={{ onClick: openDialog }} noHover={true} noUnderline={true}>
        <QuizIcon color="primary" />
      </BillyLink>
    )

    caption = (
      <BillyLink
        linkProps={{
          onClick: openDialog,
        }}
      >
        <Typography color="primary" variant="body2">
          Approve or Reject
        </Typography>
      </BillyLink>
    )
  } else if (approvalState.status == ApprovalFlowInstanceStatus.Approved) {
    // approved / vetoed
    icon = <CheckCircleIcon />
    caption = (
      <Typography className={classes.textSecondary} variant="body2">
        {`Approved ${approvedTime}`}
      </Typography>
    )
  } else if (approvalState.status == ApprovalFlowInstanceStatus.Rejected) {
    // rejected
    icon = <CancelIcon color="error" />
    caption = (
      <Typography className={classes.textSecondary} variant="body2">
        {`Rejected ${formatUnixDate(orderApprovalFlow.updatedOn)}`}
      </Typography>
    )
  } else if (
    approvalState.status == ApprovalFlowInstanceStatus.Cancelled ||
    (approvalState.status == ApprovalFlowInstanceStatus.Inactive && isFlowRejected) ||
    (approvalState.status == ApprovalFlowInstanceStatus.AwaitingApproval && isFlowRejected)
  ) {
    // cancelled
    icon = <CancelIcon />
    caption = (
      <Typography className={classes.textSecondary} variant="body2">
        {`Cancelled`}
      </Typography>
    )
  } else if (approvalState.status == ApprovalFlowInstanceStatus.Inactive) {
    // pending other
    icon = <PendingIcon className={classes.disabledIcon} />
    caption = (
      <Typography className={classes.textSecondary} variant="body2">
        {`Up Next`}
      </Typography>
    )
  } else if (approvalState.status == ApprovalFlowInstanceStatus.NotApplicable) {
    // not applicable
    icon = <NotInterestedIcon />
    caption = (
      <Typography className={classes.textSecondary} variant="body2">
        {`Not Applicable`}
      </Typography>
    )
  }

  const usersString = usersInState
    ?.map((user) => {
      if (user) {
        return user.displayName + (user.state === Status.Disabled ? DISABLED_USER : '')
      }
    })
    .join(', ')

  const approvedByNames = approvedBy.map((a) => a?.user?.displayName).join(',')
  const rejectedByNames = approvalState.rejectedBy.map((a) => a?.user?.displayName).join(',')

  const approvedOrRejectedByName = approvedByNames || rejectedByNames
  const groupName = approvalState.approvalGroup.name
  const userName =
    approvalState.approvalGroup.name +
    (approvalState.approvalGroup.isUser && approvalState.approvalGroup.users[0]?.state === Status.Disabled
      ? DISABLED_USER
      : '')
  const approvalComment = approvalState.approvedBy[0]?.note || approvalState.rejectedBy[0]?.note
  return (
    <>
      <ListItem sx={{ paddingLeft: 0 }} dense>
        <ListItemIcon sx={{ display: 'flex', justifyContent: 'center' }}>{icon}</ListItemIcon>
        <ListItemText>
          {approvalState.approvalGroup.isUser ? (
            userName
          ) : (
            <Tooltip title={usersString} placement="top">
              <label>
                {approvedOrRejectedByName ? (
                  <>
                    {approvedOrRejectedByName} <Box display="inline-block">({groupName})</Box>
                  </>
                ) : (
                  groupName
                )}
              </label>
            </Tooltip>
          )}
          <br />
          {caption}
        </ListItemText>
      </ListItem>
      {approvalComment && (
        <ListItem dense sx={{ paddingLeft: 0 }}>
          <ListItemText sx={{ marginLeft: '48px' }}>
            <ReadMore fontSize="small" className={classes.textSecondary}>
              {approvalComment}
            </ReadMore>
          </ListItemText>
        </ListItem>
      )}
    </>
  )
}

function OrderApprovalDialog({
  isOpen,
  jotaiForm,
}: {
  isOpen: boolean
  jotaiForm: JotaiForm<OrderApprovalFlowsViewForm>
}): JSX.Element {
  const updateState = useJotaiUrqlMutation({
    document: UpdateOrderApprovalStateDocument,
    jotaiForm,
    refreshDocuments: [GetOrderDetailDocument],
  })
  const isApprovalStateUpdating = jotaiForm.useSelect(
    useCallback((form) => form.urqlIsFetching?.UpdateOrderApprovalState, [])
  )

  const onSubmit = useJotaiOnSubmit(
    useMemo(
      () => ({
        atom: jotaiForm.atom,
        onSubmit: (form: OrderApprovalFlowsViewForm) => {
          const data = form.approvalDialogData
          const status =
            form.approvalDialogRadio == 'Approve'
              ? ApprovalFlowInstanceStatus.Approved
              : ApprovalFlowInstanceStatus.Rejected
          const note = form.approvalDialogNote
          if (data) {
            const orderApprovalStateUpdates: InputApprovalFlowInstanceStateUpdates = {
              groupId: data.orderApprovalFlow.id,
              stateUpdates: [
                {
                  approvalFlowId: data.approvalFlowInstance.approvalFlowId,
                  stateId: data.approvalState.id,
                  status,
                  note,
                },
              ],
            }
            updateState({
              onData: (data, draft) => {
                draft.approvalDialogData = undefined
                draft.approvalDialogNote = ''
              },
              variables: { orderApprovalStateUpdates },
            })
          }
        },
      }),
      // prevent infinite re-render
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )
  )

  const confirmOrRejectButton = useMemo(() => {
    return (
      <JotaiReadValue
        atomSelector={(form) => form.approvalDialogRadio}
        form={jotaiForm}
        render={(approvalDialogRadio) =>
          approvalDialogRadio === 'Approve' ? (
            <ActionButton
              buttonData={{
                label: 'Confirm',
                onClick: onSubmit,
                disabledExplanation: isApprovalStateUpdating
                  ? 'Please wait while the request is in progress'
                  : undefined,
              }}
            />
          ) : (
            <ActionButton
              buttonData={{
                color: 'error',
                label: 'Revert to Draft',
                onClick: onSubmit,
                disabledExplanation: isApprovalStateUpdating
                  ? 'Please wait while the request is in progress'
                  : undefined,
              }}
            />
          )
        }
      />
    )
  }, [onSubmit, jotaiForm, isApprovalStateUpdating])

  const subtitle = useMemo(
    () => (
      <JotaiReadValue
        atomSelector={(form: OrderApprovalFlowsViewForm) => form.approvalDialogData?.approvalFlowInstance.data.name}
        form={jotaiForm}
        render={(name) => <Typography fontWeight={600}>{name}</Typography>}
      />
    ),
    [jotaiForm]
  )

  return (
    <Dialog open={isOpen} maxWidth={'sm'} scroll={'paper'}>
      <DialogTitle>Review Approval</DialogTitle>
      <DialogContent dividers={true} sx={{ minWidth: 400 }}>
        <JotaiUrqlErrors jotaiForm={jotaiForm} />
        <JotaiUrqlBillySnackBar
          jotaiForm={jotaiForm}
          document={UpdateOrderApprovalStateDocument}
          workingMessage="Saving"
        />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {subtitle}
          </Grid>
          <Grid item xs={12} mb={2}>
            <JotaiMuiRadioGroup
              atomSelector={(form: OrderApprovalFlowsViewForm) => form.approvalDialogRadio}
              form={jotaiForm}
              atomUpdater={(value, draft) =>
                (draft.approvalDialogRadio = value as OrderApprovalFlowsViewForm['approvalDialogRadio'])
              }
            >
              <Grid container direction="row" spacing={0}>
                <Grid item xs={12}>
                  <FormControlLabel value="Approve" control={<Radio color="primary" />} label="Approve" />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel value="Reject" control={<Radio color="primary" />} label="Reject" />
                </Grid>
              </Grid>
            </JotaiMuiRadioGroup>
          </Grid>
          <Grid item xs={12}>
            <JotaiMuiField
              atomSelector={(form: OrderApprovalFlowsViewForm) => form.approvalDialogNote}
              form={jotaiForm}
              atomUpdater={(value, draft) =>
                (draft.approvalDialogNote = value as OrderApprovalFlowsViewForm['approvalDialogRadio'])
              }
              textFieldProps={{
                label: 'Comment',
                multiline: true,
                minRows: 2,
                placeholder: 'Optional',
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ActionButton
          buttonData={{
            label: 'Cancel',
            onClick: () =>
              jotaiForm.set((form) => {
                form.approvalDialogData = undefined
                form.urqlErrors = undefined
              }),
            color: 'inherit',
            buttonProps: { variant: 'outlined' },
          }}
        />
        {confirmOrRejectButton}
      </DialogActions>
    </Dialog>
  )
}
