import { useEffect } from 'react'
import { GetUserTenantSessionQuery, useGetUserTenantSessionQuery } from '../../generated/graphql'
import { setTimeZone } from '../../util/datetime/luxon/dateUtil'
import { UserTenantSessionContext } from './UserTenantSessionContext'
import { datadogRum } from '@datadog/browser-rum'
import Cookies from 'js-cookie'
import { EntitySelectionPage } from '../Entity/EntitySelectionPage'
import { ALL_ENTITIES } from '@/util/entity'

interface UserTenantSessionDataProps {
  readonly children: JSX.Element
  readonly data: GetUserTenantSessionQuery
}

export const useSetTenantTimeZone = (tenantZone?: string) => {
  useEffect(() => {
    if (tenantZone) {
      setTimeZone(tenantZone)
    }
  }, [tenantZone])
}

function UserTenantSessionData(props: UserTenantSessionDataProps) {
  const { currentUser, currentTenant } = props.data

  useSetTenantTimeZone(props.data.setting.defaultTimeZone ?? undefined)
  useEffect(() => {
    datadogRum.setUser({
      id: currentUser.id,
      name: currentUser.displayName,
      email: currentUser.email,
      tenantId: currentTenant.id,
      tenantName: currentTenant.name,
    })
  }, [currentUser, currentTenant])

  useEffect(() => {
    if (currentUser?.availableEntities && currentUser?.availableEntities?.length === 1) {
      Cookies.set('entity_id', ALL_ENTITIES)
    }
  }, [currentUser.availableEntities])

  const hasMultipleEntities = currentUser?.availableEntities && currentUser?.availableEntities?.length > 1
  const cookieEntityId = Cookies.get('entity_id')
  const selectedEntityInAvailableEntities =
    currentUser?.availableEntities?.findIndex((e) => e.id === cookieEntityId) !== -1
  if (
    hasMultipleEntities &&
    (!cookieEntityId || (cookieEntityId != ALL_ENTITIES && !selectedEntityInAvailableEntities))
  ) {
    return <EntitySelectionPage availableEntities={currentUser?.availableEntities} allowAllEntities={true} />
  } else {
    return (
      <UserTenantSessionContext.Provider value={props.data}>
        <>{props.children}</>
      </UserTenantSessionContext.Provider>
    )
  }
}

export default function UserTenantSessionProvider(props: { readonly children: JSX.Element }) {
  const [userTenantSessionResponse] = useGetUserTenantSessionQuery()
  if (userTenantSessionResponse.data) {
    return <UserTenantSessionData data={userTenantSessionResponse.data}>{props.children}</UserTenantSessionData>
  } else return null
}
